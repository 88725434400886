import React, { useState, useEffect } from 'react'
import style from '../index.module.scss'
import './send-invoice-popup.scss'
import * as Yup from 'yup'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Popup from '@components/popups/basic-modal'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import {
  POPUP_TYPES,
  customOptions,
  createNewVisible,
  customOptionsService,
  paymentStatusListForDeclinedAndSubmited,
  isPastPacificTime
} from './invoice-popup-helpers'
import { USDOLLAR } from '../constants'
import notification from '@components/notification'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { isEnabled, phoneNumberFormat, validateEmail, validateMobile } from '@helpers/utility'
import moment from 'moment'
import Icon from '@components/icons'
import Tooltip from '@components/tooltip'
import PropTypes from 'prop-types'
import { numberMask } from '@helpers/inputmask'
import { useTranslation } from 'react-i18next'

let isFormChanged = false

const displayRecurringProperties = (recurringFrequency, recurringDay) => {
  if (!['first', 'fifteenth'].includes(recurringDay)) {
    // a weekday
    return _.capitalize(recurringFrequency) + ' on ' + _.capitalize(recurringDay) + 's'
  } else if (recurringFrequency === 'every month') {
    if (recurringDay === 'first') {
      return 'Every 1st of the month'
    } else if (recurringDay === 'fifteenth') {
      return 'Every 15th of the month'
    }
  }
  return ''
}

const determineEndType = (invoiceItem) => {
  if (
    invoiceItem.invoice_end_cycle !== null &&
    invoiceItem.invoice_never_end === false &&
    invoiceItem.invoice_end_date === null
  ) {
    return 'invoice_end_cycle'
  } else if (
    invoiceItem.invoice_end_cycle === null &&
    invoiceItem.invoice_never_end &&
    invoiceItem.invoice_end_date === null
  ) {
    return 'invoice_never_end'
  } else if (
    invoiceItem.invoice_end_cycle === null &&
    invoiceItem.invoice_never_end === false &&
    invoiceItem.invoice_end_date !== null
  ) {
    return 'invoice_end_date'
  }
  return 'invoice_never_end'
}

const SendRecurringInvoicePopup = ({
  open,
  closeModal,
  getEditSeriesBaseDate,
  editInvoiceBaseDate,
  allRecurringPayments,
  recurringSeriesDetail,
  invoiceItem,
  autosuggestContacts,
  products,
  contactList,
  me,
  savePrivateContact,
  privateContact,
  openInvoiceModal,
  autoSuggestServices,
  servicesList,
  fromBooking,
  hasMid,
  paymentStatus,
  paymentLinkLoader,
  confirmInvoice,
  popupType
}) => {
  const { t } = useTranslation()
  // Contact hooks
  const POPUP_TYPE_SETTINGS = {
    [POPUP_TYPES.EDIT_PREVIEW]: { title: t('payments.update-recurring-invoice'), buttonText: 'UPDATE INVOICE' },
    [POPUP_TYPES.CREATE]: { title: t('payments.send-recurring-invoice'), buttonText: 'CONTINUE' },
    [POPUP_TYPES.EDIT_INVOICE]: {
      title: t('payments.edit-invoice-in-recurring-series'),
      buttonText: 'EDIT INVOICE IN SERIES'
    },
    [POPUP_TYPES.EDIT_SERIES]: { title: t('payments.edit-recurring-series'), buttonText: 'EDIT RECURRING SERIES' }
  }

  const RECURRING_FREQUENCY_VALUES = ['every week', 'every 2 weeks', 'every month']
  const RECURRING_DAY_VALUES = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    first: 7,
    fifteenth: 8
  }
  const SEND_TYPE_DISPLAY_TEXT = {
    immediatly: 'Immediately',
    schedule: 'Scheduled'
  }
  const END_TYPE_DISPLAY_TEXT = {
    invoice_end_date: 'On date',
    invoice_end_cycle: 'After number of occurrences',
    invoice_never_end: 'Never'
  }
  const INVOICE_DUE_DATE_VALUES = ['upon receipt', '1 week', '2 weeks', '30 days', 'end of month']

  const [hasContact, setHasContact] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [selectedContact, setSelectedContact] = useState(_.get(invoiceItem, 'contact_details', ''))
  const [twilioEnabled, setTwilioEnabled] = useState(false)
  const [customName, setCustomName] = useState('')
  const [errorMessageSendVia, setErrorMessageSendVia] = useState('')
  const [paymentAllowed, setPaymentAllowed] = useState(false)
  const [payment_type, setPaymentType] = useState('')

  // Invoice Details hooks

  const [editSendType, setEditSendType] = useState(false)
  const [editSendDate, setEditSendDate] = useState(false)
  const [editScheduledDate, setEditScheduledDate] = useState(false)

  const [editEndType, setEditEndType] = useState(false)
  const [editEndDate, setEditEndDate] = useState(false)
  const [editOccurrences, setEditOccurrences] = useState(
    invoiceItem && (invoiceItem.end_type === 'invoice_end_cycle' || invoiceItem.invoice_end_cycle !== null)
  )

  const [editRecurringFrequency, setEditRecurringFrequency] = useState(false)
  const [pendingRecurringFrequency, setPendingRecurringFrequency] = useState('')
  const [editRecurringDay, setEditRecurringDay] = useState(false)

  const [editDueDate, setEditDueDate] = useState(false)

  const [editSeriesBaseDate, setEditSeriesBaseDate] = useState(
    getEditSeriesBaseDate ? getEditSeriesBaseDate(allRecurringPayments, recurringSeriesDetail.invoice_start_date) : null
  )

  // Invoice Items hooks

  const [editDescription, setEditDescription] = useState(false)
  const [currentItemIndex, setCurrentItemIndex] = useState(-1)

  useEffect(() => {
    const meDefault = me || {}
    const hasTwilioAssigned =
      meDefault.account_settings && meDefault.account_settings.twilio && meDefault.account_settings.twilio.length
    const isTrialUser = meDefault.role_id && meDefault.role_id.role_name === 'trial'
    const twilioEnabled = hasTwilioAssigned || isTrialUser

    let ErrorMessageSendVia = t('payments.sms-service-is-not-enabled-in-your-account-try-with-an-email-instead')
    if (twilioEnabled) {
      ErrorMessageSendVia = t('payments.please-select-email-or-sms-checkbox-0')
    }

    let paymentAllowed = !!(
      me &&
      me.account_settings &&
      me.account_settings.payment &&
      me.account_settings.payment.merchant_id
    )

    const payment_type = me && me.account_settings && me.account_settings.payment_type

    if (payment_type === 'stripe') {
      paymentAllowed = paymentStatus === 'tested'
    }

    setTwilioEnabled(twilioEnabled)
    setErrorMessageSendVia(ErrorMessageSendVia)
    setPaymentAllowed(paymentAllowed)
    setPaymentType(payment_type)

    if (invoiceItem) {
      setHasContact(true)
    }

    const popupInvoiceElem = document.querySelector('#modalSendRecurringInvoiceLink')
    popupInvoiceElem.addEventListener('click', function (e) {
      if (!e.target.closest('.send-via-value')) {
        setEditSendType(false)
      }
      if (!e.target.closest('.send-immeediatly-value')) {
        setEditSendDate(false)
        setEditScheduledDate(false)
      }
      if (!e.target.closest('.due-date-value')) {
        setEditDueDate(false)
      }
      if (!e.target.closest('.repeat-schedule-value')) {
        setEditRecurringDay(false)
        setEditRecurringFrequency(false)
      }
      if (!e.target.closest('.end-type-value')) {
        setEditEndType(false)
        setEditEndDate(false)
      }
    })

    async function loadServices(data) {
      await autoSuggestServices(data)
    }
    const data = {
      nopaging: true,
      search: '',
      source: 'direct'
    }
    loadServices(data)
  }, [])

  // Helper functions for invoice popup navigation

  const closePopupModal = () => {
    closeModal(isFormChanged)
    isFormChanged = false
  }
  const switchToInvoiceModal = () => {
    openInvoiceModal()
    closePopupModal()
  }

  // Helper functions for Formik

  const handleCancel = () => {
    closeModal(isFormChanged)
    isFormChanged = false
  }
  const handleSubmit = async (values, { setFieldValue }) => {
    if (
      popupType !== POPUP_TYPES.EDIT_SERIES &&
      popupType !== POPUP_TYPES.EDIT_INVOICE &&
      values.send_type !== 'immediatly' &&
      !values.scheduled_date
    ) {
      const scheduledDate = getNextInvoiceDate('', '', values.recurring_frequency, values.recurring_day)
      values.scheduled_date = moment(scheduledDate).format('MM/DD/yyyy')
    }
    let flag = true
    if (values.invoice_items.length) {
      values.invoice_items.forEach((item) => {
        if (item && (item.service_name === '' || item.service_price === 0 || item.service_price === '')) {
          flag = false
          notification('error', t('payments.required-item-name-and-rate'))
        }
      })
    }

    if (
      popupType !== POPUP_TYPES.EDIT_SERIES &&
      popupType !== POPUP_TYPES.EDIT_INVOICE &&
      values.send_type !== 'immediatly' &&
      values.scheduled_date &&
      new Date(values.scheduled_date).getDate() === new Date().getDate() &&
      new Date(values.scheduled_date).getMonth() === new Date().getMonth() &&
      new Date(values.scheduled_date).getFullYear() === new Date().getFullYear() &&
      isPastPacificTime(10)
    ) {
      flag = false
      notification('error', t('payments.cannot-schedule-on-same-day-past-10am-pacific-time'))
    }

    if (
      values.scheduled_date &&
      values.end_date &&
      Date.parse(values.end_date) <= Date.parse(values.scheduled_date) &&
      values.end_type !== 'invoice_never_end'
    ) {
      flag = false
      notification('error', t('payments.start-date-should-be-before-end-date'))
    }
    if (values.end_type === 'invoice_end_cycle' && parseInt(values.recurring_occurrences) < 1) {
      flag = false
      notification('error', t('payments.number-of-occurrences-must-be-at-least-1'))
    }
    if (
      POPUP_TYPES.EDIT_SERIES &&
      values.end_type === 'invoice_end_cycle' &&
      values.recurring_occurrences < invoiceItem.recurring_current_cycle
    ) {
      flag = false
      notification('error', t('payments.number-of-occurrences-must-be-at-least') + invoiceItem.recurring_current_cycle)
    }
    if (flag) {
      const polishedInvoice = { ...values }
      polishedInvoice.due_date = polishedInvoice.invoice_due

      if (values.end_type === 'invoice_end_cycle') {
        polishedInvoice.invoice_end_cycle = values.recurring_occurrences
        polishedInvoice.invoice_end_date = null
        polishedInvoice.invoice_never_end = false
        polishedInvoice.end_date = ''
      } else if (values.end_type === 'invoice_end_date' && values.end_date) {
        polishedInvoice.invoice_end_date = new Date(values.end_date)
        polishedInvoice.invoice_end_cycle = null
        polishedInvoice.invoice_never_end = false
      } else if (values.end_type === 'invoice_never_end') {
        polishedInvoice.invoice_end_date = null
        polishedInvoice.invoice_end_cycle = null
        polishedInvoice.invoice_never_end = true
        polishedInvoice.end_date = ''
      } else {
        notification('error', t('payments.specify-recurring-invoice-series-method-of-termination'))
        return
      }

      let invoiceDate = ''
      if (_.get(values, 'send_type') === 'immediatly') {
        invoiceDate = moment().format('MM/DD/yyyy')
      } else {
        invoiceDate = values.scheduled_date
        polishedInvoice.scheduled_date = values.scheduled_date
      }

      switch (values.invoice_due) {
        case INVOICE_DUE_DATE_VALUES[0]:
          if (_.get(values, 'send_type') === 'immediatly') {
            setFieldValue('invoice_due', '')
            polishedInvoice.invoice_due = ''
          } else {
            polishedInvoice.invoice_due = invoiceDate
            setFieldValue('invoice_due', invoiceDate)
          }
          break
        case INVOICE_DUE_DATE_VALUES[1]:
          polishedInvoice.invoice_due = moment(invoiceDate).add(7, 'days').format('MM/DD/yyyy')
          setFieldValue('invoice_due', new Date(polishedInvoice.invoice_due))
          break
        case INVOICE_DUE_DATE_VALUES[2]:
          polishedInvoice.invoice_due = moment(invoiceDate).add(14, 'days').format('MM/DD/yyyy')
          setFieldValue('invoice_due', new Date(polishedInvoice.invoice_due))
          break
        case INVOICE_DUE_DATE_VALUES[3]:
          polishedInvoice.invoice_due = moment(invoiceDate).add(30, 'days').format('MM/DD/yyyy')
          setFieldValue('invoice_due', new Date(polishedInvoice.invoice_due))
          break
        case INVOICE_DUE_DATE_VALUES[4]:
          polishedInvoice.invoice_due = moment(invoiceDate).endOf('month').format('MM/DD/yyyy')
          setFieldValue('invoice_due', new Date(polishedInvoice.invoice_due))
          break
        default:
          break
      }

      if (values.customer_email && selectedContact) {
        if (values.customer_email !== selectedContact.email) {
          setFieldValue('isEmailChanged', true)
          polishedInvoice.isEmailChanged = true
        }
      } else if (values.customer_email && !selectedContact && privateContact && privateContact.data) {
        if (values.customer_email !== privateContact.data.email || !privateContact.data.email) {
          setFieldValue('isEmailChanged', true)
          polishedInvoice.isEmailChanged = true
        }
      }
      if (values.customer_phone && selectedContact) {
        if (values.customer_phone !== phoneNumberFormat(selectedContact.mobile)) {
          setFieldValue('isMobileChanged', true)
          polishedInvoice.isMobileChanged = true
        }
      } else if (values.customer_phone && !selectedContact && privateContact && privateContact.data) {
        if (values.customer_phone !== phoneNumberFormat(privateContact.data.mobile) || !privateContact.data.mobile) {
          setFieldValue('isMobileChanged', true)
          polishedInvoice.isMobileChanged = true
        }
      }
      if (selectedContact) {
        polishedInvoice.customer_address = selectedContact.address
        polishedInvoice.customer_address2 = selectedContact.address2
        polishedInvoice.customer_city = selectedContact.city
        polishedInvoice.customer_state = selectedContact.state
        polishedInvoice.customer_zip = selectedContact.zip
        polishedInvoice.customer_country = selectedContact.country
      }
      if (values.invoice_items.length) {
        const newInvoiceItems = values.invoice_items.map((item, index) => {
          if (item) {
            return { ...item, sort_order: index + 1 }
          } else return null
        })
        setFieldValue('invoice_items', newInvoiceItems)
        polishedInvoice.invoice_items = newInvoiceItems
      }
      const invoiceTotalAmount = calculateTotal(values)
      setFieldValue('invoice_amount', invoiceTotalAmount)
      polishedInvoice.invoice_amount = invoiceTotalAmount
      if (customName) {
        const name = customName.split(' ')
        setFieldValue('customer_first_name', name[0])
        polishedInvoice.customer_first_name = name[0]
        if (name.length > 1) {
          const newCustomName = customName.replace(name[0], '')
          setFieldValue('customer_last_name', newCustomName)
          polishedInvoice.customer_last_name = newCustomName
        }
        setFieldValue('isNameChanged', true)
        polishedInvoice.isNameChanged = true
      }
      if (polishedInvoice.due_date && !INVOICE_DUE_DATE_VALUES.includes(polishedInvoice.due_date)) {
        polishedInvoice.due_date = new Date(polishedInvoice.due_date)
      }
      const scheduledDate = popupType === POPUP_TYPES.EDIT_SERIES ? editSeriesBaseDate : polishedInvoice.scheduled_date
      const nextInvoiceDate = getNextInvoiceDate(
        scheduledDate,
        polishedInvoice.end_date,
        polishedInvoice.recurring_frequency,
        polishedInvoice.recurring_day
      )
      // if (!nextInvoiceDate) {
      //   notification('error', 'Next invoice in series is not scheduled')
      //   return
      // }
      if (nextInvoiceDate) {
        if (popupType === POPUP_TYPES.EDIT_SERIES) {
          polishedInvoice.scheduled_date = nextInvoiceDate ? new Date(nextInvoiceDate.format('L')) : ''
        }
      }
      if (!polishedInvoice.scheduled_date) {
        polishedInvoice.scheduled_date = new Date()
      } else {
        polishedInvoice.scheduled_date = new Date(polishedInvoice.scheduled_date)
      }
      polishedInvoice.utc_offset =
        values.send_type === 'immediatly' ? moment().format('Z') : moment(polishedInvoice.scheduled_date).format('Z')
      polishedInvoice.reminder = true
      await confirmInvoice({
        ...polishedInvoice,
        recurring_invoice_id: _.get(invoiceItem, 'recurring_invoice_id', null),
        invoice_id: _.get(invoiceItem, 'invoice_id', null)
      })
    }
  }

  // Helper functions for "Bill To" (integration with Contacts)

  const autoSuggestContacts = async (input) => {
    if (input.length >= 2) {
      const data = {
        nopaging: true,
        status: 'active',
        excludeNullGroup: true,
        search: input || ''
      }
      if (products && isEnabled('contact-manager', products)) {
        const contactList = await autosuggestContacts(data)
        return [
          {
            options: contactList && contactList.length > 0 ? contactList : []
          }
        ]
      } else {
        return [
          {
            options: []
          }
        ]
      }
    }
  }
  const handleSelectOption = async function (setFieldValue, data, field, action) {
    isFormChanged = true
    if (action && action.action === 'clear') {
      setHasContact(false)
      setSelectedValue('')
      setFieldValue(field, '')
    }
    let contact = null
    if (data && data.value && data.contactObj) {
      contact = data.contactObj

      setFieldValue('customer_id', contact.contact_id)
      setFieldValue('customer_first_name', contact.first_name)
      setFieldValue('customer_last_name', contact.last_name)
      setFieldValue('customer_email', contact.email)
      setFieldValue('customer_full_name', contact.customer_full_name)

      setFieldValue('customer_address', contact.address)
      setFieldValue('customer_address2', contact.address2)
      setFieldValue('customer_city', contact.city)
      setFieldValue('customer_state', contact.state)
      setFieldValue('customer_zip', contact.zip)

      setFieldValue('customer_phone', phoneNumberFormat(contact.mobile) ? phoneNumberFormat(contact.mobile) : '')
      setFieldValue('photo', contact.image)
      if (contact.email) {
        setFieldValue('send_email', true)
      }
      if (contact.mobile && twilioEnabled) {
        setFieldValue('send_sms', true)
      }
      const val = {
        image: data.image,
        label: _.get(data, 'label', _.get(data, 'subLabel', data.value)),
        value: data.value
      }
      setHasContact(true)
      setSelectedContact(contact)
      setSelectedValue(val)
      setFieldValue(field, data.value)
    }
    if ((action && action.action === 'create-option') || (action && action.action === 'select-option' && !contact)) {
      const mobileTest = validateMobile(data.value)
      const emailTest = validateEmail(data.value)
      if (data.value && !mobileTest && !emailTest) {
        const meDefault = me || {}
        const hasTwilioAssigned =
          meDefault.account_settings && meDefault.account_settings.twilio && meDefault.account_settings.twilio.length
        const isTrialUser = meDefault.role_id && meDefault.role_id.role_name === 'trial'
        const twilioEnabled = hasTwilioAssigned || isTrialUser

        const message = twilioEnabled
          ? t('payments.please-enter-a-valid-email-or-mobile')
          : t('payments.please-enter-a-valid-email')
        notification('error', message)
        setHasContact(false)
        setSelectedValue('')
        setFieldValue(field, '')
      } else {
        let payload = {}
        if (mobileTest) {
          payload = {
            mobile: phoneNumberFormat(data.value),
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          setFieldValue('customer_phone', phoneNumberFormat(data.value) ? phoneNumberFormat(data.value) : '')
          if (twilioEnabled) {
            setFieldValue('send_sms', true)
          }
        }
        if (emailTest) {
          payload = {
            email: data.value,
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          setFieldValue('customer_email', data.value)
          setFieldValue('send_email', true)
        }
        const val = {
          label: data.value,
          value: data.value
        }
        setHasContact(true)
        setSelectedValue(val)
        const contactData = await savePrivateContact(payload)
        setFieldValue('customer_id', contactData && contactData.data && contactData.data._id)
      }
    }
  }
  const editContact = async function (setFieldValue) {
    setFieldValue('customer_email', '')
    setFieldValue('customer_first_name', '')
    setFieldValue('customer_last_name', '')
    setFieldValue('customer_id', '')
    setFieldValue('customer_phone', '')
    setFieldValue('send_email', false)
    setFieldValue('send_sms', false)
    setFieldValue('photo', '')
    setHasContact(false)
    setEditRecurringFrequency(false)
    setEditRecurringDay(false)
    setEditSendType(false)
    setSelectedContact('')
    setCustomName('')
    setSelectedValue('')
  }
  const setOriginalValues = (setFieldValue, type) => {
    if (type === 'customer_email') {
      setFieldValue('customer_email', selectedContact.email)
    } else if (type === 'mobile') {
      setFieldValue(
        'customer_phone',
        phoneNumberFormat(selectedContact.mobile) ? phoneNumberFormat(selectedContact.mobile) : ''
      )
    }
  }

  // Helper functions for invoice details

  const getNextInvoiceDate = (scheduledDate, endDate, recurringFrequency, recurringDay) => {
    const weekday = RECURRING_DAY_VALUES[recurringDay]
    const todayMoment = scheduledDate ? moment(scheduledDate) : moment()
    let nextInvoiceDate
    if (weekday < 7) {
      const today = todayMoment.weekday()
      if (today < weekday) {
        if (recurringFrequency === 'every week') {
          nextInvoiceDate = todayMoment.weekday(weekday)
        } else if (recurringFrequency === 'every 2 weeks') {
          nextInvoiceDate = todayMoment.add(1, 'weeks').weekday(weekday)
        }
      } else {
        if (recurringFrequency === 'every month') {
          nextInvoiceDate = todayMoment.add(1, 'months').weekday(weekday)
        } else {
          const addWeeks = recurringFrequency === 'every week' ? 1 : recurringFrequency === 'every 2 weeks' ? 2 : 0
          nextInvoiceDate = todayMoment.add(addWeeks, 'weeks').weekday(weekday)
        }
      }
    } else if (recurringFrequency === 'every month' && weekday >= 7) {
      const currentDayOfMonth = scheduledDate ? new Date(moment(scheduledDate)).getDate() : new Date().getDate()
      if (recurringDay === 'first') {
        nextInvoiceDate = todayMoment.add(1, 'months').set('date', 1)
      } else if (recurringDay === 'fifteenth') {
        if (currentDayOfMonth < 15) {
          nextInvoiceDate = todayMoment.set('date', 15)
        } else {
          nextInvoiceDate = todayMoment.add(1, 'months').set('date', 15)
        }
      }
    } else return ''

    if (endDate && new Date(endDate).setHours(0, 0, 0, 0) < new Date(nextInvoiceDate).setHours(0, 0, 0, 0)) {
      return ''
    }
    return nextInvoiceDate
  }
  const updateRecurringFrequency = async function (
    setFieldValue,
    selectedRecurringFrequency,
    selectedRecurringDay = null
  ) {
    if (!RECURRING_FREQUENCY_VALUES.includes(selectedRecurringFrequency)) return
    if (selectedRecurringDay && selectedRecurringFrequency === 'every month') {
      setFieldValue('recurring_frequency', 'every month')
      setFieldValue('recurring_day', selectedRecurringDay)
      setPendingRecurringFrequency('')
    } else {
      setPendingRecurringFrequency(selectedRecurringFrequency)
      setEditRecurringDay(true)
    }
    setFieldValue('scheduled_date', '')
    setEditRecurringFrequency(false)
    if (getEditSeriesBaseDate) {
      setEditSeriesBaseDate(getEditSeriesBaseDate(allRecurringPayments, recurringSeriesDetail.invoice_start_date))
    }
  }
  const updateRecurringDay = async function (setFieldValue, selectedRecurringDay) {
    if (!Object.keys(RECURRING_DAY_VALUES).includes(selectedRecurringDay)) return
    if (pendingRecurringFrequency.length) {
      setFieldValue('recurring_frequency', pendingRecurringFrequency)
      setFieldValue('recurring_day', selectedRecurringDay)
      setPendingRecurringFrequency('')
    }
    setFieldValue('scheduled_date', '')
    setEditRecurringDay(false)
    if (getEditSeriesBaseDate) {
      setEditSeriesBaseDate(getEditSeriesBaseDate(allRecurringPayments, recurringSeriesDetail.invoice_start_date))
    }
  }
  const updateSendDate = async function (setFieldValue, type) {
    if (type === 'immediatly') {
      setFieldValue('send_type', 'immediatly')
      setFieldValue('scheduled_date', '')
      setEditSendDate(false)
    } else {
      setEditSendDate(false)
      setEditScheduledDate(true)
    }
  }
  const updateEndType = async function (setFieldValue, selectedEndType) {
    if (!Object.keys(END_TYPE_DISPLAY_TEXT).includes(selectedEndType)) return
    if (selectedEndType === 'invoice_never_end') {
      setFieldValue('end_type', 'invoice_never_end')
      setFieldValue('end_date', '')
      setEditOccurrences(false)
    } else {
      if (selectedEndType === 'invoice_end_cycle') {
        setFieldValue('end_type', 'invoice_end_cycle')
        setFieldValue('end_date', '')
        setEditOccurrences(true)
      } else if (selectedEndType === 'invoice_end_date') {
        setEditEndDate(true)
      }
    }
    setEditEndType(false)
  }
  const updateDueDate = async function (setFieldValue, type) {
    setFieldValue('invoice_due', type)
    setEditDueDate(false)
  }

  // Helper functions for invoice items

  const autoSuggestServicesCaller = async function (input) {
    const data = {
      nopaging: true,
      search: input || '',
      source: 'direct'
    }
    await autoSuggestServices(data)
    return [
      {
        options: servicesList && servicesList.length > 0 ? servicesList : []
      }
    ]
  }
  const handleSelectOptionService = async function (values, setFieldValue, data, field, action, index) {
    isFormChanged = true
    if (action && action.action === 'clear') {
      setFieldValue(field, '')
    }
    if (data && data.value && action && action.action !== 'create-option' && !data.__isNew__) {
      setFieldValue(field, data.label)
      setFieldValue(
        'invoice_items',
        values.invoice_items.map((item, currentIndex) =>
          currentIndex !== index
            ? item
            : {
                ...item,
                service_name: data.label,
                service_id: data.value,
                service_price: _.get(data, 'subLabel', ''),
                service_price_total: data.subLabel
                  ? item.service_quantity
                    ? item.service_quantity * data.subLabel
                    : data.subLabel
                  : item.service_price_total,
                isPayment: !!data.subLabel,
                service_status: 'active',
                tempGoStop: true // temporary gostop to prevent user from editing item rate
              }
        )
      )
    }
    if (
      (action && action.action === 'create-option') ||
      (action && action.action === 'select-option' && data.__isNew__)
    ) {
      if (data) {
        setFieldValue(field, data.value)
        setFieldValue(
          'invoice_items',
          values.invoice_items.map((item, currentIndex) =>
            currentIndex !== index
              ? item
              : {
                  ...item,
                  service_name: data.value,
                  service_id: '',
                  service_price: '',
                  service_status: 'open',
                  tempGoStop: false
                }
          )
        )
      }
    }
  }
  const editDescriptionBtnClick = (item, index) => {
    if (!item.service_description && editDescription && currentItemIndex === index) {
      setEditDescription(false)
    } else {
      setEditDescription(true)
      setCurrentItemIndex(index)
    }
    window.$('#serviceDescription_' + index).focus()
  }
  const onRemoveItem = (setFieldValue, values, index) => {
    const keyArray = [...values.invoice_items]
    keyArray.splice(index, 1)
    setFieldValue('invoice_items', keyArray)
  }
  const onAddItem = (setFieldValue, values) => {
    isFormChanged = true
    const keyArray = [...values.invoice_items]
    const key = {
      service_id: '',
      service_name: '',
      service_price: 0,
      service_description: '',
      service_price_total: 0,
      service_quantity: 1,
      service_status: 'active',
      created_timestamp: new Date().getTime()
    }
    keyArray.push(key)
    setFieldValue('invoice_items', keyArray)
  }
  const calculateTotal = (values) => {
    let totalCost = 0
    if (values.invoice_items) {
      totalCost = values.invoice_items.reduce(
        (total, currentItem) => (total += _.get(currentItem, 'service_price_total', 0)),
        0
      )
    }
    if (totalCost > 0) {
      return totalCost
    } else {
      return 0
    }
  }

  return (
    <Popup
      open={open}
      title={POPUP_TYPE_SETTINGS[popupType].title}
      onCancel={() => closePopupModal.bind(this)}
      type="medium"
      modalBackdropClicked={() => console.log(null)}
      id="modalSendRecurringInvoiceLink"
      customClass="modal-send-invoice">
      <Formik
        validateOnChange={false}
        validateOnBlur
        enableReinitialize
        initialValues={{
          // For validation purposes
          contact: _.get(invoiceItem, 'contact', ''),
          sendVia: _.get(invoiceItem, 'sendVia', ''),
          items: _.get(invoiceItem, 'contact', ''),

          // Information about the contact
          customer_id: _.get(invoiceItem, 'customer_id', ''),
          customer_first_name: _.get(invoiceItem, 'customer_first_name', ''),
          customer_last_name: _.get(invoiceItem, 'customer_last_name', ''),
          customer_email: _.get(invoiceItem, 'customer_email', ''),
          customer_phone: phoneNumberFormat(_.get(invoiceItem, 'customer_phone', '')) || '',
          customer_full_name: _.get(invoiceItem, 'customer_full_name', ''),

          customer_address: _.get(invoiceItem, 'customer_address', false),
          customer_address2: _.get(invoiceItem, 'customer_address2', false),
          customer_city: _.get(invoiceItem, 'customer_city', false),
          customer_state: _.get(invoiceItem, 'customer_state', false),
          customer_zip: _.get(invoiceItem, 'customer_zip', false),

          isEmailChanged: _.get(invoiceItem, 'isEmailChanged', false),
          isMobileChanged: _.get(invoiceItem, 'isMobileChanged', false),
          isNameChanged: _.get(invoiceItem, 'isNameChanged', false),

          // Invoice customization details
          recurring_frequency: _.get(invoiceItem, 'recurring_frequency', 'every week'),
          recurring_day: _.get(invoiceItem, 'recurring_day', 'monday'),
          send_type: _.has(invoiceItem, 'invoice_start_date')
            ? 'schedule'
            : _.get(invoiceItem, 'send_type', invoiceItem.scheduled_date ? 'schedule' : 'schedule'),
          scheduled_date:
            popupType === POPUP_TYPES.EDIT_SERIES
              ? _.get(invoiceItem, 'invoice_start_date', '')
              : _.get(invoiceItem, 'scheduled_date', '')
              ? _.get(invoiceItem, 'scheduled_date', '')
              : '',
          end_type: invoiceItem
            ? invoiceItem.end_type
              ? invoiceItem.end_type
              : determineEndType(invoiceItem)
            : 'invoice_never_end',
          end_date: _.get(invoiceItem, 'end_date', _.get(invoiceItem, 'invoice_end_date', '')),
          send_email: _.get(invoiceItem, 'send_email', false),
          send_sms: _.get(invoiceItem, 'send_sms', false),
          recurring_occurrences: invoiceItem
            ? invoiceItem.recurring_occurrences
              ? invoiceItem.recurring_occurrences
              : invoiceItem.invoice_end_cycle
              ? invoiceItem.invoice_end_cycle
              : '1'
            : '1',
          invoice_due: _.get(invoiceItem, 'due_date', _.get(invoiceItem, 'invoice_due', INVOICE_DUE_DATE_VALUES[0])),
          due_date: _.get(invoiceItem, 'due_date', _.get(invoiceItem, 'invoice_due', INVOICE_DUE_DATE_VALUES[0])),

          // Invoice items
          invoice_amount: _.get(invoiceItem, 'invoice_amount', 0),
          invoice_items: _.get(invoiceItem, 'invoice_items', []),
          invoice_number: _.get(invoiceItem, 'invoice_number', ''),

          source: _.get(invoiceItem, 'source', 'direct'),
          photo: _.get(invoiceItem, 'photo', ''),
          collect_payments: _.get(invoiceItem, 'collect_payments', paymentAllowed),
          bank_transfer: _.get(invoiceItem, 'bank_transfer', paymentAllowed)
        }}
        validationSchema={Yup.object().shape({
          customer_email: Yup.string().email(t('payments.please-enter-valid-email')).nullable(),
          customer_phone: Yup.string().matches(
            /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/,
            t('payments.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006-0')
          ),
          contact: Yup.string().test('email', t('payments.required'), function (value) {
            const customerEmail = _.get(this.parent, 'customer_email', null)
            const customerPhone = _.get(this.parent, 'customer_phone', null)
            if (!customerEmail && !customerPhone) {
              return value != null
            }
            return true
          }),
          items: Yup.string().test('invoice_items', t('payments.required'), function (value) {
            const invoiceItems = _.get(this.parent, 'invoice_items', [])
            if (!invoiceItems.length) {
              return value != null
            }
            return true
          }),
          sendVia: Yup.string().test('send_email', `${errorMessageSendVia}`, function (value) {
            const sendEmail = _.get(this.parent, 'send_email', false)
            const sendSMS = _.get(this.parent, 'send_sms', false)
            if (!sendEmail && !sendSMS) {
              return value != null
            }
            return true
          })
        })}
        onReset={handleCancel.bind(this)}
        onSubmit={handleSubmit.bind(this)}
        render={({ values, setFieldValue }) => {
          const baseScheduledDate = {
            [POPUP_TYPES.EDIT_INVOICE]: editInvoiceBaseDate,
            [POPUP_TYPES.EDIT_SERIES]: editSeriesBaseDate
          }
          const nextInvoiceDate = getNextInvoiceDate(
            popupType !== POPUP_TYPES.EDIT_SERIES && popupType !== POPUP_TYPES.EDIT_INVOICE
              ? values.scheduled_date
              : baseScheduledDate[popupType],
            values.end_date,
            values.recurring_frequency,
            values.recurring_day
          )

          const CUSTOMER_NAME_BLANK = !values.customer_first_name && !values.customer_last_name
          const EMAIL_WAS_EDITED =
            values && selectedContact && selectedContact.email && values.customer_email !== selectedContact.email
          const PHONE_NUMBER_WAS_EDITED =
            values &&
            selectedContact &&
            selectedContact.mobile &&
            values.customer_phone !== phoneNumberFormat(selectedContact.mobile)

          return (
            <Form className={`pt-20 custom-get-paid-form ${style['get-paid-form']}`}>
              <button
                name="btninvoiceCloseModal"
                type="reset"
                className="close custom-modal-close-button remove-modal-close">
                <span aria-hidden="true">×</span>
              </button>
              <div className="row">
                <div className="col-12">
                  <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                    <label className="label-text">
                      {t('payments.bill-to')} <span className="star">*</span>
                      <ErrorMessage name="contact" component="span" className="form-error" />
                      <ErrorMessage name="customer_email" component="span" className="form-error" />
                      <ErrorMessage name="customer_phone" component="span" className="form-error" />
                    </label>
                    {!hasContact && (
                      <>
                        <AsyncCreatableSelect
                          id="sendRecurringInvoiceContact"
                          isClearable
                          placeholder={t('payments.name-phone-or-email')}
                          className="dropdown-select no-arrow-down"
                          classNamePrefix="dropdownSelect"
                          loadOptions={autoSuggestContacts}
                          value={
                            selectedValue
                              ? { label: selectedValue.label, value: selectedValue.value, image: selectedValue.image }
                              : []
                          }
                          formatOptionLabel={customOptions}
                          noOptionsMessage={() => null}
                          onChange={(event, type) => handleSelectOption(setFieldValue, event, 'contact', type)}
                          formatCreateLabel={(userInput) => (
                            <div className="react-select-unavailable-data">
                              <span className="react-select-highlight-text">{t('payments.create-new-contact')}</span>
                            </div>
                          )}
                          isValidNewOption={(inputValue, selectValue, selectOptions) => createNewVisible(selectOptions)}
                        />
                      </>
                    )}
                    {hasContact && (
                      <div className="selected-user border rounded-sm">
                        <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
                          <div className="user-contact pl-15">{t('payments.contact-0')}</div>
                          {CUSTOMER_NAME_BLANK ? (
                            <>
                              <div className="user-phone-value pr-25">
                                <Field
                                  type="text"
                                  name="customer_full_name"
                                  className="form-control form-control-lg"
                                  placeholder="(Optional)"
                                  value={_.get(values, 'customer_full_name', '')}
                                  disabled={popupType === POPUP_TYPES.EDIT_SERIES}
                                  onChange={(event) => {
                                    setFieldValue('customer_full_name', event.target.value)
                                    setCustomName(event.target.value)
                                    isFormChanged = true
                                  }}
                                />
                              </div>
                              {Boolean(popupType !== POPUP_TYPES.EDIT_SERIES) && (
                                <div className="position-absolute cursor-pointer right-0 mr-15 icon-block">
                                  <Icon
                                    onClick={editContact.bind(this, setFieldValue)}
                                    name="closeThin"
                                    className="stroke-icon d-block"
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {!CUSTOMER_NAME_BLANK && (
                                <figure className={`mb-0 mr-10 ml-30 ${style['b-image']}`}>
                                  <img
                                    className={`w-100 rounded-circle ${style.image}`}
                                    src={
                                      values && values.photo
                                        ? values.photo
                                        : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                    }
                                    alt=""
                                  />
                                </figure>
                              )}
                              <div
                                className={`${
                                  popupType !== POPUP_TYPES.EDIT_SERIES ? 'user-names' : ''
                                } d-flex justify-content-between`}
                                style={{
                                  width: CUSTOMER_NAME_BLANK && '60%'
                                }}>
                                {values && values.customer_first_name && <span>{values.customer_first_name}</span>}
                                {values && values.customer_last_name && (
                                  <span className="mr-auto pl-5">{values.customer_last_name}</span>
                                )}
                                {Boolean(popupType !== POPUP_TYPES.EDIT_SERIES) && (
                                  <Icon
                                    onClick={editContact.bind(this, setFieldValue)}
                                    name="closeThin"
                                    className="stroke-icon"
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row-user-email border-bottom d-flex align-items-center position-relative">
                          <div className="user-email pl-15">{t('payments.email')}</div>
                          <div className="user-email-value pr-20">
                            <Field
                              type="text"
                              name="customer_email"
                              className="form-control form-control-lg"
                              placeholder={values.customer_phone ? '(Optional)' : t('payments.enter-email')}
                              value={_.get(values, 'customer_email', '')}
                              disabled={popupType === POPUP_TYPES.EDIT_SERIES}
                              onChange={(event) => {
                                setFieldValue('customer_email', event.target.value)
                                if (event.target.value) {
                                  setFieldValue('send_email', true)
                                } else {
                                  setFieldValue('send_email', false)
                                }
                                isFormChanged = true
                              }}
                            />
                          </div>
                          {EMAIL_WAS_EDITED && (
                            <div
                              className=" position-absolute cursor-pointer right-0 mr-15"
                              onClick={
                                popupType !== POPUP_TYPES.EDIT_SERIES
                                  ? setOriginalValues.bind(this, setFieldValue, 'customer_email')
                                  : undefined
                              }>
                              <Tooltip position="left" message={t('payments.revert-to-email-on-file')}>
                                <Icon name="undoThin" className={`${style.undo}`} />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        <div className="row-user-phone  d-flex align-items-center position-relative">
                          <div className="user-phone pl-15">{t('payments.phone')}</div>
                          <div className="user-phone-value pr-20">
                            <Field
                              type="text"
                              name="mobile"
                              className="form-control form-control-lg"
                              placeholder={values.customer_email ? t('payments.optional') : t('payments.enter-mobile')}
                              value={_.get(values, 'customer_phone', '')}
                              maxLength={14}
                              disabled={popupType === POPUP_TYPES.EDIT_SERIES}
                              onChange={(event) => {
                                setFieldValue(
                                  'customer_phone',
                                  numberMask(event.target.value) ? numberMask(event.target.value) : ''
                                )
                                if (event.target.value && twilioEnabled) {
                                  setFieldValue('send_sms', true)
                                } else {
                                  setFieldValue('send_sms', false)
                                }
                                isFormChanged = true
                              }}
                            />
                          </div>
                          {PHONE_NUMBER_WAS_EDITED && (
                            <div
                              className="position-absolute cursor-pointer right-0 mr-15"
                              onClick={
                                popupType !== POPUP_TYPES.EDIT_SERIES
                                  ? setOriginalValues.bind(this, setFieldValue, 'mobile')
                                  : undefined
                              }>
                              <Tooltip position="left" message={t('payments.revert-to-phone-on-file')}>
                                <Icon name="undoThin" className={`${style.undo}`} />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                    <label className="label-text">
                      {t('payments.invoice-details')}
                      <ErrorMessage name="sendVia" component="span" className="form-error" />
                    </label>
                    <div className="invoice-detail border rounded-sm">
                      {Boolean(popupType !== POPUP_TYPES.EDIT_INVOICE) && (
                        <div className="row-repeat-schedule border-bottom d-flex align-items-center">
                          <div className="repeat-schedule px-15">{t('payments.repeat')}</div>
                          <div className="repeat-schedule-value">
                            <div
                              className="px-15 repeat-schedule-value-item d-flex align-items-center justify-content-between"
                              onClick={() => {
                                if (popupType !== POPUP_TYPES.EDIT_INVOICE) {
                                  setEditRecurringFrequency(!editRecurringFrequency)
                                  setEditRecurringDay(false)
                                }
                              }}>
                              <span>
                                {displayRecurringProperties(values.recurring_frequency, values.recurring_day)}
                              </span>
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            </div>
                            {Boolean(editRecurringFrequency) && (
                              <div className="repeat-schedule-frequency send-immeediatly-calendar react-datepicker-popper">
                                <div
                                  className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                  onClick={() => updateRecurringFrequency(setFieldValue, 'every week')}>
                                  {t('payments.every-week')}
                                </div>
                                <div
                                  className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                  onClick={() => updateRecurringFrequency(setFieldValue, 'every 2 weeks')}>
                                  {t('payments.every-2-weeks')}
                                </div>
                                <div
                                  className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                  onClick={() => updateRecurringFrequency(setFieldValue, 'every month', 'first')}>
                                  {t('payments.every-1st-of-the-month')}
                                </div>
                                <div
                                  className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                  onClick={() => updateRecurringFrequency(setFieldValue, 'every month', 'fifteenth')}>
                                  {t('payments.every-15th-of-the-month')}
                                </div>
                                {popupType !== POPUP_TYPES.EDIT_SERIES && (
                                  <div
                                    className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                    onClick={switchToInvoiceModal}>
                                    {t('payments.does-not-repeat')}
                                  </div>
                                )}
                              </div>
                            )}
                            {Boolean(editRecurringDay) && (
                              <div className="repeat-schedule-frequency send-immeediatly-calendar react-datepicker-popper">
                                {Object.keys(RECURRING_DAY_VALUES).map((day) => {
                                  if (day !== 'first' && day !== 'fifteenth') {
                                    return (
                                      <div
                                        key={day}
                                        className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                        onClick={() => updateRecurringDay(setFieldValue, day)}>
                                        {_.capitalize(day) + 's'}
                                      </div>
                                    )
                                  } else return null
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row-send-immeediatly border-bottom d-flex align-items-center">
                        <div className="send-immeediatly px-15">
                          {popupType === POPUP_TYPES.EDIT_INVOICE ? t('payments.send') : t('payments.start')}
                        </div>
                        <div className="send-immeediatly-value">
                          <div
                            className={`px-15 send-immeediatly-value-date d-flex align-items-center justify-content-between ${
                              popupType === POPUP_TYPES.EDIT_SERIES ? 'disabled' : ''
                            }`}
                            style={{ cursor: popupType === POPUP_TYPES.EDIT_SERIES ? 'not-allowed' : '' }}
                            onClick={() => {
                              if (popupType !== POPUP_TYPES.EDIT_SERIES) {
                                setEditSendType(false)
                                setEditScheduledDate(false)
                                if (popupType === POPUP_TYPES.EDIT_INVOICE) {
                                  setEditScheduledDate(!editScheduledDate)
                                } else {
                                  setEditSendDate(!editSendDate)
                                }
                              }
                            }}>
                            <div className="d-flex flex-column">
                              {values.send_type === 'immediatly' ? (
                                <span>{SEND_TYPE_DISPLAY_TEXT.immediatly}</span>
                              ) : popupType === POPUP_TYPES.EDIT_SERIES ? (
                                <span className="send-label">
                                  {moment(_.get(invoiceItem, 'invoice_start_date')).format('MM/DD/YYYY')}
                                </span>
                              ) : (
                                <span className="send-label">
                                  {values.scheduled_date
                                    ? moment(values.scheduled_date).format('MM/DD/YYYY')
                                    : nextInvoiceDate
                                    ? moment(nextInvoiceDate).format('MM/DD/YYYY')
                                    : null}
                                </span>
                              )}
                              {values.send_type === 'schedule' && popupType !== POPUP_TYPES.EDIT_SERIES && (
                                <span className="send-next-date">
                                  {values.scheduled_date || nextInvoiceDate
                                    ? t('payments.next-invoice-sent-on-0', {
                                        0: moment(values.scheduled_date || nextInvoiceDate).format('ll')
                                      })
                                    : ''}
                                </span>
                              )}
                            </div>
                            {popupType !== POPUP_TYPES.EDIT_SERIES && (
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            )}
                          </div>
                          {Boolean(editSendDate) && (
                            <div className="send-immeediatly-calendar react-datepicker-popper">
                              <div
                                className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                onClick={updateSendDate.bind(this, setFieldValue, 'immediatly')}>
                                {SEND_TYPE_DISPLAY_TEXT.immediatly}
                              </div>
                              <div
                                className="send-immeediatly-calendar-item px-15 py-10"
                                onClick={updateSendDate.bind(this, setFieldValue, 'schedule')}>
                                {SEND_TYPE_DISPLAY_TEXT.schedule}
                              </div>
                            </div>
                          )}
                          {Boolean(editScheduledDate) && (
                            <div className="send-immeediatly-calendar react-datepicker-popper hide-outside-dates ">
                              <DatePicker
                                inline
                                id="sendInvoice_DatePicker"
                                selected={
                                  values.scheduled_date
                                    ? new Date(moment(values.scheduled_date).format('L'))
                                    : nextInvoiceDate
                                    ? new Date(moment(nextInvoiceDate).format('L'))
                                    : null
                                }
                                dateFormat="MM/dd/yyyy"
                                className="form-control border-0 p-0 datepicker"
                                name="scheduled_date"
                                placeholderText={values.send_type}
                                minDate={
                                  editSeriesBaseDate && moment(editSeriesBaseDate).isSameOrAfter(moment(), 'day')
                                    ? new Date(moment(editSeriesBaseDate).add(1, 'days').format('L'))
                                    : new Date(moment().add(1, 'days').format('L'))
                                }
                                maxDate={
                                  popupType === POPUP_TYPES.EDIT_INVOICE
                                    ? new Date(moment(nextInvoiceDate).subtract(1, 'days').format('L'))
                                    : undefined
                                }
                                onChange={(event) => {
                                  setFieldValue('scheduled_date', moment(event).format('MM/DD/yyyy'))
                                  setFieldValue('send_type', 'schedule')
                                  setFieldValue('invoice_due', INVOICE_DUE_DATE_VALUES[0])
                                  setEditScheduledDate(false)
                                  isFormChanged = true
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {popupType !== POPUP_TYPES.EDIT_INVOICE && (
                        <div className="row-end-type border-bottom d-flex align-items-center">
                          <div className="send-immeediatly px-15">{t('payments.end')}</div>
                          <div className="end-type-value">
                            <div
                              className="px-15 end-type-value-input send-immeediatly-value-date d-flex align-items-center justify-content-between"
                              onClick={() => {
                                setEditEndDate(false)
                                setEditEndType(!editEndType)
                              }}>
                              {editOccurrences ? (
                                <span>
                                  {t('payments.after')}
                                  <div className="occurrences-label" onClick={(e) => e.stopPropagation()}>
                                    <Field
                                      type="text"
                                      name="recurring_occurrences"
                                      placeholder="1"
                                      className="form-control form-control-lg"
                                      value={_.get(values, 'recurring_occurrences', '1')}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const regex = /^[0-9]*$/
                                        if (regex.test(value.toString()) || value.length === 0) {
                                          setFieldValue('recurring_occurrences', value)
                                          isFormChanged = true
                                        }
                                      }}
                                    />
                                  </div>
                                  occurrences
                                </span>
                              ) : (
                                <span>
                                  {values.end_type === 'invoice_end_date'
                                    ? moment(values.end_date).format('MM/DD/YYYY')
                                    : END_TYPE_DISPLAY_TEXT[values.end_type]}
                                </span>
                              )}
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            </div>
                            {Boolean(editEndType) && (
                              <div className="send-immeediatly-calendar react-datepicker-popper">
                                {Object.keys(END_TYPE_DISPLAY_TEXT).map((endType) => (
                                  <div
                                    key={endType}
                                    className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                    onClick={updateEndType.bind(this, setFieldValue, endType)}>
                                    {END_TYPE_DISPLAY_TEXT[endType]}
                                  </div>
                                ))}
                              </div>
                            )}
                            {Boolean(editEndDate) && (
                              <div className="send-immeediatly-calendar react-datepicker-popper hide-outside-dates ">
                                <DatePicker
                                  inline
                                  id="sendInvoice_DatePicker"
                                  selected={values.end_date ? new Date(moment(values.end_date).format('L')) : null}
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control border-0 p-0 datepicker"
                                  name="end_date"
                                  placeholderText={values.end_date}
                                  minDate={new Date(moment().add(1, 'days').format('L'))}
                                  onChange={(event) => {
                                    setFieldValue('end_date', moment(event).format('MM/DD/yyyy'))
                                    setFieldValue('end_type', 'invoice_end_date')
                                    setEditOccurrences(false)
                                    setEditEndDate(false)
                                    isFormChanged = true
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="row-send-via border-bottom d-flex align-items-center">
                        <div className="send-via px-15">
                          {t('payments.send-via')} {values.send_email}
                        </div>
                        <div className="send-via-value position-relative">
                          <div
                            className="send-via-value-item px-15 cursor-pointer d-flex align-items-center justify-content-between "
                            onClick={() => {
                              setEditSendType(!editSendType)
                              setEditSendDate(false)
                            }}>
                            {Boolean(values && values.send_email && values.send_sms) && (
                              <span>{t('payments.email-and-sms')}</span>
                            )}
                            {Boolean(values && values.send_email && !values.send_sms) && (
                              <span>{t('payments.email')}</span>
                            )}
                            {Boolean(values && !values.send_email && values.send_sms) && <span>SMS</span>}
                            {Boolean(values && !values.send_email && !values.send_sms) && (
                              <span>{t('payments.select')}</span>
                            )}
                            <div className={`${style['arrow-icon']}`}>
                              <Icon name="arrowDownThin" />
                            </div>
                          </div>
                          {editSendType && (
                            <div className="dropdown-send-via-menu position-absolute right-0  bg-white">
                              <div className="dropdown-send-via-menu-item border-bottom px-15 py-10">
                                <div className="form-group form-check d-flex m-0">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      className="h-cb"
                                      disabled={!values.customer_phone || !twilioEnabled}
                                      checked={values.send_sms}
                                      onChange={(event) => {
                                        setFieldValue('send_sms', !values.send_sms)
                                        isFormChanged = true
                                      }}
                                    />
                                    <span id="frm_addService_chkBookingHours" className="checkbox-icon mr-10" />
                                    SMS
                                  </label>
                                </div>
                              </div>
                              <div className="dropdown-send-via-menu-item px-15 py-10">
                                <div className="form-group form-check d-flex m-0">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      className="h-cb"
                                      disabled={!values.customer_email}
                                      checked={values.send_email}
                                      onChange={(event) => {
                                        setFieldValue('send_email', !values.send_email)
                                        isFormChanged = true
                                      }}
                                    />
                                    <span id="frm_addService_chkBookingHours" className="checkbox-icon mr-10" />
                                    {t('payments.email')}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row-due-date border-bottom d-flex align-items-center">
                        <div className="due-date px-15">{t('payments.due-date')}</div>
                        <div className="due-date-value">
                          <div
                            className="px-15 due-date-value-date d-flex align-items-center justify-content-between"
                            onClick={() => {
                              setEditDueDate(!editDueDate)
                            }}>
                            {values.invoice_due
                              ? INVOICE_DUE_DATE_VALUES.includes(values.invoice_due)
                                ? _.capitalize(values.invoice_due) +
                                  (values.invoice_due.includes('day') || values.invoice_due.includes('week')
                                    ? t('payments.after-receipt')
                                    : '')
                                : moment(values.invoice_due).format('MM/DD/YYYY')
                              : t('payments.upon-receipt-0')}
                            <div className="arrow-icon">
                              <Icon name="arrowDownThin" />
                            </div>
                          </div>
                          {Boolean(editDueDate) &&
                            (popupType !== POPUP_TYPES.EDIT_INVOICE ? (
                              <div className="send-immeediatly-calendar react-datepicker-popper">
                                {INVOICE_DUE_DATE_VALUES.map((type) => (
                                  <div
                                    key={type}
                                    className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                    onClick={updateDueDate.bind(this, setFieldValue, type)}>
                                    {_.capitalize(type)}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="due-date-calendar react-datepicker-popper hide-outside-dates d-sm-flex pt-10 pl-10 pr-15 pb-0">
                                <DatePicker
                                  inline
                                  id="sendInvoice_DatePicker"
                                  selected={
                                    values.invoice_due && !INVOICE_DUE_DATE_VALUES.includes(values.invoice_due)
                                      ? new Date(moment(values.invoice_due).format('L'))
                                      : null
                                  }
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control border-0 p-0 datepicker"
                                  name="invoice_due"
                                  placeholderText={t('payments.select-due-date')}
                                  minDate={
                                    values.scheduled_date
                                      ? new Date(moment(values.scheduled_date).format('L'))
                                      : new Date()
                                  }
                                  onChange={(event) => {
                                    setFieldValue('invoice_due', moment(event).format('MM/DD/yyyy'))
                                    setEditDueDate(false)
                                    isFormChanged = true
                                  }}
                                />
                                <div className="calendar-button-block pl-15 pt-5">
                                  {INVOICE_DUE_DATE_VALUES.map((type) => (
                                    <button
                                      key={type}
                                      onClick={updateDueDate.bind(this, setFieldValue, type)}
                                      className="btn btn-sm btn-outline-primary border text-nowrap mb-15 w-100">
                                      {_.capitalize(type)}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                    <label className="label-text">
                      {t('payments.invoice-items')}
                      <ErrorMessage name="items" component="span" className="form-error" />
                    </label>
                    <table className="table table-invoice rounded-sm">
                      <thead>
                        <tr>
                          <th>{t('payments.item')}</th>
                          <th className="text-right qty">QTY</th>
                          <th className="text-right rate">{t('payments.rate')}</th>
                          <th className="text-right amount">{t('payments.amount')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.invoice_items.length > 0
                          ? values.invoice_items.map((item, index) => {
                              const IS_DISABLED_ENTRY = popupType === POPUP_TYPES.EDIT_SERIES
                              return (
                                <tr key={item.created_timestamp} id={index}>
                                  <td className="td-item-label td-item-label-name position-relative">
                                    <div className="d-flex justify-content-between">
                                      <div className="flex-grow-1 pr-15">
                                        <div data-test="select-invoice-service-item" className="item-name">
                                          <AsyncCreatableSelect
                                            id="sendInvoiceService"
                                            name="service"
                                            isClearable
                                            placeholder={t('payments.enter-item')}
                                            className="dropdown-select no-arrow-down"
                                            classNamePrefix="dropdownSelect"
                                            isDisabled={IS_DISABLED_ENTRY}
                                            defaultOptions
                                            cacheOptions
                                            value={
                                              item && item.service_name
                                                ? {
                                                    label: item.service_name,
                                                    value: _.get(item, 'service_id', '')
                                                  }
                                                : []
                                            }
                                            loadOptions={autoSuggestServicesCaller}
                                            formatOptionLabel={customOptionsService}
                                            noOptionsMessage={() => null}
                                            onChange={(event, type) =>
                                              handleSelectOptionService(
                                                values,
                                                setFieldValue,
                                                event,
                                                'service',
                                                type,
                                                index
                                              )
                                            }
                                            formatCreateLabel={(userInput) => (
                                              <div className="new-custom-item">
                                                <span className="">{t('payments.new-custom-item')}</span> &quot;
                                                {userInput}&quot;
                                              </div>
                                            )}
                                          />
                                        </div>
                                        {Boolean(
                                          (editDescription && currentItemIndex === index) || item.service_description
                                        ) && (
                                          <div className="item-description">
                                            <div
                                              className="item-description-text d-none"
                                              onClick={(event) => {
                                                if (!IS_DISABLED_ENTRY) {
                                                  event.target.classList.add('d-none')
                                                  event.target.classList.remove('d-block')
                                                  event.target
                                                    .closest('.item-description')
                                                    .querySelector('input')
                                                    .classList.add('d-block')
                                                  event.target
                                                    .closest('.item-description')
                                                    .querySelector('input')
                                                    .classList.remove('d-none')
                                                  event.target
                                                    .closest('.item-description')
                                                    .querySelector('input')
                                                    .focus()
                                                }
                                              }}>
                                              {_.get(item, 'service_description', t('payments.enter-description'))}
                                            </div>
                                            <Field
                                              type="text"
                                              name="description"
                                              id={'serviceDescription_' + index}
                                              className="form-control d-block"
                                              placeholder={t('payments.enter-description')}
                                              disabled={IS_DISABLED_ENTRY}
                                              value={_.get(item, 'service_description', '')}
                                              autoComplete="off"
                                              onChange={(event) => {
                                                setFieldValue(
                                                  'invoice_items',
                                                  values.invoice_items.map((item, currentIndex) =>
                                                    currentIndex !== index
                                                      ? item
                                                      : {
                                                          ...item,
                                                          service_description: event.target.value
                                                        }
                                                  )
                                                )
                                                isFormChanged = true
                                              }}
                                              onBlur={(event) => {
                                                event.target.classList.add('d-none')
                                                event.target.classList.remove('d-block')
                                                event.target
                                                  .closest('.item-description')
                                                  .querySelector('.item-description-text')
                                                  .classList.add('d-block')
                                                event.target
                                                  .closest('.item-description')
                                                  .querySelector('.item-description-text')
                                                  .classList.remove('d-none')
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      {Boolean(
                                        !item.service_description &&
                                          item.service_name &&
                                          popupType !== POPUP_TYPES.EDIT_SERIES
                                      ) && (
                                        <div className="add-note">
                                          <Tooltip position="top" message={t('payments.add-service-note')}>
                                            <Icon
                                              onClick={editDescriptionBtnClick.bind(this, item, index)}
                                              name="note"
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className="td-item-label td-item-label-qty text-right light-gray">
                                    <Field
                                      type="text"
                                      name="quantity"
                                      placeholder="--"
                                      className="form-control form-control-lg"
                                      value={_.get(item, 'service_quantity', '')}
                                      disabled={IS_DISABLED_ENTRY}
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const regex = /^[0-9]*$/
                                        if (regex.test(value.toString()) || value.length === 0) {
                                          setFieldValue(
                                            'invoice_items',
                                            values.invoice_items.map((item, currentIndex) =>
                                              currentIndex !== index
                                                ? item
                                                : {
                                                    ...item,
                                                    service_quantity: value,
                                                    service_price_total: value * item.service_price
                                                  }
                                            )
                                          )
                                          isFormChanged = true
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="td-item-label td-item-label-rate text-right light-gray">
                                    <Field
                                      type="text"
                                      name="rate"
                                      placeholder="--"
                                      className="form-control form-control-lg"
                                      value={_.get(item, 'service_price', '')}
                                      disabled={
                                        IS_DISABLED_ENTRY ||
                                        (popupType === POPUP_TYPES.CREATE && _.get(item, 'tempGoStop'))
                                      }
                                      onChange={(event) => {
                                        const { value } = event.target
                                        const regex = /^(0*[1-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                        if (regex.test(value.toString()) || value.length === 0) {
                                          setFieldValue(
                                            'invoice_items',
                                            values.invoice_items.map((item, currentIndex) =>
                                              currentIndex !== index
                                                ? item
                                                : {
                                                    ...item,
                                                    service_price: value,
                                                    service_price_total:
                                                      value.toString() !== '.' ? value * item.service_quantity : 0
                                                  }
                                            )
                                          )
                                          isFormChanged = true
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="text-right td-item-label-total">
                                    <div className="position-relative">
                                      {USDOLLAR.format(item.service_price_total)}
                                      <div className="position-absolute remove-item">
                                        {Boolean(values.invoice_items.length > 1 && !IS_DISABLED_ENTRY) && (
                                          <i
                                            onClick={(event) => {
                                              onRemoveItem(setFieldValue, values, index)
                                            }}
                                            className="mx-10 top-minus-2">
                                            <Icon name="removeMinus" />
                                          </i>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          : null}
                      </tbody>
                      <tbody>
                        {Boolean(!fromBooking && popupType !== POPUP_TYPES.EDIT_SERIES) && (
                          <tr>
                            <td colSpan="4" className="td-add-item border-top">
                              <div
                                data-test="add-invoice-item"
                                className="text-primary cursor-pointer"
                                onClick={(event) => {
                                  if (popupType !== POPUP_TYPES.EDIT_SERIES) {
                                    onAddItem(setFieldValue, values)
                                  }
                                }}>
                                {t('payments.add-item')}
                              </div>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="3" className="primary-font-bold td-total-calculation border-top">
                            {t('payments.total')}
                          </td>
                          <td className="text-right primary-font-bold td-total-calculation border-top">
                            {USDOLLAR.format(calculateTotal(values))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {(hasMid || !paymentStatusListForDeclinedAndSubmited.includes(paymentStatus)) && (
                  <div className="col-12">
                    <div
                      data-test="accept-payments-checkbox-title"
                      className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                      <label className="label-text" style={{ color: '#082344' }}>
                        PAYMENTS
                      </label>
                      {!paymentAllowed && <p>{t('payments.activate-payments-to-get-paid-directly-from-invoices')}</p>}
                      <div
                        data-test="accept-payments-checkbox"
                        className={!paymentAllowed ? 'payments-disabled' : null}>
                        <div className="payments border rounded-sm d-flex py-10">
                          <div
                            className="payments-text px-15 primary-font-bold"
                            style={{ color: '#082344', fontSize: '.875rem' }}>
                            {t('payments.credit-card')}
                          </div>
                          <div className="payments-check-box form-group form-check px-15 m-0 ml-auto">
                            <label>
                              <Field
                                type="checkbox"
                                className="h-cb"
                                disabled={popupType === POPUP_TYPES.EDIT_SERIES}
                                checked={values.collect_payments}
                                onChange={async (event) => {
                                  setFieldValue('collect_payments', !values.collect_payments)
                                  isFormChanged = true
                                }}
                              />
                              <span id="frm_addService_chkBookingHours" className="checkbox-icon" />
                            </label>
                          </div>
                        </div>
                      </div>

                      {payment_type === 'stripe' ? (
                        <div
                          data-test="accept-payments-checkbox"
                          className={!paymentAllowed ? 'payments-disabled' : null}>
                          <div className="payments border rounded-sm d-flex py-10">
                            <div
                              className="payments-text px-15 primary-font-bold"
                              style={{ color: '#082344', fontSize: '.875rem' }}>
                              {t('payments.bank-transfer')}
                            </div>
                            <div className="payments-check-box form-group form-check px-15 m-0 ml-auto">
                              <label>
                                <Field
                                  type="checkbox"
                                  className="h-cb"
                                  disabled={popupType === POPUP_TYPES.EDIT_SERIES}
                                  checked={values.bank_transfer}
                                  onChange={async (event) => {
                                    setFieldValue('bank_transfer', !values.bank_transfer)
                                    isFormChanged = true
                                  }}
                                />
                                <span id="frm_addService_chkBookingHours" className="checkbox-icon" />
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              <footer className="d-block d-flex justify-content-around">
                <button
                  className="btn btn-primary border-0 btn-xl flex-fill mr-10"
                  data-test="send-invoice-form-btn"
                  id="frmBtnSendInvoice"
                  disabled={paymentLinkLoader}
                  type="submit">
                  {' '}
                  {POPUP_TYPE_SETTINGS[popupType].buttonText}
                  {paymentLinkLoader && (
                    <i className="btn-icon top-minus-2 mr-5 btn-loader">
                      <Icon name="spinner" />
                    </i>
                  )}
                </button>
                <button type="reset" id="frmBtnSendInvoice_btnCancel" className="btn ml-10 btn-xl cancel-btn flex-fill">
                  CANCEL
                </button>
              </footer>
            </Form>
          )
        }}
      />
    </Popup>
  )
}

SendRecurringInvoicePopup.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  invoiceItem: PropTypes.object,
  autosuggestContacts: PropTypes.func,
  products: PropTypes.object,
  contactList: PropTypes.array,
  me: PropTypes.object,
  savePrivateContact: PropTypes.func,
  privateContact: PropTypes.object,
  openInvoiceModal: PropTypes.func,
  autoSuggestServices: PropTypes.func,
  servicesList: PropTypes.array,
  fromBooking: PropTypes.bool,
  hasMid: PropTypes.bool,
  paymentStatus: PropTypes.string,
  paymentLinkLoader: PropTypes.bool,
  confirmInvoice: PropTypes.func,
  popupType: PropTypes.string
}

export default SendRecurringInvoicePopup
