import Cookies from 'js-cookie'
import { capitalize, objToUrlParams } from './index'
import moment from 'moment'
import { momentFromNowShort } from './moment'
import queryString from 'qs'
import { businessDays, days, classMapping, sources, DAY_WITH_VALUE, GOOGLE_STATUS } from '@config/constants'
import { PAYMENT_STATUS } from '@containers/payment/constants'
import axios from 'axios'
import ipapi from 'ipapi.co'
import config from '@config/index'
import md5 from 'md5'
import { featureFlags, getFeatureVal } from './configcat'
import { history } from '@app/store'
import _ from 'lodash'
import {
  USER_ROLES,
  ENTERPRISE_ROLES,
  ROLES_SEGMENT_TRACK,
  REFERRAL_APP_SOURCE_PROVIDER_MAP,
  REFERRAL_APP_SOURCE_COOKIE_KEY,
  INTEGRATION_REFFERAL_APPS,
  ID_TOKEN,
  ID_TOKEN_ADMIN,
  FEDERATED_LOGIN_PROVIDERS,
  COOKIES_KEYS
} from './constants'
import { logout } from '@containers/auth/service'
import JSEncrypt from 'jsencrypt'
import i18next from 'i18next'

const { STAFF, READ_ONLY, CUSTOMER, TRIAL, PROSPECT } = USER_ROLES
const { PARTNER, MANAGER } = ENTERPRISE_ROLES
const cookies = {
  name: 'gosite',
  expiry: 1,
  domain: ''
}

export function setActiveTotalAccounts(active_accounts, total_accounts) {
  const { user_unique_id } = getCookie()
  const cookie = Cookies.getJSON(user_unique_id)
  cookie.active_accounts = active_accounts
  cookie.total_accounts = total_accounts

  /** Check totaL accounts condition */
  let isSMBPartner = false
  let isMultiLocationPartner = false
  if (cookie.role === 'partner' && cookie.isMultiLocation) {
    if (total_accounts <= 1) {
      isSMBPartner = true
      isMultiLocationPartner = false
    } else {
      isSMBPartner = false
      isMultiLocationPartner = true
    }
  } else {
    isSMBPartner = false
    isMultiLocationPartner = false
  }

  cookie.isSMBPartner = isSMBPartner
  cookie.isMultiLocationPartner = isMultiLocationPartner

  Cookies.set(user_unique_id, cookie, { expires: cookies.expiry, domain: cookies.domain })
  return {
    uuid: user_unique_id
  }
}

export function updateAllCookies(uuid, cookie) {
  Cookies.set(uuid, cookie, { expires: cookies.expiry, domain: cookies.domain })
}

export function getAllCookies(uuid) {
  const cookie = Cookies.getJSON(uuid)
  return cookie
}

export async function clearCookies(key, isEnterpriseUser = false) {
  const cName = key || cookieName()
  if (isCognitoUser()) {
    await logout()
    deleteAllCookies()
    if (!isEnterpriseUser) {
      window.open(`${config.cognitoUrl}?logout=true`, '_parent')
    }
  }
  Cookies.remove(cName, { domain: cookies.domain })
  Cookies.remove(ID_TOKEN, { domain: cookies.domain })
}
export function updateCookies(uuid, userEmail) {
  try {
    const cookie = Cookies.getJSON(uuid)
    cookie.user_email = userEmail
    console.log('setCookies', cookie)
    Cookies.set(uuid, cookie, { expires: cookies.expiry, domain: cookies.domain })
  } catch (e) {
    console.log(e)
  }
}
export function setCookies(data, isAdmin = false) {
  try {
    const cookie = data
    console.log('data Cookie =============', data)

    let isSMBPartner = false
    let isMultiLocationPartner = false
    if (cookie.role === 'partner' && cookie.isMultiLocation) {
      if (cookie.total_accounts <= 1) {
        isSMBPartner = true
        isMultiLocationPartner = false
      } else {
        isSMBPartner = false
        isMultiLocationPartner = true
      }
    } else {
      isSMBPartner = false
      isMultiLocationPartner = false
    }

    /** [EVE-682] */
    let in4Hours
    if (cookie.hideNav) {
      in4Hours = new Date(new Date().getTime() + 4 * 60 * 60 * 1000)
      Cookies.set('hideNav', true, {
        expires: in4Hours,
        domain: cookies.domain
      })
    } else {
      deleteASingleCookies('hideNav')
    }

    if (cookie.hideSocial) {
      Cookies.set('hideSocial', true, {
        expires: in4Hours,
        domain: cookies.domain
      })
    } else {
      deleteASingleCookies('hideSocial')
    }

    Cookies.set(
      cookie.user_unique_id,
      {
        status: cookie.status,
        role: cookie.role,
        user_unique_id: cookie.user_unique_id,
        isMultiLocation: cookie.isMultiLocation,
        active_accounts: cookie.active_accounts,
        total_accounts: cookie.total_accounts,
        isSMBPartner: isSMBPartner,
        isMultiLocationPartner: isMultiLocationPartner,
        businessProfile: cookie.businessProfile,
        site: cookie.site,
        whiteListPermission: cookie.whiteListPermission,
        multilocation: cookie.multilocation,
        customTheme: cookie.customTheme,
        isAwsCognitoUser: cookie.isAwsCognitoUser,
        cogAccessToken: cookie.cogAccessToken,
        isCognito: cookie.isCognito
      },
      { expires: cookies.expiry, domain: cookies.domain }
    )
    if (cookie.tt_token) {
      Cookies.set(COOKIES_KEYS.THUMBTACK_TOKEN, cookie.tt_token, { expires: cookies.expiry, domain: cookies.domain })
    }
    if (cookie.admin_origin_req) {
      Cookies.set(COOKIES_KEYS.ADMIN_ORIGIN_REQ, cookie.admin_origin_req, {
        expires: cookies.expiry,
        domain: cookies.domain
      })
      Cookies.set(COOKIES_KEYS.ADMIN_UUID, cookie.admin_uuid, { expires: cookies.expiry, domain: cookies.domain })
    }
    if (isAdmin) {
      Cookies.set(ID_TOKEN_ADMIN, cookie.token, { expires: cookies.expiry, domain: cookies.domain })
    } else {
      Cookies.set(ID_TOKEN, cookie.token, { expires: cookies.expiry, domain: cookies.domain })
    }
  } catch (e) {
    console.log(e)
  }
}

export const setCookieParentDomain = (name, value) => {
  if (value) {
    Cookies.set(name, value, { domain: config.cookies.parentDomain })
  }
}

export function setCookiesV2(name, value) {
  if (value) {
    Cookies.set(name, value, { domain: cookies.domain })
  }
}

export const decodeCookies = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}
function cookieName() {
  const uuid = window.location.href.split('/')[3]
  const reg = /^100/
  if (reg.test(uuid)) {
    return uuid
  } else {
    const allCookie = Cookies.get()
    for (const key in allCookie) {
      if (reg.test(key)) {
        return key
      }
    }
  }
}
export function getThumbtackToken() {
  return Cookies.get('tt_token')
}
export function getJsonCookies() {
  const data1 = Cookies.getJSON(cookieName())
  const data2 = getCookie()
  if (data1) {
    return data1.token || getIdToken()
  } else if (data2) {
    return data2.token
  } else {
    return undefined
  }
}

export function getCookie() {
  // var uuid = window.location.pathname.split('/')[1]
  // if(!uuid){
  //   uuid = cookieName()
  // }
  // let token =  Cookies.getJSON(uuid);
  // if (token){
  //     console.log("---------token-------", token)
  //     return token;
  // }else{
  const params = queryString.parse(window.location.search)
  // for temporary fix
  // if(params.reviewId || params['?reviewId'] || params.feedbackId || params['?feedbackId']  ){
  //   let str = params.reviewId || params['?reviewId'] || params.feedbackId || params['?feedbackId'];
  //   let token = str.split('?')[1];
  //   var t = token.split("t=")[1]
  //   // console.log("token",t)
  // }
  if ((params.t || params['?t']) && !params['?rs'] && !params['?uid']) {
    const data = {
      token: params.t || params['?t'],
      status: params.s,
      role: params.r,
      user_unique_id: params.uuid,
      site: params.site ? params.site.replace(/^http:\/\//i, 'https://') : '',
      isAwsCognitoUser: !!params.isAwsCognitoUser,
      cogAccessToken: params.cogtoken ? params.cogtoken : null,
      loginProvider: params.PROVIDER_NAME || '',
      isCognito: params.isCognito,
      active_accounts: params.active_accounts || ''
    }
    if (params.wc) {
      data.canCreateWebsite = true
    }
    if (params.showBusinessModal || params['?showBusinessModal']) {
      data.showBusinessModal = params.showBusinessModal || params['?showBusinessModal']
    }
    if (params.multilocation) {
      data.multilocation = true
    }
    if (params.tt_token) {
      data.tt_token = params.tt_token
    }
    if (params.admin_origin_req) {
      data.admin_origin_req = true
      data.admin_uuid = params.admin_uuid
    }
    if (params.isMultiLocation && params.r === 'partner') {
      data.isMultiLocation = true

      if (params.total_accounts) {
        data.total_accounts = parseInt(params.total_accounts)
      }

      if (params.active_accounts) {
        data.active_accounts = parseInt(params.active_accounts)
      }

      let isSMBPartner = false
      let isMultiLocationPartner = false
      if (data.total_accounts <= 1) {
        isSMBPartner = true
        isMultiLocationPartner = false
      } else {
        isSMBPartner = false
        isMultiLocationPartner = true
      }

      data.isSMBPartner = isSMBPartner
      data.isMultiLocationPartner = isMultiLocationPartner
    }

    /** [EVE-682] checking for hideNav param to hide nav bars */
    if (params.hideNav === 'true') {
      data.hideNav = true
    }

    if (params.hideSocial === 'true') {
      data.hideSocial = true
    }

    if (params.businessProfile === 'true') {
      data.businessProfile = true
    }

    let search = ''
    if (params['?feedbackId']) {
      search = '?feedbackId=' + params['?feedbackId']
    } else if (params['?reviewId']) {
      search = '?reviewId=' + params['?reviewId']
    } else if (params['?threadId']) {
      search = '?threadId=' + params['?threadId']
    } else if (params['?redirect']) {
      search = '?redirect=' + params['?redirect']
    }

    setCookies(data)
    if (!params.at) {
      // remove access token from url
      if (data.showBusinessModal) {
        window.history.pushState({}, '', window.location.pathname + '?showBusinessModal=' + data.showBusinessModal)
      } else {
        window.history.pushState({}, '', window.location.pathname + search)
      }
    }

    return data
  } else {
    let uuid = window.location.pathname.split('/')[1]
    const reg = /^\d+$/
    if (!uuid || !reg.test(uuid)) {
      uuid = cookieName()
    }
    const allCookie = Cookies.get()
    const lastLoggedInUuid = Cookies.get('lastLoggedInUuid')
    if (lastLoggedInUuid) {
      uuid = lastLoggedInUuid
    }
    if (!Object.keys(allCookie).includes(uuid)) {
      const isMatch = Object.keys(allCookie).find((rx) => reg.test(rx))
      if (isMatch) {
        uuid = isMatch
      }
    }
    const userCookie = Cookies.getJSON(uuid)
    if (userCookie) {
      /** [EVE-682] checking for hideNav param to hide nav bars */
      if (Object.keys(allCookie).includes('hideNav')) {
        if (Cookies.getJSON('hideNav') === true) {
          userCookie.hideNav = true
        }
      }
      if (Object.keys(allCookie).includes('hideSocial')) {
        if (Cookies.getJSON('hideSocial') === true) {
          userCookie.hideSocial = true
        }
      }
      userCookie.token = getIdToken()
      return userCookie

      // if(all) {
      //   const token = Cookies.getJSON(uuid)
      //   console.log("token ======", token)
      //   if (token) {
      //     return token
      //   }
      // } else {
      //   const userCookie = Cookies.getJSON(uuid)
      //   console.log("getIdToken() =================", getIdToken())
      //   userCookie.token = getIdToken()
      //   if (userCookie) {
      //     return userCookie
      //   }
    }
  }
}

export function deleteASingleCookies(id) {
  Cookies.remove(id, { domain: cookies.domain })
}

export function deleteAllCookies() {
  const cName = cookieName()
  Cookies.remove(cName, { domain: cookies.domain })
  Cookies.remove(ID_TOKEN, { domain: cookies.domain })
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName, { path: '/', domain: config.domainName })
  })
  // can be removed once tested above code
  // const all = Cookies.get()
  // console.log(all)
  // var cookies = document.cookie.split(';')
  // for (var i = 0; i < cookies.length; i++) {
  //   var cookie = cookies[i]
  //   var eqPos = cookie.indexOf('=')
  //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
  //   document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  // }
}

export function getAdminOrPartnerCookie() {
  const params = queryString.parse(window.location.search)
  console.log('params ==getAdminOrPartnerCookie=====', params)
  if (params.at) {
    const adminData = {
      token: params.at,
      status: params.as,
      role: params.ar,
      user_unique_id: params.auuid,
      isCognito: params.isCognito
    }
    if (params.awhitelist) {
      adminData.whiteListPermission = true
    } else {
      adminData.whiteListPermission = false
    }
    if (params.enterprise_group) {
      adminData.enterprise_group = params.enterprise_group
    }
    if (params.isMultiLocation) {
      adminData.isMultiLocation = true
    }
    if (params.customTheme) {
      adminData.customTheme = 1
    }
    setCookies(adminData, true)
    window.history.pushState({}, '', window.location.pathname)
    return [adminData]
  } else {
    const cookiesArray = Cookies.get()
    const adminCookies = []

    Object.keys(cookiesArray).forEach((keyName, i) => {
      if (keyName && isJSON(cookiesArray[keyName])) {
        const obj = JSON.parse(cookiesArray[keyName])
        if (obj.role && [CUSTOMER, TRIAL, READ_ONLY, STAFF, PROSPECT].indexOf(obj.role) === -1) {
          obj.token = getAdminIdToken()
          adminCookies.push(obj)
        }
      }
    })
    return adminCookies
  }
}

export function getAllQueryParam(url = window.location.search) {
  const queryParams = queryString.parse(url)
  return queryParams
}

export function getAdminCookie() {
  const params = queryString.parse(window.location.search)
  console.log('JSON params ============', JSON.stringify(params))
  if (params.at) {
    const adminData = {
      token: params.at,
      status: params.as,
      role: params.ar,
      user_unique_id: params.auuid,
      isCognito: params.isCognito
    }
    if (params.awhitelist) {
      adminData.whiteListPermission = true
    } else {
      adminData.whiteListPermission = false
    }
    if (params.customTheme) {
      adminData.customTheme = 1
    }
    if (params.isMultiLocation) {
      adminData.isMultiLocation = true
    }
    setCookies(adminData, true)
    window.history.pushState({}, '', window.location.pathname)
    return [adminData]
  } else {
    const cookiesArray = Cookies.get()
    const adminCookies = []
    Object.keys(cookiesArray).forEach((keyName, i) => {
      if (keyName && isJSON(cookiesArray[keyName])) {
        const obj = JSON.parse(cookiesArray[keyName])
        if (obj.role && [CUSTOMER, TRIAL, READ_ONLY, STAFF, PROSPECT, PARTNER, MANAGER].indexOf(obj.role) === -1) {
          obj.token = getAdminIdToken()
          adminCookies.push(obj)
        }
      }
    })
    return adminCookies
  }
}
export function isFromAdmin() {
  const cookiesArray = Cookies.get()
  const adminCookies = []
  Object.keys(cookiesArray).forEach((keyName, i) => {
    if (keyName && isJSON(cookiesArray[keyName])) {
      const obj = JSON.parse(cookiesArray[keyName])
      if (obj.role && [CUSTOMER, TRIAL, READ_ONLY, STAFF, PROSPECT, PARTNER, MANAGER].indexOf(obj.role) === -1) {
        adminCookies.push(obj)
      }
    }
  })
  return !!(adminCookies && adminCookies.length)
}
export function removeAdminCookie() {
  const cookiesArray = Cookies.get()
  Object.keys(cookiesArray).forEach((keyName, i) => {
    if (keyName && isJSON(cookiesArray[keyName])) {
      const obj = JSON.parse(cookiesArray[keyName])
      if (obj.role && [CUSTOMER, TRIAL, READ_ONLY, STAFF].indexOf(obj.role) === -1) {
        clearCookies(obj.user_unique_id)
      }
    }
  })
  return true
}

function isJSON(str) {
  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}
export function mapSourceName(item) {
  let sourceName = ''
  sources.forEach(function (arrayItem) {
    if (arrayItem.val === item) {
      sourceName = arrayItem.name
    }
  })
  return sourceName
}

export function mapIconClass(item) {
  const iconClassArray = [
    { chat: 'messenger' },
    { 'contact-us': 'email' },
    { appointment: 'calender' },
    { lead_form: 'calender' },
    { direct: 'goIcon-icon-user-new' },
    { subscriber: 'goIcon-subscriptiom' },
    { google: 'goIcon-gplus' },
    { outlook: 'goIcon-outlook' },
    { review: 'reviews' },
    { quickbooks: 'goIcon-logo-quickbooks2' },
    { campaign: 'goIcon-campaign2' },
    { transaction: 'goIcon-transactions-ico' },
    { send: 'goIcon-tick' },
    { new: 'goIcon-processing-icon' },
    { failed: 'close' },
    { scheduled: 'statusSheduled' }
  ]
  let className = ''
  iconClassArray.forEach(function (arrayItem) {
    if (arrayItem[item]) {
      className = arrayItem[item]
    }
  })
  return className
}
export function mapIconIcon(item) {
  const iconNameArray = [
    { send: 'statusTick' },
    { new: 'processing' },
    { failed: 'statusClose' },
    { scheduled: 'statusSheduled' }
  ]
  let IconName = ''
  iconNameArray.forEach(function (arrayItem) {
    if (arrayItem[item]) {
      IconName = arrayItem[item]
    }
  })
  return IconName
}
export function mapSourceText(item) {
  return capitalize(item)
}
export function phoneNumberFormat(item) {
  const value1 = String(item)
  let value = value1.replace(/\D/g, '')
  if (value.length === 11) {
    value = value.substr(1)
  }
  const number = value.replace(/[^0-9]+/g, '')
  const cleaned = ('' + number).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}
export function filterObjectArrayWithAnotherArray(array1, array2, key1, key2) {
  const newArray = array1.filter((el) => {
    return array2.some((f) => {
      return f[key1] === el[key2]
    })
  })
  return newArray
}
export function findWithAttr(array, attr, value, convertToLower) {
  for (let i = 0; i < array.length; i += 1) {
    if (convertToLower) {
      if (array[i][attr].toLowerCase().includes(value)) {
        return i
      }
    } else {
      if (array[i][attr] === value) {
        return i
      }
    }
  }
}
export function getAMPM(input) {
  const timeString = input
  const H = +timeString.substr(0, 2)
  const h = H % 12 || 12
  const ampm = H < 12 ? 'AM' : 'PM'
  const timeStart = h + timeString.substr(2, 3) + ' ' + ampm
  return timeStart
}
export function getTimeFormatted(input) {
  const timeString = input
  const H = +timeString.substr(0, 2)
  let h = H % 12 || 12
  h = h < 10 ? `0${h}` : h
  const ampm = H < 12 ? 'AM' : 'PM'
  const timeStart = h + timeString.substr(2, 3) + ' ' + ampm
  return timeStart
}
export function getHours(input) {
  const [hours, minutes] = input.split(':')
  const h = parseInt(hours) <= 12 ? hours : hours - 12
  const newHours = (h < 10 ? '0' : '') + h + ':' + minutes
  return newHours
}

export function checkPermission(product, permission, user, products) {
  if (user && user.permission_names) {
    if (products.indexOf(product) >= 0 && user.permission_names.indexOf(permission) >= 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export function checkPermissionWOproduct(permission, user) {
  if (user && user.permission_names.indexOf(permission) >= 0) {
    return true
  } else {
    return false
  }
}
export function isEnabled(module, products) {
  if (products && products.indexOf(module) > -1) {
    return true
  } else {
    return false
  }
}
export function isDisabled(module, products) {
  if (products && products.indexOf(module) > -1) {
    return true
  } else {
    return false
  }
}
export function generateImageUrl(url, type) {
  const arr = url.split('/')
  let newUrl = ''
  for (let i = 0; i < arr.length; i++) {
    newUrl += arr[i]
    if (i === arr.length - 2) {
      newUrl += '/' + type
    }
    if (i < arr.length - 1) {
      newUrl += '/'
    }
  }
  return newUrl
}
export function convertHoursToString(hours) {
  let str = '' // breakLoop = false;
  Object.keys(hours).forEach(function (day) {
    const dayValue = days[findWithAttr(days, 'label', day.toUpperCase())].value
    if (!hours[day].open) {
      str += dayValue + ':CLOSED,'
    } else {
      let ts, te, startTime, endTime
      hours[day].slots.forEach(function (slot) {
        if (slot.time_start1) {
          ts = slot.time_start1 + ' ' + slot.time_start2
        } else {
          ts = 'AM'
        }
        if (slot.time_end1) {
          te = slot.time_end1 + ' ' + slot.time_end2
        } else {
          te = 'PM'
        }
        if (
          !hours[day].open_allday &&
          hours[day].open &&
          (ts === 'AM' || te === 'PM' || slot.time_start1 === '00:00' || slot.time_end1 === '00:00')
        ) {
          // var message = "Invalid time slots";
          // $rootScope.addMessage(message, "error");
          // breakLoop = true;
        }
        if (ts !== 'AM' && ts !== 'PM') {
          startTime = convertTimeformats(ts)
          endTime = convertTimeformats(te)
          str += dayValue + ':' + startTime + ':' + endTime + ','
        }
      })
    }
  })
  str = str.slice(0, -1)
  str = str.replace(/^,/, '')
  console.log(str.replace(/^,/, ''))
  return str
}

export function convertHoursToArray(hours) {
  const businessHours = JSON.parse(JSON.stringify(businessDays))
  if (hours && hours.length) {
    const arr1 = hours.split(',')
    arr1.forEach(function (elem) {
      if (elem) {
        const arr2 = elem.split(':')
        let hrs, mins, hrs1, mins1, ts1, te1
        const day = parseInt(arr2[0])
        const dayLabel = days[findWithAttr(days, 'value', day)].label.toLowerCase()
        if (arr2.length === 5) {
          hrs = parseInt(arr2[1])
          mins = parseInt(arr2[2])
          hrs1 = parseInt(arr2[3])
          mins1 = parseInt(arr2[4])

          if (hrs === 0 && mins === 0 && hrs1 === 0 && mins1 === 0) {
            businessHours[dayLabel].open_allday = true
            ts1 = arr2[1] + ':' + arr2[2] + ' AM'
            te1 = arr2[3] + ':' + arr2[4] + ' AM'
          } else {
            if (arr2[1].length === 1) arr2[1] = '0' + arr2[1]
            if (arr2[3].length === 1) arr2[3] = '0' + arr2[3]
            ts1 = getAMPM(arr2[1] + ':' + arr2[2])
            te1 = getAMPM(arr2[3] + ':' + arr2[4])
          }
          businessHours[dayLabel].slots.push({
            time_start: ts1,
            time_end: te1
          })
          businessHours[dayLabel].open = true
        }
        if (arr2.length === 2 && arr2[1].toUpperCase() === 'CLOSED') {
          businessHours[dayLabel].open = false
        }
        if (businessHours[dayLabel].slots.length > 1) {
          businessHours[dayLabel].split = true
        }
      }
    })
    Object.keys(businessHours).forEach(function (day) {
      if (!businessHours[day].slots.length) {
        businessHours[day].slots.push({
          time_end: 'PM',
          time_start: 'AM'
        })
      }
    })
  } else {
    Object.keys(businessHours).forEach(function (day) {
      businessHours[day].slots.push({
        time_end: 'PM',
        time_start: 'AM'
      })
    })
  }

  Object.keys(businessHours).forEach(function (key) {
    businessHours[key].slots.forEach(function (day) {
      if (day.time_start !== 'AM') {
        day.time_start1 = day.time_start.split(' ')[0]
        day.time_start2 = day.time_start.split(' ')[1]
      } else {
        day.time_start2 = 'AM'
      }
      if (day.time_end !== 'PM') {
        day.time_end1 = day.time_end.split(' ')[0]
        day.time_end2 = day.time_end.split(' ')[1]
      } else {
        day.time_end2 = 'PM'
      }
    })
  })
  return businessHours
}

// this formatter is used to format google's regular hour
// conversion to backed confortable format
export const googleWorkingHourBEFormatter = (data) => {
  return data
    .map((itr) => {
      const openHours = _.get(itr, 'openTime.hours', undefined)
      const closeHours = _.get(itr, 'closeTime.hours', undefined)

      // invalid hours
      if (!openHours || !closeHours || !DAY_WITH_VALUE[itr.openDay] || !DAY_WITH_VALUE[itr.openDay].dbValue) {
        return ''
      }

      return `${DAY_WITH_VALUE[itr.openDay].dbValue}:${itr.openTime.hours}:00:${itr.closeTime.hours}:00`
    })
    .filter((i) => i !== '')
    .join(',')
}
export function convertSpecialHours(googleSpecialHours) {
  googleSpecialHours.forEach((google) => {
    if (google.startDate && google.openTime && google.closeTime) {
      if (google.startDate.month.toString().length === 1) {
        google.startDate.month = '0' + google.startDate.month
      }

      google.startDate_formatted = google.startDate.month + '/' + google.startDate.day + '/' + google.startDate.year
      google.time_start1 = getTimeFormatted(google.openTime).split(' ')[0]
      google.time_start2 = getTimeFormatted(google.openTime).split(' ')[1]

      google.time_end1 = getTimeFormatted(google.closeTime).split(' ')[0]
      google.time_end2 = getTimeFormatted(google.closeTime).split(' ')[1]
    } else if (google.startDate && google.isClosed) {
      if (google.startDate.month.toString().length === 1) {
        google.startDate.month = '0' + google.startDate.month
      }
      google.startDate_formatted = google.startDate.month + '/' + google.startDate.day + '/' + google.startDate.year
    } else {
      google.startDate_formatted = ''
      google.time_start2 = 'AM'
      google.time_end2 = 'PM'
    }
  })
  return googleSpecialHours && googleSpecialHours.length
    ? googleSpecialHours
    : [
        {
          startDate_formatted: '',
          time_start2: 'AM',
          time_end2: 'PM',
          isClosed: true
        }
      ]
}
export function formatSpecialHours(specialHours, fromBulk = false) {
  let err = false
  specialHours.forEach((google) => {
    if (google.startDate_formatted) {
      google.startDate = {}
      google.startDate.month = parseInt(google.startDate_formatted.split('/')[0])
      google.startDate.day = parseInt(google.startDate_formatted.split('/')[1])
      google.startDate.year = parseInt(google.startDate_formatted.split('/')[2])

      google.endDate = {}
      google.endDate.month = parseInt(google.startDate_formatted.split('/')[0])
      google.endDate.day = parseInt(google.startDate_formatted.split('/')[1])
      google.endDate.year = parseInt(google.startDate_formatted.split('/')[2])
      if (
        google.isClosed ||
        (fromBulk && (google.time_start1 === '' || google.time_end1 === '')) ||
        (fromBulk && (google.time_start1 === '00:00' || google.time_end1 === '00:00'))
      ) {
        google.openTime = ''
        google.closeTime = ''
      } else {
        if (google.time_start1 && google.time_start2 && google.time_end1 && google.time_end2) {
          google.openTime = convertTimeformats(google.time_start1 + ' ' + google.time_start2).split(' ')[0]
          google.closeTime = convertTimeformats(google.time_end1 + ' ' + google.time_end2).split(' ')[0]
        } else {
          err = true
        }
        if (
          moment(`${google.time_start1} ${google.time_start2}`, 'HH:mm A').isAfter(
            moment(`${google.time_end1} ${google.time_end2}`, 'HH:mm A'),
            'minutes'
          )
        ) {
          err = true
        }
        if (
          google.time_start === 'AM' ||
          google.time_end === 'PM' ||
          google.time_start1 === '00:00' ||
          google.time_end1 === '00:00'
        ) {
          err = true
        }
      }
    } else {
      google.startDate = {}
      google.endDate = {}
    }
  })
  if (err) {
    return false
  } else {
    return specialHours
  }
}

export function convertTimeformats(str) {
  const time = str
  let hours = Number(time.match(/^(\d+)/)[1])
  const minutes = Number(time.match(/:(\d+)/)[1])
  const AMPM = time.match(/\s(.*)$/)[1]
  if (AMPM === 'PM' && hours < 12) hours = hours + 12
  if (AMPM === 'AM' && hours === 12) hours = hours - 12
  let sHours = hours.toString()
  let sMinutes = minutes.toString()
  if (hours < 10) sHours = '0' + sHours
  if (minutes < 10) sMinutes = '0' + sMinutes
  return sHours + ':' + sMinutes
}
export function numberMask(data) {
  if (!data) {
    return ''
  }
  const value = data.toString().trim().replace(/\D/g, '')
  if (value.length < 4) return value
  else if (value.length < 7 && value.length > 3) {
    return '(' + value.slice(0, 3) + ')' + value.slice(3, 6)
  } else {
    return '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-' + value.slice(6)
  }
}
export function emailArrayValidation(array) {
  const emailRegx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  if (array.length) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== '') {
        const res = array[i].match(emailRegx)
        if (res) {
          continue
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
  return true
}
function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01'

      // this can happen when user paste number
    } else if (val > max) {
      val = max
    }
  }

  return val
}
export function cardExpiry(val) {
  const month = limit(val.substring(0, 2), '12')
  const date = limit(val.substring(2, 4), '31')
  return month + (date.length ? '/' + date : '')
}
export function generateYearsAndMonths() {
  const currentYear = moment().year()
  const years = []
  for (let i = 0; i < 20; i++) {
    years.push(currentYear + i)
  }
  const months = moment()._locale._monthsShort
  return { years, months }
}

export function formatFormData(formData, newKeys) {
  if (formData && newKeys) {
    Object.keys(newKeys).forEach(function (newfieldKey, index) {
      Object.keys(formData).find(function (key) {
        if (newfieldKey === key) {
          formData[newKeys[newfieldKey]] = formData[key]
          delete formData[key]
        }
        return newfieldKey === key
      })
    })
    return formData
  } else {
    return {}
  }
}
export function minutesToHours(minutes) {
  let hrs = Math.floor(minutes / 60)
  let min = minutes % 60
  if (hrs < 10) {
    hrs = `0${hrs}`
  }
  if (min < 10) {
    min = `0${min}`
  }
  return hrs + ':' + min
}

export function siteClass(siteID) {
  if (classMapping[siteID]) {
    return classMapping[siteID]
  } else {
    return siteID
  }
}

export function getIconImage(activity) {
  let ret
  const star = ['rating']
  const into = ['yext_wb_sync', 'wb_yext_sync']
  const msg = ['new_message']
  const user = ['incomplete_profile']
  const newcontact = ['new_contact_fail']
  const newcontactimprt = ['new_contact_success']
  const card = ['subscription_notification']
  const pen = ['new_review', 'private_review']
  const sendrev = ['review_send']
  const ssl = ['ssl_success', 'ssl_fails']
  const arrow = [
    'cardconnect_settings_updated',
    'website_appointment',
    'website_manage',
    'website_feature',
    'audience_review'
  ]
  const webform = ['website_custom_form']
  const webcontact = ['website_contact_us']
  const refresh = ['review_synced']
  const yext = ['yext_sync_success', 'yext_sync_failed']
  const google = [
    'google_sync_success',
    'google_sync_failed',
    'account_gmb_connect',
    'account_gmb_disconnect',
    'account_gmb_expired'
  ]
  const share = ['api_connect_disabled_by_admin']
  const byadmin = ['api_connect_enabled_by_admin']
  const video = ['video_process_complete', 'scrape_process_complete']
  const customer = ['user_import_success', 'user_import_failed']
  const warning = [
    'mid_missing',
    'cardconnect_settings_update_failed',
    'photo_limit_reached',
    'hubspot_crm_status',
    'social_token_expiry'
  ]
  const admin = ['admin_push']
  const campaign = ['campaign_shedule_send']
  const payment = [
    'new_payment',
    'payment_website',
    'new_payment_messenger',
    'new_payment_widget',
    'new_payment_contact'
  ]
  const mobile = ['twilio_number_request']
  const webSubscription = ['website_subscription', 'product_limit_changed', 'subscription_renew']
  const placement = ['new_qa']

  if (byadmin.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/employee-add.svg'
  }

  if (webform.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/website-publish.svg'
  }

  if (warning.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/warning.svg'
  }

  if (star.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/rating.svg'
  }

  if (share.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/share.svg'
  }

  if (into.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/text.svg'
  }

  if (msg.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/messenger.svg'
  }

  if (user.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/icon-user-new-1.svg'
  }

  if (card.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/share.svg'
  }

  if (pen.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/reviews.svg'
  }

  if (ssl.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/shield.svg'
  }

  if (arrow.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/sites.svg'
  }

  if (refresh.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/refresh.svg'
  }

  if (google.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/gmb.svg'
  }

  if (yext.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/text.svg'
  }

  if (video.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/media-icon.svg'
  }

  if (customer.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/media-import.svg'
  }

  if (admin.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/review_new.svg'
  }

  if (campaign.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/review_new.svg'
  }

  if (payment.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/icons/money-bags.svg'
  }

  if (mobile.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/review_sms.svg'
  }

  if (webcontact.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/website_contact_us.svg'
  }

  if (webSubscription.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/website-subscriber.svg'
  }

  if (sendrev.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/review_new.svg'
  }

  if (newcontact.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/contact_create.svg'
  }

  if (newcontactimprt.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/contact_import.svg'
  }

  if (placement.indexOf(activity) !== -1) {
    ret = 'https://dufzo4epsnvlh.cloudfront.net/image/activity-icons/placement.svg'
  }

  return ret
}

export function textCapitalize(word) {
  if (typeof word !== 'string') return word
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function hubspotTrackPage() {
  const _hsq = (window._hsq = window._hsq || [])
  const page = window.location.pathname
  // Track the page view for the new page
  _hsq.push(['setPath', page])
  _hsq.push(['trackPageView'])
}

export function phoneNumberAutomasking(item) {
  if (!item) {
    return ''
  }
  const value = item.toString().trim().replace(/^\+/, '')
  if (value.match(/[^0-9]/)) {
    return item
  }
  let city, number
  switch (value.length) {
    case 1:
    case 2:
    case 3:
      city = value
      break
    default:
      city = value.slice(0, 3)
      number = value.slice(3)
  }
  if (number) {
    let num = ''
    if (number.length > 3) {
      num = number.slice(0, 3) + '-' + number.slice(3, 7)
    } else {
      num = number
    }
    return ('(' + city + ') ' + num).trim()
  } else {
    return '(' + city
  }
}
export function UsCurrencyFormatter(amount) {
  if (amount > 100000) {
    amount = amount ? amount.toFixed(2) : amount.toFixed(2)
    return convertToKformat(amount)
  } else {
    if (amount) {
      amount = amount ? parseFloat(amount).toFixed(2) : amount
      const formatter = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 })
      return formatter.format(amount)
    } else {
      return '0.00'
    }
  }
}
function convertToKformat(amount) {
  // Nine Zeroes for Billions
  return Number(Math.abs(Number(amount))).toFixed(2) >= 1.0e9
    ? (Math.abs(Number(amount)) / 1.0e9).toFixed(2) + 'B'
    : Number(Math.abs(Number(amount)) >= 1.0e6).toFixed(2) // Six Zeroes for Millions
    ? Number(Math.abs(Number(amount)) / 1.0e6).toFixed(2) + 'M'
    : Number(Math.abs(Number(amount)) >= 1.0e3).toFixed(2) // Three Zeroes for Thousands
    ? (Math.abs(Number(amount)) / 1.0e3).toFixed(2) + 'K'
    : Math.abs(Number(amount)).toFixed(2)
}
export function nFormatter(number, precision = 2) {
  const abbrev = ['', 'K', 'M', 'B', 'T']
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1))
  const suffix = abbrev[order]

  return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix
}
export function downloadFile(fileUrl, name, type) {
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
    'x-access-token': ''
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.${type}`)
    document.body.appendChild(link)
    link.click()
  })
}

export function slotConversionFunction(bussHours) {
  for (const key in bussHours) {
    if (bussHours[key].slots && bussHours[key].slots.length) {
      bussHours[key].slots &&
        bussHours[key].slots.forEach((slotItem, index) => {
          if (slotItem.time_start !== 'AM') {
            slotItem.time_start1 = slotItem.time_start.split(' ')[0]
            slotItem.time_start2 = slotItem.time_start.split(' ')[1]
          } else {
            slotItem.time_start2 = 'AM'
          }

          if (slotItem.time_end !== 'PM') {
            slotItem.time_end1 = slotItem.time_end.split(' ')[0]
            slotItem.time_end2 = slotItem.time_end.split(' ')[1]
          } else {
            slotItem.time_end2 = 'PM'
          }
        })
    } else {
      bussHours[key].slots = [
        {
          time_end: '00:00 AM',
          time_end1: '00:00',
          time_end2: 'AM',
          time_start: '00:00 AM',
          time_start1: '00:00',
          time_start2: 'AM'
        }
      ]
    }
  }

  return bussHours
}
export function getTimeInAMPM(input) {
  const timeString = input
  const H = +timeString.substr(0, 2)
  const h = H % 12 || 12
  const ampm = H < 12 ? 'AM' : 'PM'
  const timeStart = h + timeString.substr(2, 3) + ampm
  return timeStart
}
export function getDiff(timeZone) {
  const t = i18next.t
  const time = moment(timeZone)
  const now = moment()

  const intervals = [
    { unit: 'year', key: 'listings.time_year' },
    { unit: 'month', key: 'listings.time_month' },
    { unit: 'day', key: 'listings.time_day' },
    { unit: 'hour', key: 'listings.time_hour' },
    { unit: 'minute', key: 'listings.time_minute' }
  ]

  for (const interval of intervals) {
    const diff = now.diff(time, interval.unit)
    if (diff > 0) {
      return t(interval.key, { count: diff })
    }
  }

  return t('listings.time_just-now')
}

export function getDocIcon(media) {
  if (media) {
    let name = `${media.split('.')[media.split('.').length - 1]}`
    if (name.length > 3) {
      name = name.slice(0, 3)
    }
    return `https://dufzo4epsnvlh.cloudfront.net/image/icon-library/${name}-ico.svg`
  }
}
export function downloadMediaFile(fileUrl) {
  axios({
    url: `${config.server}/api/medias-url?url=${fileUrl}`,
    method: 'GET',
    responseType: 'blob',
    'x-access-token': ''
  }).then((response) => {
    const urlObject = window.URL.createObjectURL(new Blob([response.data]))
    const suggestedFileName = fileUrl.match(/.*\/(.*)$/)[1]
    const downloadLink = document.createElement('a')
    downloadLink.href = urlObject
    downloadLink.setAttribute('download', suggestedFileName)
    document.body.appendChild(downloadLink)
    downloadLink.click()
    // cleanup
    downloadLink.remove()
  })
}
export function capitalizeunderscore(input) {
  if (input != null) {
    input = input.toLowerCase()
    let str = input.substring(0, 1).toUpperCase() + input.substring(1).replace('-', ' ')
    str = str.split(' ')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  }
}
export function formatDate(date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [month, day, year].join('/')
}
export function isInclueMenu(businesssettings) {
  let status = false
  if (businesssettings && businesssettings.placement_services && businesssettings.placement_services.length) {
    const index = findWithAttr(businesssettings.placement_services, 'service_name', 'food', true)
    if (index || index === 0) {
      status = true
    }
  } else if (businesssettings && businesssettings.foursquare_menu) {
    status = true
  }
  return status
}
export function setUserAttempt(user) {
  const data = Cookies.getJSON('users') ? Cookies.getJSON('users') : []
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + 1)
  data.push({ email: user.user_email, ip: user.hb_ipAddress })
  Cookies.set('users', JSON.stringify(data), { expires: expireDate })
}

export function getUserCount() {
  const data = Cookies.getJSON('users') ? Cookies.getJSON('users') : []
  return data && data.length ? data.length : 0
}
export function getIP() {
  return new Promise((resolve, reject) => {
    const callback = (res) => {
      if (res && res.ip) {
        resolve(res.ip)
      } else {
        resolve(null)
      }
    }
    ipapi.location(callback)
  })
}
export function phoneHide(input) {
  if (input != null) {
    const formatednumber = `XXX-XXX-${input.slice(-4)}`
    return formatednumber
  }
}

export function getFormatedToday() {
  const za = new Date()
  const zaR = za.getFullYear()
  const zaMth = ('0' + (za.getMonth() + 1)).slice(-2)
  const zaDs = ('0' + za.getDate()).slice(-2)
  const zaTm = za.toTimeString().substr(0, 5)
  return `${zaR}-${zaMth}-${zaDs}:${zaTm}`
}

export function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export function sortByKey(array, key) {
  const arr = array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
  return arr
}
export function prettyNumber(numbers) {
  return numbers ? numbers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
}

export function validateEmail(email) {
  if (email !== 'All Contacts') {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  } else {
    return true
  }
}

export function validateMobile(mobile) {
  const value = String(mobile).replace(/[^0-9]+/g, '')
  const re = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/
  return re.test(value)
}
export function redirectToAdmin(cookies) {
  console.log('redirecting to admin', cookies)
  const obj = {
    t: getCookieByKey(ID_TOKEN_ADMIN) || cookies.token,
    s: cookies.status,
    r: cookies.role,
    uuid: cookies.user_unique_id
  }
  if (cookies.isCognito === 'true') {
    obj.isCognito = cookies.isCognito
    obj.isAwsCognitoUser = cookies.isAwsCognitoUser
  }
  // if (this.props.adminUser && this.props.adminUser.length) {
  //     obj.ac = 1
  // }

  let url = ''

  url = `${config.redirection_url}/${cookies.user_unique_id}/customers?${objToUrlParams(obj)}`
  removeAdminCookie()
  window.location.href = url
}

export function getCustomersReviewParams(customers) {
  const params = []
  if (customers && customers.length) {
    for (let i = 0; i < customers.length; i++) {
      params.push(customers[i].i + '~' + customers[i].r)
    }
  }
  return params.join(',')
}
export function getcustomersReferenceParams(customers, currentUserLocations) {
  const params = []
  for (let i = 0; i < customers.length; i++) {
    const refId = _pick(currentUserLocations, 'customer', customers[i])
    params.push(refId)
  }
  return params.join(',')
}
export function _pick(arr, field, match) {
  let payload
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][field] === match) {
      payload = arr[i]
    }
  }
  return payload.customerData.reference_number
}
export function checkProductAccess(selectedLocations, product, currentUserLocations) {
  let productAccess = false
  selectedLocations.forEach((loc) => {
    const index = findWithAttr(currentUserLocations, 'customer', loc)
    if (index || index > -1) {
      const products = currentUserLocations[index].products
      if (products.indexOf(product) > -1) {
        productAccess = true
      }
    }
  })
  return productAccess
}
export function checkAnyLocationProductAccess(selectedLocations, product, currentUserLocations) {
  let productAccess = false
  for (let i = 0; i < selectedLocations.length; i++) {
    const loc = selectedLocations[i]
    const index = findWithAttr(currentUserLocations, 'customer', loc)
    if (index || index > -1) {
      const products = currentUserLocations[index].products
      if (products.indexOf(product) > -1) {
        productAccess = true
        break
      }
    }
  }
  // selectedLocations.forEach((loc)=>{
  //     var index = findWithAttr(currentUserLocations, "customer", loc);
  //     if(index || index >-1){
  //         var products  = currentUserLocations[index].products;
  //         if(products.indexOf(product) > -1){
  //             productAccess = true;
  //         }
  //     }
  // })
  return productAccess
}
export function checkAvatar(email) {
  const hash = md5(email)
  const avatarUrl = `http://www.gravatar.com/avatar/${hash}?s=181&d=404`
  return avatarUrl
}
export function humanize(str) {
  let i
  const frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

export function changeTimezone(date, ianatz) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-us', {
      timeZone: ianatz
    })
  )
  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime()
  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff) // needs to substract
}

// convert hex code into rgb/rgba
export function hexToRGB(hex, alpha) {
  const getHex = hex
  // checking if hex value is 3 digit or 6 digits
  if (hex) {
    if (hex && hex.length > 4) {
      hex = getHex
    } else {
      hex = getHex + getHex.slice(1, 4)
    }
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
  }
}

export function cleanPhone(value) {
  let phone = String(value)
  phone = phone.replace(/[^\d+]/g, '')
  if (phone.substr(0, 1) === '+') {
    phone = '+' + phone.replace(/[^\d]/g, '')
  } else {
    phone = phone.replace(/[^\d]/g, '')
  }
  return phone.replace(/^0+|\+1/, '')
}

export const isMobile = () => {
  // eslint-disable-next-line valid-typeof
  const userAgent = typeof window.navigator === undefined ? '' : navigator.userAgent
  const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i))
  return mobile
}

export function isMultiLocation() {
  const cookiesData = getCookie()
  const host = window.location.host
  if (cookiesData && cookiesData.enterprise_group && config.multiLocationDomain === host) {
    return cookiesData.enterprise_group
  }
  return ''
}

export const checkForValidSpecialHours = (specialHours) => {
  if (specialHours.length > 1) return true

  const hourCheck = specialHours[0]
  if (hourCheck) {
    const check = 'startDate' in hourCheck
    return check
  }

  return true
}
export function setEnterpriseGroupCookies(uuid, groupName) {
  try {
    const user = Cookies.getJSON(uuid)
    if (user) {
      user.enterprise_group = groupName
    }
    Cookies.set(uuid, user, { expires: cookies.expiry })
  } catch (e) {
    console.log(e)
  }
}

export const getPaymentDisplayFields = (item) => {
  const { status, source } = item
  const updatedAt = _.get(item, 'updated_at')
  const paidDate = _.get(item, 'paid_date')
  const refundDate = _.get(item, 'refund_date')
  const invoiceDue = _.get(item, 'invoice_due')
  const createdAt = _.get(item, 'created_at')

  // determine payment status display fields
  let statusLabel = ''
  let statusDateLabel = ''
  let iconName = null
  let iconClass = ''
  if (status === PAYMENT_STATUS.PAID || status === PAYMENT_STATUS.ACCEPTED) {
    statusLabel = 'Paid'
    statusDateLabel = paidDate ? `${momentFromNowShort(paidDate)} ago` : ''
    iconName = 'statusTick'
  } else if (status === PAYMENT_STATUS.PROCESSING || status === PAYMENT_STATUS.CAPTURE) {
    statusLabel = 'Processing'
    iconName = 'processing'
    statusDateLabel = paidDate ? moment(paidDate).format('MMM D') : moment(createdAt).format('MMM D')
  } else if (
    status === PAYMENT_STATUS.CANCELLED ||
    status === PAYMENT_STATUS.DECLINED ||
    status === PAYMENT_STATUS.CANCELED ||
    status === PAYMENT_STATUS.VOIDED
  ) {
    if (
      ((status === PAYMENT_STATUS.CANCELLED || status === PAYMENT_STATUS.CANCELED) && paidDate) ||
      status === PAYMENT_STATUS.VOIDED
    ) {
      statusLabel = 'Voided'
    } else if (
      (status === PAYMENT_STATUS.CANCELLED || status === PAYMENT_STATUS.CANCELED) &&
      (!paidDate || paidDate === '')
    ) {
      statusLabel = 'Canceled'
    } else {
      statusLabel = 'Declined'
    }
    statusDateLabel = updatedAt ? `${momentFromNowShort(updatedAt)} ago` : ''
    iconName = 'statusClose'
  } else if (status === PAYMENT_STATUS.REFUND) {
    statusLabel = 'Refunded'
    statusDateLabel = refundDate ? `${momentFromNowShort(refundDate)} ago` : ''
    iconName = 'refund'
  } else if (status === PAYMENT_STATUS.REFUND_PROCESSING) {
    statusLabel = 'Refund Processing'
    statusDateLabel = refundDate ? `${momentFromNowShort(refundDate)} ago` : ''
    iconName = 'processing'
  } else if (status === PAYMENT_STATUS.REFUND_FAILED) {
    statusLabel = 'Refund Failed'
    statusDateLabel = refundDate ? `${momentFromNowShort(refundDate)} ago` : ''
    iconName = 'statusClose'
  } else if (
    status === PAYMENT_STATUS.OPEN ||
    status === PAYMENT_STATUS.SCHEDULED ||
    status === PAYMENT_STATUS.PENDING
  ) {
    iconName = 'statusPending'
    if (invoiceDue && source === 'invoice') {
      statusLabel = 'Due'
      statusDateLabel = moment(invoiceDue).local().format('MMM D')
    } else if (source === 'invoice' && createdAt) {
      statusLabel = 'Due'
      statusDateLabel = moment(createdAt).format('MMM D')
    } else {
      statusLabel = 'Pending'
    }
  } else if (status === PAYMENT_STATUS.DUE && source === 'invoice') {
    statusLabel = 'Overdue'
    statusDateLabel = invoiceDue ? moment(invoiceDue).local().format('MMM D') : ''
    iconName = 'statusPending'
    iconClass = 'status-overdue-icon'
  } else if (status === PAYMENT_STATUS.DUE && (source === 'getpaid' || source === 'website')) {
    iconName = 'statusPending'
    statusLabel = 'Pending'
  }

  return { statusLabel, statusDateLabel, iconName, iconClass }
}

export function getBootstrapColorFromStatus(status) {
  switch (status) {
    case PAYMENT_STATUS.DECLINED:
    case PAYMENT_STATUS.DUE: // overdue
    case PAYMENT_STATUS.VOIDED:
    case PAYMENT_STATUS.CANCELED:
    case PAYMENT_STATUS.CANCELLED:
      return 'danger'
    case PAYMENT_STATUS.PAID:
    case PAYMENT_STATUS.ACCEPTED:
      return 'success'
    default:
      return 'muted'
  }
}

export function getRecurringIconNameFromStatus(status) {
  switch (status) {
    case PAYMENT_STATUS.DECLINED:
    case PAYMENT_STATUS.DUE: // overdue
    case PAYMENT_STATUS.VOIDED:
    case PAYMENT_STATUS.CANCELED:
    case PAYMENT_STATUS.CANCELLED:
      return 'recurringRed'
    case PAYMENT_STATUS.PAID:
    case PAYMENT_STATUS.ACCEPTED:
      return 'recurring'
    default:
      return 'recurringGray'
  }
}

export function setpaymentAttempt() {
  let data = Cookies.getJSON('userPayment') || { count: 0, updatedTime: '' }
  if (data) {
    data = { ...data, ...updateTimeAndCount(data.count) }
  }
  Cookies.set('userPayment', JSON.stringify(data), { expires: 1 })
}
export function getPaymentCount() {
  const isAutomation = Cookies.getJSON('isAutomation')
  console.log('isAutomation = ' + isAutomation)
  if (!isAutomation) {
    const initial = { count: 0, updatedTime: '' }
    let data = Cookies.getJSON('userPayment') || initial
    if (data.updatedTime) {
      const diff = getTimeDifferenceInHours(data.updatedTime)
      if (Math.abs(Math.round(diff)) >= 5) {
        data = { ...data, ...initial }
        Cookies.set('userPayment', JSON.stringify(data), { expires: 1 })
      }
    }
    return data.count
  } else {
    return 0
  }
}
function updateTimeAndCount(count) {
  return {
    updatedTime: new Date(),
    count: count + 1
  }
}
function getTimeDifferenceInHours(time) {
  let diff = (new Date().getTime() - new Date(time).getTime()) / 1000
  diff /= 60
  return diff
}

export function isValidAddress(address) {
  if (address) {
    const re = /\d{1,5}\s\w/
    const reg = /\s+(?=\d)/
    if (re.test(address) || reg.test(address)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export function getReferralappUserData(queryData, appData) {
  const data = {
    email: '',
    realmId: '',
    channel: ''
  }
  const appProps = appData.find((app) => app.source === queryData.source)
  if (appProps && appProps.payload) {
    const emailKey = appProps.payload.email
    const realmId = appProps.payload.realmId
    const channel = appProps.payload.channel
    if (queryData[emailKey]) {
      data.email = queryData[emailKey]
    }
    if (queryData[realmId]) {
      data.realmId = queryData[realmId]
    }
    if (queryData[channel]) {
      data.realmId = queryData[channel]
    }
  }
  return data
}
export function setReferralAppSource(source) {
  localStorage.setItem('referralAppSource', source)
}
export const getCookieByKey = (key) => {
  if (key) {
    return Cookies.get(key)
  }
}

export const isIntegrationRefferalApp = (referralAppSource = '') => {
  const referralAppSourceLC = referralAppSource.toLowerCase()
  if (INTEGRATION_REFFERAL_APPS.includes(referralAppSourceLC)) {
    return true
  }
  return false
}

export const isReferralSourceDiffWithLogin = (referralAppSource = '', providerName = '') => {
  const referralAppSourceLC = referralAppSource.toLowerCase()
  const providerNameLC = providerName.toLowerCase()
  if (REFERRAL_APP_SOURCE_PROVIDER_MAP[referralAppSourceLC] !== providerNameLC) {
    return true
  }
  return false
}

export const getProviderNameFromCookie = () => {
  const cookie = getCookie()
  const token = cookie && cookie.token
  const decodeData = decodeCookies(token)
  // since it is onboarding flow we are expecting only one identities
  return decodeData.identities && decodeData.identities[0] && decodeData.identities[0].providerName
}
export function getReferralAppSource() {
  return localStorage.getItem('referralAppSource') || getCookieByKey(REFERRAL_APP_SOURCE_COOKIE_KEY)
}
export function getUserDataFromQuery(queryData) {
  const data = {
    email: '',
    firstName: '',
    lastName: '',
    phone: ''
  }
  if (queryData.info) {
    const decodedData = atob(queryData.info)
    try {
      const userDetails = JSON.parse(decodedData)
      if (userDetails.email) {
        data.email = userDetails.email
      }
      if (userDetails.firstname) {
        data.firstName = userDetails.firstname
      }
      if (userDetails.lastname) {
        data.lastName = userDetails.lastname
      }
      if (userDetails.phonenumber) {
        data.phone = phoneNumberFormat(userDetails.phonenumber)
      }
    } catch (e) {
      console.log('Decode error', e)
    }
  }
  return data
}

export function isCognitoUser() {
  const data = Cookies.getJSON(cookieName())
  if (data) {
    return data.isAwsCognitoUser
  }
}
export function cognitoAccessToken() {
  const data = Cookies.getJSON(cookieName())
  if (data) {
    return data.cogAccessToken
  }
}
export function validatePassword(value) {
  if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?~!@$ %^&*\-+{}[\]|']).{6,}$/.test(value)) {
    return true
  } else {
    return false
  }
}

export const shouldTrackWithSegment = (role) => {
  return ROLES_SEGMENT_TRACK.indexOf(role) !== -1
}

export const getV2ConfigCatValue = async (flag) => {
  const me = {
    _id: '',
    user_email: Cookies.get('user_email')
  }
  let flagStatus = false
  if (flag === 'QB') {
    flagStatus = await getFeatureVal(featureFlags.v2qbdatasync, me)
  } else if (flag === 'FB') {
    flagStatus = await getFeatureVal(featureFlags.v2fbdatasync, me)
  } else if (flag === 'GMB') {
    flagStatus = await getFeatureVal(featureFlags.v2gmbdatasync, me)
  } else if (flag === 'BusinessSettings') {
    flagStatus = await getFeatureVal(featureFlags.v2Businesssettings, me)
  } else if (flag === 'isV2SunsetApi') {
    flagStatus = await getFeatureVal(featureFlags.isv2sunsetapi, me)
  } else if (flag === 'v2tempqacalendar') {
    flagStatus = await getFeatureVal(featureFlags.v2tempqacalendar, me)
  } else if (flag === 'v2Thumbtack') {
    flagStatus = await getFeatureVal(featureFlags.v2Thumbtack, me)
  } else if (flag === 'uberallBanners') {
    flagStatus = await getFeatureVal(featureFlags.uberallbanners, me)
  } else if (flag === 'wisetack') {
    flagStatus = await getFeatureVal(featureFlags.wisetack, me)
  }
  return flagStatus
}

export const sessionStorageUtil = {
  getString: function (a) {
    return window.sessionStorage.getItem(a)
  },
  setString: function (a, b) {
    return window.sessionStorage.setItem(a, b)
  }
}

export const isPopupAlreadyOpened = (userId) => {
  if (sessionStorageUtil.getString('dclGracePeriodPopupOpened') === userId) {
    return true
  }
  return false
}

export const setPopupAlreadyOpenedInSession = (userId) => {
  sessionStorageUtil.setString('dclGracePeriodPopupOpened', userId)
}

export const clearPopupAlreadyOpenedInSession = () => {
  sessionStorageUtil.setString('dclGracePeriodPopupOpened', '')
}

export const gmbLocationOptions = (locations) => {
  const disableGmbVerification = process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'live'
  let options = []
  if (locations && locations.length) {
    options = locations
      .filter((itr) => (disableGmbVerification ? true : itr.verificationState === GOOGLE_STATUS.COMPLETED))
      .map((item, index) => {
        const addressLinse = _.get(item, 'storefrontAddress.addressLines', [])
        const label = `${item.title} ${addressLinse.join(' ')}`

        return {
          value: item,
          label
        }
      })
  }
  return options
}

/** RSA algo based encryption logic used by messenger
 *  @param messageToEncrypt message to encrypt
 */
export const encryptMessageUsingRSAPub = async (messageToEncrypt) => {
  try {
    // get public key from S3 bucket
    const EncData = await axios({
      url: 'https://dufzo4epsnvlh.cloudfront.net/app/message_publicKey.pem'
    }).then((response) => {
      const jsEncrypt = new JSEncrypt()
      jsEncrypt.setPublicKey(response.data)
      const encryptedMsg = jsEncrypt.encrypt(messageToEncrypt)
      if (encryptedMsg) return encryptedMsg
    })
    if (EncData) return EncData

    throw new Error('public key not accepted.')
  } catch (e) {
    console.log('Error while encrypting message :', e)
  }
}
export function convertToBase64(text) {
  return Buffer.from(text).toString('base64')
}

export function findSettingValue(settings, key, platform_id) {
  const getSettingValue = settings.find((item) => item.option_key === key)
  if (getSettingValue.option_value) {
    const parsedValue = JSON.parse(getSettingValue.option_value)
    const data = parsedValue.find((item) => item === platform_id)
    if (data) {
      return true
    }
    return false
  }
}

export const getIdToken = () => {
  return getCookieByKey(ID_TOKEN)
}

export const getAdminIdToken = () => {
  return getCookieByKey(ID_TOKEN_ADMIN)
}

export const isFederatedLogin = () => {
  const loginProvider = getCookieByKey('loginProvider')
  return FEDERATED_LOGIN_PROVIDERS.indexOf(loginProvider) !== -1
}

export const goToUpgradePlan = async (me, qs) => {
  const isNewPricingPlanEnabled = await getFeatureVal(featureFlags.pricingProjectFlows, me)
  let path = ''
  if (isNewPricingPlanEnabled) {
    path = `/${me.user_unique_id}/upgrade/new-plan`
  } else {
    path = `/${me.user_unique_id}/upgrade/pro-plan`
  }
  if (qs) {
    path += `?${qs}`
  }
  history.push(path)
}

export const formatUSDFromCent = (amount) => {
  return new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD'
  }).format(amount / 100)
}

export const isValidHttpUrl = (string) => {
  try {
    const newUrl = new URL(string)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (err) {
    return false
  }
}

/**
 *
 * @param {any[]} a
 * @param {any[]} b
 * @returns {any[]}
 */
export const intersect = (a, b) => {
  const setB = new Set(b)
  return [...new Set(a)].filter((x) => setB.has(x))
}
