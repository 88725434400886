import * as service from './service'
import notification from '@components/notification'
import { capitalize, getMessages } from '@helpers/index'
import { sortByKey } from '@helpers/utility'

export default {
  state: {
    apiError: false,
    simvolyWebsites: [],
    simvolySubmitting: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    oncreateYextAccountError(state, data) {
      let err = data && data.data && data.data.error ? data.data.error : 'Please try again later'
      err = err.replace(/[\r\n]/g, ' ,').replace(/:/gi, '-')

      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    // cross check this function
    onGetLeaderboardSuccess(state, data) {
      if (data && data.data && data.data.length) {
        data.data.forEach(function (item) {
          item.created_at = item.user.created_at
        })
      }
      return {
        ...state,
        loading: false,
        leaderboard: data.data
      }
    },
    onGetTableFormatSuccess(state, data) {
      return {
        ...state,
        loading: false,
        locationTableOrder: data.data.location,
        contactTableOrder: data.data.contact
      }
    },
    onTableLayoutSuccess(state, data) {
      notification('success', 'Updated Successfully')
      return {
        ...state,
        loading: false
      }
    },
    onGetUserDataSuccess(state, data) {
      const userData = data

      // if(userData && userData.products && userData.products.length){
      //     for(var i=0;i<userData.products.length;i++){
      //         if (userData.products[i].product_id && userData.products[i].status === 1) {
      //             currentProducts.push(userData.products[i].product_id.product_name);
      //         }
      //     }
      // }

      if (userData.mobile) {
        const formated = userData.mobile.toString().replace(/\D/g, '')
        if (formated.length === 11) {
          userData.mobile = formated.substr(1)
        } else {
          userData.mobile = formated
        }
      }
      if (userData.mobile && userData.mobile.location_phone) {
        const formated = userData.mobile.location_phone.toString().replace(/\D/g, '')
        if (formated.length === 11) {
          userData.mobile.location_phone = formated.substr(1)
        } else userData.mobile.location_phone = formated
      }

      return {
        ...state,
        loading: false,
        userData: userData
      }
    },
    onGetUserAnalyticsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userAnalytics: data && data.data
      }
    },
    onGetBusinessTypeSuccess(state, data) {
      // console.log("object")
      return {
        ...state,
        loading: false,
        businessType: data
      }
    },
    onGetProductsSuccess(state, data) {
      let products = data
      const user = { ...state.userData }
      const prods = []

      products = products.filter((element) => {
        return element.product_name !== 'sms-campaign'
      })

      if (user && user.products) {
        const selected_products = []
        user.products.forEach((pdt) => {
          selected_products.push({
            id: pdt.product_id._id,
            status: pdt.status
          })
        })
        products.forEach((product) => {
          const obj = selected_products.filter(function (arr) {
            return arr.id === product._id
          })[0]

          if (obj && obj.id) {
            product.selected = true
            product.added = true
            product.status = obj.status
          } else {
            product.status = 0
          }
        })
        products.forEach((product) => {
          // console.log(product.status,product.product_name);
          if (product._id && product.status === 1) {
            prods.push(product.product_name)
          }
        })

        products = sortByKey(products, 'product_slug')
      }

      // console.log("Products==>",products)
      // console.log("Prods==>",prods)

      return {
        ...state,
        loading: false,
        userProducts: products,
        userProds: prods
      }
    },
    onGetBetaProductsSuccess(state, data) {
      const userData = { ...state.userData }
      const betaProducts = data
      if (userData && userData.products) {
        const selected_products = []
        userData.products.forEach((pdt) => {
          selected_products.push({
            id: pdt.product_id._id,
            status: pdt.status
          })
        })
        betaProducts.forEach((product) => {
          const obj = selected_products.filter(function (arr) {
            return arr.id === product._id
          })[0]

          if (obj && obj.id) {
            product.selected = true
            product.added = true
            product.status = obj.status
          } else {
            product.status = 0
          }
        })
      }

      return {
        ...state,
        loading: false,
        betaProducts: betaProducts
      }
    },
    onGetAccountSettingsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userAccSettings: data.data
      }
    },
    onResetPasswordSuccess(state, data) {
      notification('success', 'A reset password link has been sent to this customer')
      return {
        ...state,
        loading: false
      }
    },
    onSendQuickLoginSuccess(state, data) {
      notification('success', 'A quick login link has been sent to this customer')
      return {
        ...state,
        loading: false
      }
    },
    onDeleteLocationSuccess(state, data) {
      notification('success', 'Successfully deleted location')
      return {
        ...state,
        loading: false
      }
    },
    onGetPlatformUrlSuccess(state, data) {
      return {
        ...state,
        loading: false,
        platformUrl: data && data.platform_url ? data.platform_url : ''
      }
    },
    onLoginWithEmailSuccess(state, data) {
      return {
        ...state,
        loading: false,
        loginWithEmailData: data.data
      }
    },
    onRepublishWebsitesSuccess(state) {
      notification('success', 'Thanks, your request has been registered')
      return {
        ...state,
        loading: false
      }
    },
    onCustomerInfoUpdateSuccess(state, data) {
      notification('success', 'User has been updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onDeleteUserDataSuccess(state, data) {
      notification('success', 'User data deletion has been scheduled')
      return {
        ...state,
        loading: false
      }
    },
    onGetUserActivitiesSuccess(state, data) {
      let delCount = 0
      let activitiesTotl = 0
      for (let i = data.docs.length - 1; i >= 0; i--) {
        if (data.docs[i].meta_data && data.docs[i].meta_data.succesful === 0) {
          data.docs[i].delstatus = 'deleted'
          delCount++
        }
      }
      for (let i = data.docs.length - 1; i >= 0; i--) {
        if (!data.docs[i].status || (data.docs[i].status && data.docs[i].status !== 'canceled')) {
          if (data.docs[i].display_message) {
            activitiesTotl++
          }
        }
      }
      let array = data.docs && data.docs.length ? data.docs : []
      if (state.userActivityList && state.userActivityList.docs && state.userActivityList.docs.length) {
        array = [...state.userActivityList.docs, ...array]
      }
      data.docs = array
      return {
        ...state,
        loading: false,
        userActivityList: data,
        activitiesTotl: activitiesTotl,
        delCount: delCount
      }
    },
    clearUserActivityData(state, data) {
      return {
        ...state,
        userActivityList: null,
        activitiesTotl: null,
        delCount: null
      }
    },
    onImportLocationsFromCSVSuccess(state, data) {
      let message = ''
      if (data && data.data && data.data.Items_skipped && data.data.Items_skipped.length > 0) {
        const skip = []
        for (let s = 0; s < data.data.Items_skipped.length; s++) {
          skip.push(data.data.Items_skipped[s].firstname)
        }
        message = 'Import successful. ' + data.data.Items_skipped.length + ' customers(s) skipped'
        notification('success', message)
      } else {
        message = 'Upload Successful! This will take some time on our side to process. We’ll keep you updated.'
        notification('success', message)
      }
      return {
        ...state,
        loading: false
      }
    },
    onBatchUpdateSuccess(state, data) {
      notification('success', 'Account settings updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onSuccessGetEnterPriceUserApiData(state, data) {
      return {
        ...state,
        myAccountLoading: false,
        userApiData: data.data
      }
    },
    onEnableApiConnectSuccess(state, data) {
      if (data.message) {
        notification('success', data.message)
      }
      return {
        ...state,
        loading: false
      }
    },
    onUpdateAccountSettingsSuccess(state, data) {
      if (data.message) {
        notification('success', data.message)
      }

      return {
        ...state,
        loading: false,
        userAccSettings: data.data
      }
    },
    onGetSocialConnectionsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userSocialConnections: data && data.data
      }
    },
    onLoadBusinessSettingsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userBusinessSettings: data && data.data
      }
    },
    onUpdateBusinessSettingsSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false,
        userBusinessSettings: data
      }
    },
    onUpdateSubscriptionInfoSuccess(state, data) {
      notification('success', data.message)
      return {
        ...state,
        loading: false
      }
    },
    onGeStatesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allStates: data && data.states
      }
    },
    onSmsNumberSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allSmsNumber: data && data.data
      }
    },
    onSendAreaCodeSuccess(state, data) {
      if (data.message) {
        notification('success', 'GoSite Sms Number Added Successfully')
      }
      return {
        ...state,
        loading: false
      }
    },
    onDeleteAreaCodeSuccess(state, data) {
      if (data.message) {
        notification('success', 'GoSite Sms Number Deleted Successfully')
      }
      return {
        ...state,
        loading: false
      }
    },
    onRequestSuccess(state, data) {
      const { show, message } = data
      if (show) {
        notification('success', message || '')
      }
      return {
        ...state,
        paymentLoading: false
      }
    },
    onGetGmbAccountsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userGmbData: data
      }
    },
    onSyncSuccess(state, data) {
      notification('success', data.data ? data.data : data.message)
      return {
        ...state,
        loading: false
      }
    },
    onGetGmbLocationsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        gmbLocations: data && data.data
      }
    },
    onGetLocationCategoriesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        locationCategories: data && data.data
      }
    },
    ongetLocationCategoriesSecondarySuccess(state, data) {
      return {
        ...state,
        loading: false,
        locationSecondaryCatogory: data && data.data && data.data.docs
      }
    },
    onDeleteMediaPhotoSuccess(state) {
      notification('success', 'Successfully deleted photo')
      return {
        ...state,
        loading: false
      }
    },
    onDeleteMediaVideoSuccess(state) {
      notification('success', 'Successfully deleted video')
      return {
        ...state,
        loading: false
      }
    },
    onbulkUpdateLocationsSuccess(state, data) {
      notification('success', 'Business settings updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onCreateYextAccountSuccess(state, data) {
      notification('success', 'Placement settings updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onSyncYextSuccess(state, data) {
      notification('success', 'Placement settings updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onGetOffersSuccess(state, data) {
      const yextTypes = data.data
      let offers = []
      let locationTypes = []
      if (yextTypes && yextTypes.length) {
        offers = yextTypes.filter((item) => item.item_type === 'services')
        locationTypes = yextTypes.filter((item) => item.item_type === 'location_type')
      }

      return {
        ...state,
        loading: false,
        offers: offers,
        locationTypes: locationTypes
      }
    },
    onUnsyncYextSuccess(state, data) {
      notification('success', 'Your yext page has been successfully unsync')
      return {
        ...state,
        loading: false
      }
    },
    ongetMenuLocationsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        locationMenus: data.data
      }
    },
    onaddSectionitemSuccess(state, data) {
      notification('success', 'Menu section added')
      return {
        ...state,
        loading: false,
        newAddedSection: data.data
      }
    },
    onGetMenuOptionsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        menuLabel: data && data.data
      }
    },
    ongetSavedFiltersSUccess(state, data) {
      return {
        ...state,
        loading: false,
        savedLocationFilter: data
      }
    },
    ononSaveFilterSUccess(state, data) {
      notification('success', 'New filter added successfully')
      return {
        ...state,
        loading: false,
        savedLocationFilter: [...state.savedLocationFilter, data]
      }
    },
    ononUpdateFilterSUccess(state, data) {
      notification('success', 'Filter updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onCheckYextDuplicateSuccess(state, data) {
      const yextDuplicates =
        data &&
        data.data.filter((duplicate) => {
          return duplicate.status === 'POSSIBLE_DUPLICATE' || duplicate.status === 'SUPPRESSION_REQUESTED'
        })
      const filteredDuplicates = yextDuplicates.slice(0, 10)
      return {
        ...state,
        loading: false,
        yextDuplicates: yextDuplicates,
        filteredDuplicates: filteredDuplicates
      }
    },
    onfetchConfigFlag(state, achPaymentFeatureFlag) {
      return {
        ...state,
        achPaymentFeatureFlag: achPaymentFeatureFlag
      }
    },
    onGetPresenceLocationSuccess(state, data) {
      let liveSites = 0
      const userBusinessSettings = { ...state.userBusinessSettings }
      userBusinessSettings.placement_services = data.location.placement_services
      const isStatusData = data.statuses
      const fullfilteredStatus = data.statuses
      const filteredStatus = isStatusData.slice(0, 10)
      isStatusData.forEach((item) => {
        if (item.status === 'LIVE') {
          liveSites++
        }
      })
      return {
        ...state,
        loading: false,
        liveSites: liveSites,
        isStatusData: isStatusData,
        fullfilteredStatus: fullfilteredStatus,
        filteredStatus: filteredStatus
      }
    },
    placementPagination(state, data) {
      let filteredStatus = [...state.filteredStatus]
      const isStatusData = [...state.isStatusData]
      if (data) {
        filteredStatus = isStatusData.slice(data.start, data.stop)
      }
      return {
        ...state,
        loading: false,
        filteredStatus: filteredStatus
      }
    },
    onPresenceOptOutSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onPresenceOptInSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    placementDuplicatePagination(state, data) {
      const yextDuplicates = [...state.yextDuplicates]
      const filteredDuplicates = yextDuplicates.slice(data.start, data.stop)

      return {
        ...state,
        loading: false,
        filteredDuplicates: filteredDuplicates
      }
    },
    onYextSuppressSuccess(state, payload, msg) {
      if (msg) {
        notification('success', msg)
      }
      let yextDuplicates = [...state.yextDuplicates]
      yextDuplicates = yextDuplicates.map((data) => {
        if (payload.id === data.id) data.status = 'Suppression requested'
        return data
      })

      return {
        ...state,
        loading: false,
        yextDuplicates: yextDuplicates
      }
    },
    onYextDeleteSuccess(state, payload, msg) {
      if (msg) {
        notification('success', msg)
      }
      let yextDuplicates = [...state.yextDuplicates]
      yextDuplicates = yextDuplicates.filter((data) => {
        return data.id !== payload.id
      })
      return {
        ...state,
        loading: false,
        yextDuplicates: yextDuplicates
      }
    },
    onSaveOfferSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      const userBusinessSettings = { ...state.userBusinessSettings }
      userBusinessSettings.placement_services = data && data.data.placement_services
      return {
        ...state,
        loading: false,
        userBusinessSettings: userBusinessSettings
      }
    },
    onUpdateMediaPhotoNameSuccess(state) {
      notification('success', 'Photo description successfully updated')
      return {
        ...state,
        loading: false
      }
    },
    onbusinessSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        businessSettings: data && data.location ? data.location : data,
        loading: false,
        apiError: false
      }
    },
    predefinedTemplatesSuccess(state, data) {
      return {
        ...state,
        locationPredefinedTemplates: data,
        loading: false,
        apiError: false
      }
    },
    fetchGositeGMBLocationsSuccess(state, data) {
      return {
        ...state,
        gositeGmbLocations: data
      }
    },
    resubmitYextSuccess(state, data) {
      notification('success', 'Resubmitted successfully')
      return {
        ...state,
        resubmitYextStatus: true
      }
    },
    getSavedCardSuccess(state, data) {
      return {
        ...state,
        savedCard: data.data
      }
    },
    onGetMultilocationsSuccess(state, data) {
      return {
        ...state,
        multilocations: data
      }
    },
    onActiveInactiveDeleteLocationUpdateSuccess(state, data) {
      if (data.delete) {
        notification('success', 'Location has been deleted successfully')
      } else {
        notification('success', 'Location status has been updated successfully')
      }

      return {
        ...state,
        loading: false
      }
    },
    onActiveInactiveLocationUpdateSuccess(state) {
      notification('success', 'Location status has been updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onSimvolyRequest(state) {
      return {
        ...state,
        simvolySubmitting: true
      }
    },

    onSimvolySuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        simvolySubmitting: false,
        simvolyWebsites: data
      }
    },

    onSimvolyError(state, error) {
      const errorMessage = error.message || 'An error occurred with your website integration request'
      notification('error', capitalize(errorMessage))
      return {
        ...state,
        simvolySubmitting: false,
        apiError: true
      }
    }
  },

  effects: {
    async getLeaderboard() {
      this.onRequest()
      try {
        const res = await service.getLeaderboard()
        this.onGetLeaderboardSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getTableFormat() {
      this.onRequest()
      try {
        const res = await service.getTableFormat()
        this.onGetTableFormatSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async tableLayout(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.tableLayout(payload)
        this.onTableLayoutSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUserData(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getUser(payload)
        this.onGetUserDataSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resetPassword(payload) {
      try {
        const res = await service.resetPassword(payload)
        this.onResetPasswordSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUserAnalytics(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getUserAnalytics(payload)
        this.onGetUserAnalyticsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBusinessType() {
      this.onRequest()
      try {
        const res = await service.getBusinessType()
        this.onGetBusinessTypeSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getProducts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getProducts(payload)
        this.onGetProductsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBetaProducts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getProducts(payload)
        this.onGetBetaProductsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getLocationAccountSettings(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getAccountSettings(payload)
        this.onGetAccountSettingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendQuickLogin(payload) {
      try {
        const res = await service.sendQuickLogin(payload)
        this.onSendQuickLoginSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteLocation(payload) {
      try {
        const res = await service.deleteLocation(payload)
        this.onDeleteLocationSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async customerInfoUpdate(payload) {
      try {
        const res = await service.customerInfoUpdate(payload)
        this.onCustomerInfoUpdateSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteUsersGositeData() {
      try {
        const res = await service.deleteUserData()
        this.onDeleteUserDataSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUserActivities(payload) {
      try {
        const res = await service.getUserActivities(payload)
        this.onGetUserActivitiesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getPlatformUrl(payload) {
      try {
        const res = await service.getPlatformUrl(payload)
        this.onGetPlatformUrlSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async flushUserActivityData() {
      try {
        this.clearUserActivityData()
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async loginWithEmail(payload) {
      try {
        const res = await service.loginWithEmail(payload)
        this.onLoginWithEmailSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async republishWebsites() {
      try {
        const res = await service.republishWebsites()
        this.onRepublishWebsitesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async importLocationsFromCSV(payload) {
      try {
        const res = await service.importLocationsFromCSV(payload)
        this.onImportLocationsFromCSVSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async onBatchUpdate(payload) {
      try {
        const res = await service.onBatchUpdate(payload)
        this.onBatchUpdateSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getEnterPriceUserApiData(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getEnterPriceUserApiData(payload)
        this.onSuccessGetEnterPriceUserApiData(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async enableApiConnect(payload) {
      try {
        const res = await service.enableApiConnect(payload)
        this.onEnableApiConnectSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateAccountSettings(payload) {
      try {
        const res = await service.updateAccountSettings(payload)
        this.onUpdateAccountSettingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSocialConnections(payload, rootState) {
      try {
        const res = await service.getSocialConnections(payload)
        this.onGetSocialConnectionsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async loadBusinessSettings(payload, rootState) {
      try {
        const res = await service.loadBusinessSettings(payload)
        this.onLoadBusinessSettingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateUserBusinessSettings(payload) {
      try {
        const type = payload.type
        let res
        if (payload._id) {
          res = await service.updateUserBusinessSettings(payload)
        } else {
          res = await service.createUserBusinessSettings(payload)
        }
        this.onUpdateBusinessSettingsSuccess(res, type ? getMessages(type) : null)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async UpdateSubscriptionInfo(payload, rootState) {
      try {
        const res = await service.UpdateSubscriptionInfo(payload)
        this.onUpdateSubscriptionInfoSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async loadAllState(payload) {
      try {
        const res = await service.getUsStates(payload)
        this.onGeStatesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSmsNumber(payload) {
      try {
        const res = await service.getSmsNumber(payload)
        this.onSmsNumberSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendAreaCode(payload) {
      try {
        const res = await service.sendAreaCode(payload)
        this.onSendAreaCodeSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteAreaCode(payload) {
      try {
        const res = await service.deleteAreaCode(payload)
        this.onDeleteAreaCodeSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async paymentCardConnect(payload, rootState, type) {
      this.onRequest()
      try {
        const res = await service.paymentCardConnect(payload, type)
        this.onRequestSuccess({ res: res, message: res.message, show: true })
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUserGmbAccounts(payload, rootState) {
      try {
        const res = await service.getGmbAccounts(payload)
        this.onGetGmbAccountsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getGmbLocations(payload, rootState) {
      try {
        const res = await service.getGmbLocations(payload)
        this.onGetGmbLocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async userfacebookSync(payload, rootState) {
      try {
        const res = await service.userfacebookSync(payload)
        this.onSyncSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async userfacebookUnSync(payload, rootState) {
      try {
        const res = await service.userfacebookUnSync(payload)
        this.onSyncSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async userGmbUnSync(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.gmbUnSync(payload)
        this.onSuccess(res, 'Google account unsynced successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async userGmbUnSyncEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.gmbUnSyncEnterprise(payload)
        this.onSuccess(res, 'Google account unsynced successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getLocationCategories(payload, rootState) {
      try {
        const res = await service.getLocationCategories(payload)
        this.onGetLocationCategoriesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getLocationCategoriesSecondary(payload, rootState) {
      try {
        const res = await service.getLocationCategories(payload)
        this.ongetLocationCategoriesSecondarySuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async onDownloadActivities(payload, rootState) {
      try {
        const res = await service.onDownloadActivities(payload)
        this.onRequestSuccess({
          res: res,
          message: 'We are processing your request. An email will be sent to you soon',
          show: true
        })
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async onSendPushNotification(payload, rootState) {
      try {
        const res = await service.onSendPushNotification(payload)
        this.onRequestSuccess({ res: res, message: 'Notification send successfully', show: true })
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteMediaPhoto(payload) {
      this.onRequest()
      try {
        const res = await service.deleteMediaPhoto(payload)
        this.onDeleteMediaPhotoSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteMediaVideo(payload) {
      this.onRequest()
      try {
        const res = await service.deleteMediaVideo(payload)
        this.onDeleteMediaVideoSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async bulkUpdateLocations(payload, rootState) {
      try {
        const res = await service.bulkUpdateLocations(payload)
        this.onbulkUpdateLocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async bulkUpdateLocationsV2(payload, rootState) {
      try {
        const res = await service.bulkUpdateLocationsV2(payload)
        this.onbulkUpdateLocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async gmbSync(payload, rootState) {
      try {
        const res = await service.gmbSync(payload)
        this.onSuccess(res, 'Your Google account has been synced successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async gmbImport(payload, rootState) {
      try {
        const res = await service.gmbImport(payload)
        this.onSuccess(res, 'Your Google account has been linked successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createYextAccount(payload, rootState) {
      try {
        const res = await service.createYextAccount(payload)
        this.onCreateYextAccountSuccess(res)
        return res
      } catch (e) {
        this.oncreateYextAccountError(e)
      }
    },
    async syncYext(payload, rootState) {
      try {
        const res = await service.syncYext(payload)
        this.onSyncYextSuccess(res)
        return res
      } catch (e) {
        this.onError(
          e && e.data && e.data.error
            ? Array.isArray(e.data.error)
              ? { message: e.data.error[0].message }
              : e.data
            : e && e.data && e.data.error && e.data.error[0]
            ? e.data.error[0]
            : ''
        )
      }
    },
    async getOffers(payload, rootState) {
      try {
        const res = await service.getOffers(payload)
        this.onGetOffersSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async unsyncYext(payload, rootState) {
      try {
        const res = await service.unsyncYext(payload)
        this.onUnsyncYextSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getMenuLocations(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getMenuLocations(payload)
        this.ongetMenuLocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addSectionitem(payload, data) {
      this.onRequest()
      try {
        const res = await service.addSectionitem(payload)
        this.onaddSectionitemSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateSectionitem(payload, data) {
      this.onRequest()
      try {
        const res = await service.updateSectionitem(payload)
        this.onSuccess(res, 'Menu Section updated')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getMenuOptions(payload) {
      this.onRequest()
      try {
        const res = await service.getMenuOptions(payload)
        this.onGetMenuOptionsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createMenuItem(payload) {
      this.onRequest()
      try {
        const res = await service.createMenuItem(payload)
        this.onSuccess(res, 'Menu Item added')

        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateMenuItem(payload) {
      this.onRequest()
      try {
        const res = await service.updateMenuItem(payload)
        this.onSuccess(res, 'Menu Item updated')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteMenuItem(payload) {
      this.onRequest()
      try {
        const res = await service.deleteMenuItem(payload)
        this.onSuccess(res, 'Menu Item Deleted')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteMenuSection(payload) {
      this.onRequest()
      try {
        const res = await service.deleteMenuSection(payload)
        this.onSuccess(res, 'Menu Section deleted')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createMenu(payload) {
      this.onRequest()
      try {
        const res = await service.createMenu(payload)
        this.onSuccess(res, 'Menu Added')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editMenu(payload) {
      this.onRequest()
      try {
        const res = await service.editMenu(payload)
        this.onSuccess(res, 'Menu Updated')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteMenu(payload) {
      this.onRequest()
      try {
        const res = await service.deleteMenu(payload)
        this.onSuccess(res, 'Menu deleted')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async reorderMenuItem(payload) {
      this.onRequest()
      try {
        const res = await service.reorderMenuItem(payload)
        this.onSuccess(res, 'Updated menu order')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getPresenceLocation(payload) {
      this.onRequest()
      try {
        const res = await service.getPresenceLocation(payload)
        this.onGetPresenceLocationSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getConfigFlag(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getConfigFlag(payload)
        this.onfetchConfigFlag(res.achPaymentFeatureFlag)
        return res.achPaymentFeatureFlag
      } catch (e) {
        await this.onError(e)
      }
    },
    async checkYextDuplicate(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.checkYextDuplicate(payload)
        this.onCheckYextDuplicateSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updatePlacementPagination(payload) {
      try {
        const res = this.placementPagination(payload)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async presenceOptOut(payload) {
      this.onRequest()
      try {
        const res = await service.presenceOptOut(payload)
        this.onPresenceOptOutSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async presenceOptIn(payload) {
      this.onRequest()
      try {
        const res = await service.presenceOptIn(payload)
        this.onPresenceOptInSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateDuplicatePagination(payload) {
      try {
        const res = this.placementDuplicatePagination(payload)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async yextSuppress(payload) {
      this.onRequest()
      try {
        const res = await service.yextSuppress(payload)
        this.onYextSuppressSuccess(payload, 'Successfully shared your request to suppress')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async yextDelete(payload) {
      this.onRequest()
      try {
        const res = await service.yextDelete(payload)
        this.onYextDeleteSuccess(payload, 'Successfully updated')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addOffer(payload) {
      this.onRequest()
      try {
        const res = await service.addOffer(payload)
        this.onSaveOfferSuccess(res, 'Successfully updated')
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async removeOffer(payload) {
      this.onRequest()
      try {
        const res = await service.removeOffer(payload)
        this.onSaveOfferSuccess(res, 'Successfully updated')
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async uploadUserBusinessPhoto(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.uploadUserBusinessPhoto(payload)
        this.onLoadBusinessSettingsSuccess({ data: res }, 'Photo uploaded successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSavedFilters(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSavedFilters(payload)
        this.ongetSavedFiltersSUccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async onSaveFilter(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.onSaveFilter(payload)
        this.ononSaveFilterSUccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async onUpdateFilter(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.onUpdateFilter(payload)
        this.ononUpdateFilterSUccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteSavedFilter(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteSavedFilter(payload)
        this.onSuccess(res, 'Filter has been deleted successfully')
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async updateEnterpriseMediaPhotoName(payload) {
      this.onRequest()
      try {
        const res = await service.updateMediaPhotoName(payload)
        this.onUpdateMediaPhotoNameSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sortBusinessPhotos(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sortPhotos(payload)
        this.onbusinessSuccess(res, 'Photo reordered successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getlocationPredefinedTemplates(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getlocationPredefinedTemplates(payload)
        this.predefinedTemplatesSuccess(res.data)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async fetchGositeGMBLocations(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchGositeGMBLocations(payload)
        this.fetchGositeGMBLocationsSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async syncEnterpriseGMBPhoto(payload) {
      try {
        const res = await service.syncEnterpriseGMBPhoto(payload)
        this.onSuccess(res, 'Photo will be synced shortly')
        return res
      } catch (e) {
        // this.onError(e);
      }
    },
    async moveLocation(payload) {
      this.onRequest()
      try {
        const res = await service.moveLocation(payload)
        this.onSuccess(res, 'Location has been moved successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resubmitYext(payload) {
      try {
        const res = await service.resubmitYext(payload)
        this.resubmitYextSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSavedCard() {
      try {
        const res = await service.getSavedCard()
        this.getSavedCardSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getMultilocations(payload) {
      try {
        const res = await service.getMultilocations(payload)
        this.onGetMultilocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async activeInactiveDeleteLocation(payload) {
      try {
        const res = await service.activeInactiveDeleteLocationApi(payload)
        this.onActiveInactiveDeleteLocationUpdateSuccess(payload.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async activeInactiveLocationUpdate(payload) {
      try {
        const res = await service.activeInactiveLocationUpdate(payload)
        this.onActiveInactiveLocationUpdateSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async mapOrUpdateSimvolyWebsite(payload) {
      this.onSimvolyRequest()
      try {
        const res = await service.mapOrUpdateSimvolyWebsite(payload)
        this.onSimvolySuccess([res.data], 'Simvoly website added successfully')
        return res
      } catch (e) {
        this.onSimvolyError(e)
      }
    },

    async deleteSimvolyWebsite(payload) {
      this.onSimvolyRequest()
      try {
        console.log('[LocationEdit > deleteSimvolyWebsite] payload', payload)
        const res = await service.deleteSimvolyWebsite(payload)
        this.onSimvolySuccess([], 'Simvoly website deleted successfully')
        return res
      } catch (e) {
        this.onSimvolyError(e)
      }
    },

    async getSimvolyWebsites(payload) {
      this.onSimvolyRequest()
      try {
        const res = await service.getSimvolyWebsites(payload)
        this.onSimvolySuccess(res.data)
        return res
      } catch (e) {
        this.onSimvolyError(e)
      }
    }
  }
}
