import React, { PureComponent, Fragment } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Popup from '@components/popups/basic-modal'
import AsyncCreatableSelect from 'react-select/async-creatable'
import 'react-datepicker/dist/react-datepicker.css'
import style from '../index.module.scss'
import './send-invoice-popup.scss'
import { isEnabled, phoneNumberFormat, validateEmail, validateMobile } from '@helpers/utility'
import Icon from '@components/icons'
import notification from '@components/notification'
import { numberMask } from '@helpers/inputmask'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Tooltip from '@components/tooltip'
import { segmentEventTrack } from '@helpers/segment'
import { USDOLLAR } from '../constants'
import Dragula from 'react-dragula'
import {
  customOptions,
  createNewVisible,
  customOptionsService,
  paymentStatusListForDeclinedAndSubmited
} from './invoice-popup-helpers'
import 'dragula/dist/dragula.css'
import { TextInstruction } from '@components/textInstruction'
import config from '@config/index'
import _ from 'lodash'

import { Box } from '@gositeinc/ui'
import { withTranslation } from 'react-i18next'

let isFormChanged = false

class SendInvoicePopup extends PureComponent {
  INVOICE_DUE_DATE_VALUES = ['upon receipt', '1 week', '2 weeks', '30 days', 'end of month']

  constructor(props) {
    super(props)
    this.state = {
      hasContact: false,
      editSendType: false,
      editDescription: false,
      isEmailChanged: false,
      isMobileChanged: false,
      isNameChanged: false,
      editScheduledDate: false,
      dueSelectedType: '',
      selectedContact: '',
      customName: '',
      selectedValue: '',
      paymentAllowed: false,
      payment_type: '',
      showReminder: false,
      invoice: {
        customer_id: '',
        customer_first_name: '',
        customer_last_name: '',
        customer_email: '',
        customer_phone: '',
        invoice_amount: 0,
        invoice_frequency: 'single',
        invoice_items: [],
        invoice_number: '',
        send_email: false,
        send_sms: false,
        send_type: 'immediatly',
        reminder: false,
        invoice_due: 'upon receipt',
        scheduled_date: '',
        source: 'direct',
        photo: '',
        collect_payments: true,
        bank_transfer: true,
        utc_offset: moment().format('Z')
      }
    }
  }

  async componentDidMount() {
    const me = this.props.me || {}
    const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
    const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
    const twilioEnabled = hasTwilioAssigned || isTrialUser

    let ErrorMessageSendVia = this.props.t(
      'payments.sms-service-is-not-enabled-in-your-account-try-with-an-email-instead'
    )
    if (twilioEnabled) {
      ErrorMessageSendVia = this.props.t('payments.please-select-email-or-sms-checkbox')
    }

    let paymentAllowed = !!(
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.payment &&
      this.props.me.account_settings.payment.merchant_id
    )

    const payment_type = this.props.me && this.props.me.account_settings && this.props.me.account_settings.payment_type

    if (payment_type === 'stripe') {
      paymentAllowed = this.props.paymentStatus === 'tested'
    }

    this.setState({ twilioEnabled, ErrorMessageSendVia, paymentAllowed, payment_type })

    if (this.props.invoiceItem) {
      const obj = Object.assign(this.state.invoice)
      obj.customer_id = this.props.invoiceItem.customer_id
      obj.customer_first_name = this.props.invoiceItem.customer_first_name
      obj.customer_last_name = this.props.invoiceItem.customer_last_name
      obj.customer_email = this.props.invoiceItem.customer_email

      // assigned to false to not show that part of the address (mustache in html)
      obj.customer_address =
        this.props.invoiceItem.customer_address === '' ? false : this.props.invoiceItem.customer_address
      obj.customer_address2 =
        this.props.invoiceItem.customer_address2 === '' ? false : this.props.invoiceItem.customer_address2
      obj.customer_city = this.props.invoiceItem.customer_city === '' ? false : this.props.invoiceItem.customer_city
      obj.customer_state = this.props.invoiceItem.customer_state === '' ? false : this.props.invoiceItem.customer_state
      obj.customer_zip = this.props.invoiceItem.customer_zip === '' ? false : this.props.invoiceItem.customer_zip

      obj.customer_phone = this.props.invoiceItem.customer_phone
        ? phoneNumberFormat(this.props.invoiceItem.customer_phone)
        : ''
      obj.invoice_amount = this.props.invoiceItem.invoice_amount
      obj.send_email = this.props.invoiceItem.send_email
      obj.send_sms = this.props.invoiceItem.send_sms
      obj.send_type = this.props.invoiceItem.send_type
      obj.reminder = this.props.invoiceItem.reminder
      obj.invoice_due = this.props.invoiceItem.invoice_due
        ? moment(this.props.invoiceItem.invoice_due).format('MM/DD/yyyy')
        : 'upon receipt'
      obj.scheduled_date = this.props.invoiceItem.scheduled_date
        ? moment(this.props.invoiceItem.scheduled_date).format('MM/DD/yyyy')
        : ''
      obj.invoice_items = this.props.invoiceItem.invoice_items
      obj.invoice_description = this.props.invoiceItem.invoice_description
      obj.invoice_title = this.props.invoiceItem.invoice_title
      obj.invoice_number = this.props.invoiceItem.invoice_number
      obj.invoice_id = this.props.invoiceItem.id
      obj.collect_payments = paymentAllowed
      obj.bank_transfer = paymentAllowed
      this.setState({ hasContact: true, invoice: obj })
    } else if (this.props.isMessenger) {
      console.log('here...')
      const obj = Object.assign({ ...this.state.invoice, ...this.props.contact })
      obj.collect_payments = paymentAllowed
      obj.bank_transfer = paymentAllowed
      this.setState({ hasContact: true, invoice: obj })
    } else {
      const obj = Object.assign(this.state.invoice)
      obj.collect_payments = paymentAllowed
      obj.bank_transfer = paymentAllowed
      this.setState({ invoice: obj })
    }

    const popupInvoiceElem = document.querySelector('#modalSendInvoiceLink')
    const self = this
    popupInvoiceElem.addEventListener('click', function (e) {
      if (!e.target.closest('.send-via-value')) {
        self.setState({ editSendType: false })
      }
      if (!e.target.closest('.send-immeediatly-value')) {
        self.setState({ editSendDate: false, editScheduledDate: false })
      }
      if (!e.target.closest('.due-date-value')) {
        self.setState({ editDueDate: false })
      }
    })
  }

  updateInvoiceForm({ newInvoice, shouldDisableContactInputs = false, shouldDisableItems = false }) {
    const me = this.props.me || {}
    const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
    const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
    const twilioEnabled = hasTwilioAssigned || isTrialUser

    let ErrorMessageSendVia = this.props.t(
      'payments.sms-service-is-not-enabled-in-your-account-try-with-an-email-instead'
    )
    if (twilioEnabled) {
      ErrorMessageSendVia = this.props.t('payments.please-select-email-or-sms-checkbox')
    }

    let paymentAllowed = !!(
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.payment &&
      this.props.me.account_settings.payment.merchant_id
    )

    const payment_type = this.props.me && this.props.me.account_settings && this.props.me.account_settings.payment_type

    if (payment_type === 'stripe') {
      paymentAllowed = this.props.paymentStatus === 'tested'
    }

    this.setState({
      twilioEnabled,
      ErrorMessageSendVia,
      paymentAllowed,
      payment_type,
      hasContact: true,
      shouldDisableItems,
      shouldDisableContactInputs,
      invoice: {
        ...this.state.invoice,
        estimateId: newInvoice.estimateId,
        customer_id: newInvoice.customer_id,
        customer_first_name: newInvoice.customer_first_name,
        customer_last_name: newInvoice.customer_last_name,
        customer_email: newInvoice.customer_email,
        customer_address: newInvoice.customer_address === '' ? false : newInvoice.customer_address,
        customer_address2: newInvoice.customer_address2 === '' ? false : newInvoice.customer_address2,
        customer_city: newInvoice.customer_city === '' ? false : newInvoice.customer_city,
        customer_state: newInvoice.customer_state === '' ? false : newInvoice.customer_state,
        customer_zip: newInvoice.customer_zip === '' ? false : newInvoice.customer_zip,
        customer_phone: newInvoice.customer_phone ? phoneNumberFormat(newInvoice.customer_phone) : '',
        invoice_amount: newInvoice.invoice_amount,
        send_email: newInvoice.send_email,
        send_sms: newInvoice.send_sms && this.state.twilioEnabled,
        send_type: newInvoice.send_type,
        reminder: newInvoice.reminder,
        invoice_due: newInvoice.invoice_due ? moment(newInvoice.invoice_due).format('MM/DD/yyyy') : 'upon receipt',
        scheduled_date: newInvoice.scheduled_date ? moment(newInvoice.scheduled_date).format('MM/DD/yyyy') : '',
        invoice_items: newInvoice.invoice_items,
        invoice_description: newInvoice.invoice_description,
        invoice_title: newInvoice.invoice_title,
        invoice_number: newInvoice.invoice_number,
        invoice_id: newInvoice.id,
        wisetack_transaction: newInvoice.invoice_wisetack_transaction,
        collect_payments: paymentAllowed,
        bank_transfer: paymentAllowed
      }
    })
  }

  dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      const options = {
        moves: function (el, container, handle) {
          return handle.classList.contains('invoice-row-handle')
        }
      }
      Dragula([componentBackingInstance], options)
        .on('drag', function (e, el) {
          setTimeout(function () {
            const findElem = document.querySelector('.gu-mirror')
            if (findElem) {
              findElem.classList.add('gu-mirror-invoice-table-row')
            }
          }, 20)
        })
        .on('drop', (e, el, target, source) => this.swapInvoiceItemArray(e, el, target, source))
    }
  }
  async swapInvoiceItemArray(e, el, target, source) {
    if (target) {
      const indexArray = []
      const elements = target.querySelectorAll('tr')
      for (let i = 0; i < elements.length; i++) {
        indexArray.push(elements[i].getAttribute('id'))
      }
      const obj = Object.assign(this.state.invoice)
      obj.invoice_items = indexArray.map((i) => obj.invoice_items[i])
      for (let i = 0; i < elements.length; i++) {
        indexArray.push(elements[i].setAttribute('id', i))
      }
      await this.setState({ invoice: obj })
    }
  }
  closeModal() {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }
  handleCancel(values, { resetForm }) {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }
  async handleSubmit(values, { resetForm }) {
    let flag = true
    const obj = Object.assign(this.state.invoice)
    if (obj.invoice_items.length) {
      obj.invoice_items.forEach((item) => {
        if (item && (item.service_name === '' || item.service_price === 0 || item.service_price === '')) {
          flag = false
          notification('error', this.props.t('payments.required-item-name-and-rate'))
        }
      })
    }
    if (flag) {
      this.segmentTracking(obj.invoice_items)
      if (
        this.state.invoice &&
        this.state.invoice.send_type === 'schedule' &&
        this.state.invoice.invoice_due === 'upon receipt'
      ) {
        obj.invoice_due = this.state.invoice.scheduled_date
      } else if (
        this.state.invoice &&
        this.state.invoice.send_type === 'immediatly' &&
        this.state.invoice.invoice_due === 'upon receipt'
      ) {
        obj.invoice_due = moment()
      }
      if (this.state.invoice.customer_email && this.state.selectedContact) {
        if (this.state.invoice.customer_email !== this.state.selectedContact.email) {
          obj.isEmailChanged = true
        }
      } else if (
        this.state.invoice.customer_email &&
        !this.state.selectedContact &&
        this.props.privateContact &&
        this.props.privateContact.data
      ) {
        if (
          this.state.invoice.customer_email !== this.props.privateContact.data.email ||
          !this.props.privateContact.data.email
        ) {
          obj.isEmailChanged = true
        }
      }
      if (this.state.invoice.customer_phone && this.state.selectedContact) {
        if (this.state.invoice.customer_phone !== phoneNumberFormat(this.state.selectedContact.mobile)) {
          obj.isMobileChanged = true
        }
      } else if (
        this.state.invoice.customer_phone &&
        !this.state.selectedContact &&
        this.props.privateContact &&
        this.props.privateContact.data
      ) {
        if (
          this.state.invoice.customer_phone !== phoneNumberFormat(this.props.privateContact.data.mobile) ||
          !this.props.privateContact.data.mobile
        ) {
          obj.isMobileChanged = true
        }
      }
      if (obj.invoice_items.length) {
        obj.invoice_items.forEach((item, index) => {
          if (item) {
            item.sort_order = index + 1
          }
        })
      }
      if (this.state.customName) {
        const name = this.state.customName.split(' ')
        obj.customer_first_name = name[0]
        if (name.length > 1) {
          obj.customer_last_name = this.state.customName.replace(name[0], '')
        }
        obj.isNameChanged = true
      }
      this.setState({ invoice: obj })
      await this.props.confirmInvoice(this.state.invoice)
    }
  }
  segmentTracking(items) {
    let segmentData = {}
    segmentData = {
      event: 'invoices_add_item',
      properties: {
        user_id: this.props.me._id,
        role: this.props.me.role_id.role_name,
        step: 'NewInvoice-SelectItem'
      }
    }
    if (this.props.me.primaryCategories && this.props.me.primaryCategories.category_name) {
      segmentData.properties.business_category = this.props.me.primaryCategories.category_name
    }
    segmentData.properties.items = []
    let itemObj = {}

    let containsCustom = false

    let containsService = false
    items.forEach((item) => {
      itemObj = {
        name: item.service_name,
        price: parseFloat(item.service_price),
        type: item.service_id ? 'existing' : 'custom'
      }
      segmentData.properties.items.push(itemObj)
      if (item.service_id) {
        containsService = true
      } else {
        containsCustom = true
      }
    })
    segmentData.properties.contains_custom = containsCustom
    segmentData.properties.contains_service = containsService
    segmentEventTrack(segmentData)
  }

  autoSuggestContacts = async (input) => {
    if (input.length >= 2) {
      const data = {
        nopaging: true,
        status: 'active',
        excludeNullGroup: true,
        search: input || ''
      }
      this.props.products &&
        isEnabled('contact-manager', this.props.products) &&
        (await this.props.autosuggestContacts(data))
      return [
        {
          options: this.props.contactList && this.props.contactList.length > 0 ? this.props.contactList : []
        }
      ]
    }
  }
  autoSuggestServices = async (input) => {
    const data = {
      nopaging: true,
      search: input || '',
      source: 'direct'
    }
    await this.props.autoSuggestServices(data)
    return [
      {
        options: this.props.servicesList && this.props.servicesList.length > 0 ? this.props.servicesList : []
      }
    ]
  }

  async handleSelectOption(setFieldValue, data, field, action, amount) {
    isFormChanged = true
    if (action && action.action === 'clear') {
      this.setState({ hasContact: false, selectedValue: '' })
      setFieldValue(field, '')
    }
    if (data && data.value && data.contactObj) {
      const obj = Object.assign(this.state.invoice)
      obj.customer_first_name = data.contactObj.first_name
      obj.customer_last_name = data.contactObj.last_name
      obj.customer_email = data.contactObj.email
      obj.customer_phone = phoneNumberFormat(data.contactObj.mobile)
      obj.customer_id = data.contactObj.contact_id
      obj.customer_address = data.contactObj.address
      obj.customer_address2 = data.contactObj.address2
      obj.customer_city = data.contactObj.city
      obj.customer_state = data.contactObj.state
      obj.customer_country = data.contactObj.country
      obj.customer_zip = data.contactObj.zip
      obj.photo = data.contactObj.image
      if (obj.customer_email) {
        obj.send_email = true
      }
      if (obj.customer_phone && this.state.twilioEnabled) {
        obj.send_sms = true
      }
      const val = {
        image: data.image,
        label: data.label ? data.label : data.subLabel ? data.subLabel : data.value,
        value: data.value
      }
      this.setState({ hasContact: true, invoice: obj, selectedContact: data.contactObj, selectedValue: val })
      setFieldValue(field, data.value)
    }
    if (
      (action && action.action === 'create-option') ||
      (action && action.action === 'select-option' && !data.contactObj)
    ) {
      const mobileTest = validateMobile(data.value)
      const emailTest = validateEmail(data.value)
      if (data.value && !mobileTest && !emailTest) {
        const me = this.props.me || {}
        const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
        const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
        const twilioEnabled = hasTwilioAssigned || isTrialUser

        const message = twilioEnabled
          ? this.props.t('payments.please-enter-a-valid-email-or-mobile')
          : this.props.t('payments.please-enter-a-valid-email')
        notification('error', message)
        this.setState({ hasContact: false, selectedValue: '' })
        setFieldValue(field, '')
      } else {
        let obj = Object.assign(this.state.invoice)
        let payload = {}
        if (mobileTest) {
          payload = {
            mobile: phoneNumberFormat(data.value),
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          obj.customer_phone = phoneNumberFormat(data.value)
          if (this.state.twilioEnabled) {
            obj.send_sms = true
          }
        }
        if (emailTest) {
          payload = {
            email: data.value,
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          obj.customer_email = data.value
          obj.send_email = true
        }
        const val = {
          label: data.value,
          value: data.value
        }
        this.setState({ hasContact: true, invoice: obj, selectedValue: val })
        await this.props.savePrivateContact(payload)
        obj = Object.assign(this.state.invoice)
        obj.customer_id =
          this.props.privateContact && this.props.privateContact.data && this.props.privateContact.data._id
        this.setState({ invoice: obj })
      }
    }
  }
  async handleSelectOptionService(setFieldValue, data, field, action, index) {
    isFormChanged = true
    if (action && action.action === 'clear') {
      setFieldValue(field, '')
    }
    if (data && data.value && action && action.action !== 'create-option' && !data.__isNew__) {
      setFieldValue(field, data.label)
      const obj = Object.assign(this.state.invoice)
      obj.invoice_items[index].service_name = data.label
      obj.invoice_items[index].service_id = data.value
      if (data.subLabel) {
        obj.invoice_items[index].service_price = data.subLabel
        if (obj.invoice_items[index].service_quantity) {
          obj.invoice_items[index].service_price_total =
            obj.invoice_items[index].service_quantity * obj.invoice_items[index].service_price
        } else {
          obj.invoice_items[index].service_price_total = data.subLabel
        }
        obj.invoice_items[index].isPayment = true
      } else {
        obj.invoice_items[index].service_price = ''
        obj.invoice_items[index].isPayment = false
      }
      obj.invoice_items[index].service_status = 'active'
      this.setState({ invoice: obj })
    }
    if (
      (action && action.action === 'create-option') ||
      (action && action.action === 'select-option' && data.__isNew__)
    ) {
      if (data) {
        setFieldValue(field, data.value)
        const obj = Object.assign(this.state.invoice)
        obj.invoice_items[index].service_name = data.value
        obj.invoice_items[index].service_id = ''
        obj.invoice_items[index].service_price = ''
        obj.invoice_items[index].service_status = 'open'
        this.setState({ invoice: obj })
      }
    }
  }
  async editContact() {
    const obj = Object.assign(this.state.invoice)
    obj.customer_email = ''
    obj.customer_first_name = ''
    obj.customer_last_name = ''
    obj.customer_id = ''
    obj.customer_phone = ''
    obj.send_email = false
    obj.send_sms = false
    obj.photo = ''
    this.setState({
      hasContact: false,
      invoice: obj,
      editSendType: false,
      selectedContact: '',
      customName: '',
      selectedValue: ''
    })
  }
  async updateDueDate(type, setFieldValue) {
    const obj = { ...this.state.invoice }
    let invoiceDate = ''
    if (_.get(this.state.invoice, 'send_type') === 'immediatly') {
      invoiceDate = moment().format('MM/DD/yyyy')
    } else {
      invoiceDate = this.state.invoice.scheduled_date
    }

    switch (type) {
      case this.INVOICE_DUE_DATE_VALUES[0]:
        obj.invoice_due = 'upon receipt'
        obj.reminder = false
        setFieldValue('dueDate', '')
        break
      case this.INVOICE_DUE_DATE_VALUES[1]:
        obj.invoice_due = moment(invoiceDate).add(7, 'days').format('MM/DD/yyyy')
        obj.reminder = true
        setFieldValue('dueDate', new Date(obj.invoice_due))
        break
      case this.INVOICE_DUE_DATE_VALUES[2]:
        obj.invoice_due = moment(invoiceDate).add(14, 'days').format('MM/DD/yyyy')
        obj.reminder = true
        setFieldValue('dueDate', new Date(obj.invoice_due))
        break
      case this.INVOICE_DUE_DATE_VALUES[3]:
        obj.invoice_due = moment(invoiceDate).add(30, 'days').format('MM/DD/yyyy')
        obj.reminder = true
        setFieldValue('dueDate', new Date(obj.invoice_due))
        break
      case this.INVOICE_DUE_DATE_VALUES[4]:
        obj.invoice_due = moment(invoiceDate).endOf('month').format('MM/DD/yyyy')
        obj.reminder = true
        setFieldValue('dueDate', new Date(obj.invoice_due))
        break
      default:
        break
    }

    this.setState({
      invoice: obj,
      editDueDate: false,
      dueSelectedType: type,
      showReminder: type !== 'upon receipt'
    })
  }
  async updateSendDate(type) {
    const obj = Object.assign(this.state.invoice)
    if (type === 'Immediatly') {
      obj.send_type = 'immediatly'
      obj.scheduled_date = ''
      this.setState({ invoice: obj, editSendDate: false })
    } else {
      this.setState({ editSendDate: false, editScheduledDate: true })
    }
  }
  onRemoveItem(setFieldValue, values, index) {
    const keyArray = [...values.invoice_items]
    keyArray.splice(index, 1)
    setFieldValue('invoice_items', keyArray)
    const obj = Object.assign(this.state.invoice)
    obj.invoice_items.splice(index, 1)
    this.setState({ invoice: obj })
  }
  onAddItem(setFieldValue, values) {
    isFormChanged = true
    const keyArray = [...values.invoice_items]
    const key = {
      service_id: '',
      service_name: '',
      service_price: 0,
      service_description: '',
      service_price_total: 0,
      service_quantity: 1,
      service_status: 'active'
    }
    keyArray.push(key)
    const obj = Object.assign(this.state.invoice)
    obj.invoice_items.push(key)
    this.setState({ invoice: obj })
    setFieldValue('invoice_items', keyArray)
  }
  calculateTotal() {
    let total = 0
    this.state.invoice.invoice_items &&
      this.state.invoice.invoice_items.map((item, index) => {
        if (item && item.service_price_total) {
          total += item.service_price_total
        }
        return total
      })
    const obj = Object.assign(this.state.invoice)
    obj.invoice_amount = total
    if (total > 0) {
      return total
    } else {
      return 0
    }
  }
  setOriginalValues(setFieldValue, type) {
    const obj = Object.assign(this.state.invoice)
    if (type === 'email') {
      setFieldValue('email', this.state.selectedContact.email)
      obj.customer_email = this.state.selectedContact.email
    } else if (type === 'mobile') {
      setFieldValue('mobile', phoneNumberFormat(this.state.selectedContact.mobile))
      obj.customer_phone = phoneNumberFormat(this.state.selectedContact.mobile)
    }
    this.setState({ invoice: obj })
  }
  editDescriptionBtnClick(item, index) {
    if (!item.service_description && this.state.editDescription && this.state.currentIndex === index) {
      this.setState({ editDescription: false })
    } else {
      this.setState({ editDescription: true, currentIndex: index })
    }
    window.$('#serviceDescription_' + index).focus()
  }
  render() {
    const t = this.props.t

    const invoiceDueDateLabelsMap = {
      'upon receipt': t('payments.upon-receipt-capitalized'),
      '1 week': t('common.1-week'),
      '2 weeks': t('common.n-weeks', [2]),
      '30 days': t('common.n-days', [30]),
      'end of month': t('common.end-of-month')
    }

    return (
      <React.Fragment>
        <Popup
          open={this.props.open}
          title={this.props.invoiceItem ? t('payments.update-invoice') : t('payments.send-invoice')}
          onCancel={() => this.closeModal.bind(this)}
          type="medium"
          modalBackdropClicked={() => console.log(null)}
          id="modalSendInvoiceLink"
          customClass="modal-send-invoice">
          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
              email: this.state.invoice && this.state.invoice.customer_email ? this.state.invoice.customer_email : '',
              mobile: this.state.invoice && this.state.invoice.customer_phone ? this.state.invoice.customer_phone : '',
              sendSMS: this.state.invoice && this.state.invoice.send_sms ? this.state.invoice.send_sms : false,
              sendEMail: this.state.invoice && this.state.invoice.send_email ? this.state.invoice.send_email : false,
              reminder: this.state.invoice && this.state.invoice.reminder,
              invoice_items:
                this.state.invoice.invoice_items &&
                Object.keys(this.state.invoice.invoice_items) !== 0 &&
                this.state.invoice.invoice_items
                  ? this.state.invoice.invoice_items
                  : '',
              sendDate:
                this.state.invoice && this.state.invoice.scheduled_date
                  ? new Date(this.state.invoice.scheduled_date)
                  : '',
              dueDate:
                this.state.invoice &&
                this.state.invoice.invoice_due &&
                this.state.invoice.invoice_due !== 'upon receipt'
                  ? new Date(this.state.invoice.invoice_due)
                  : '',
              customername: '',
              contact: '',
              items: '',
              sendVia: '',
              collect_payments: this.state.paymentAllowed,
              bank_transfer: this.state.paymentAllowed
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('payments.please-enter-valid-email')),
              mobile: Yup.string().matches(
                /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/,
                t('payments.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006-0')
              ),
              contact: Yup.string().test('email', t('payments.required'), function (value) {
                const { email, mobile } = this.parent
                if (!mobile && !email) {
                  return value != null
                }
                return true
              }),
              items: Yup.string().test('invoice_items', t('payments.required'), function (value) {
                const invoiceItems = _.get(this.parent, 'invoice_items')
                if (!invoiceItems.length) {
                  return value != null
                }
                return true
              }),
              sendVia: Yup.string().test('sendEMail', `${this.state.ErrorMessageSendVia}`, function (value) {
                const { sendEMail, sendSMS } = this.parent
                if (!sendEMail && !sendSMS) {
                  return value != null
                }
                return true
              })
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form
                  id="frmSendInvoice"
                  name="frmSentInvoice"
                  className={`pt-20 custom-get-paid-form ${style['get-paid-form']}`}>
                  <button
                    name="btninvoiceCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <Box
                    className="row"
                    sx={{
                      '.form-control:disabled': {
                        backgroundColor: 'inherit'
                      }
                    }}>
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                        <label className="label-text">
                          {t('payments.bill-to')} <span className="star">*</span>
                          <ErrorMessage name="contact" component="span" className={'form-error'} />
                          <ErrorMessage name="email" component="span" className="form-error" />
                          <ErrorMessage name="mobile" component="span" className="form-error" />
                        </label>
                        {!this.state.hasContact && (
                          <Fragment>
                            <AsyncCreatableSelect
                              // data-test="send-invoice-contact-field"
                              id="sendInvoiceContact"
                              name="contacts"
                              isClearable
                              placeholder={t('payments.name-phone-or-email')}
                              className="dropdown-select no-arrow-down"
                              classNamePrefix="dropdownSelect"
                              loadOptions={this.autoSuggestContacts}
                              value={
                                this.state.selectedValue
                                  ? {
                                      label: this.state.selectedValue.label,
                                      value: this.state.selectedValue.value,
                                      image: this.state.selectedValue.image
                                    }
                                  : []
                              }
                              formatOptionLabel={customOptions}
                              noOptionsMessage={() => null}
                              onChange={(event, type) =>
                                this.handleSelectOption(setFieldValue, event, 'contacts', type, values.txtAmount)
                              }
                              formatCreateLabel={(userInput) => (
                                <div className="react-select-unavailable-data">
                                  <span className="react-select-highlight-text">
                                    {t('payments.create-new-contact')}
                                  </span>
                                </div>
                              )}
                              isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                createNewVisible(selectOptions)
                              }
                            />
                            {this.props.showInstruction && (
                              <TextInstruction
                                strongMessage={t('payments.start-here')}
                                message={t('payments.who-do-you-need-to-send-an-invoice-to')}
                              />
                            )}
                          </Fragment>
                        )}
                        {this.state.hasContact && (
                          <div className="selected-user border rounded-sm">
                            <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
                              <div className="user-contact pl-15">{t('payments.contact-0')}</div>
                              {this.state.invoice &&
                              !this.state.invoice.customer_first_name &&
                              !this.state.invoice.customer_last_name ? (
                                <React.Fragment>
                                  <div className="user-phone-value pr-25">
                                    <Field
                                      type="text"
                                      name="customername"
                                      className="form-control form-control-lg"
                                      placeholder={'(Optional)'}
                                      value={values.customername}
                                      onChange={(event) => {
                                        setFieldValue('customername', event.target.value)
                                        this.setState({ customName: event.target.value })
                                        isFormChanged = true
                                      }}
                                      disabled={this.state.shouldDisableContactInputs}
                                    />
                                  </div>
                                  {!this.state.shouldDisableContactInputs && (
                                    <div className="position-absolute cursor-pointer right-0 mr-15 icon-block">
                                      <Icon
                                        onClick={this.editContact.bind(this)}
                                        name="closeThin"
                                        className="stroke-icon d-block"
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {this.state.invoice &&
                                    (this.state.invoice.customer_first_name ||
                                      this.state.invoice.customer_last_name) && (
                                      <figure className={`mb-0 mr-10 ml-30 ${style['b-image']}`}>
                                        <img
                                          className={`w-100 rounded-circle ${style['image']}`}
                                          src={
                                            this.state.invoice && this.state.invoice.photo
                                              ? this.state.invoice.photo
                                              : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                          }
                                          alt=""
                                        />
                                      </figure>
                                    )}
                                  <div
                                    className="user-names d-flex justify-content-between"
                                    style={{
                                      width:
                                        this.state.invoice &&
                                        !this.state.invoice.customer_last_name &&
                                        !this.state.invoice.customer_first_name &&
                                        '60%'
                                    }}>
                                    {this.state.invoice && this.state.invoice.customer_first_name && (
                                      <span>{this.state.invoice.customer_first_name}</span>
                                    )}
                                    {this.state.invoice && this.state.invoice.customer_last_name && (
                                      <span className="mr-auto pl-5">{this.state.invoice.customer_last_name}</span>
                                    )}
                                    {!this.state.shouldDisableContactInputs && (
                                      <Icon
                                        onClick={this.editContact.bind(this)}
                                        name="closeThin"
                                        className="stroke-icon"
                                      />
                                    )}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                            <div className="row-user-email border-bottom d-flex align-items-center position-relative">
                              <div className="user-email pl-15">{t('payments.email')}</div>
                              <div className="user-email-value pr-20">
                                <Field
                                  type="text"
                                  name="email"
                                  className="form-control form-control-lg"
                                  placeholder={
                                    this.state.invoice.customer_phone ? '(Optional)' : t('payments.enter-email')
                                  }
                                  value={this.state.invoice.customer_email}
                                  onChange={(event) => {
                                    setFieldValue('email', event.target.value)
                                    const obj = Object.assign(this.state.invoice)
                                    if (event.target.value) {
                                      obj.send_email = true
                                    } else {
                                      obj.send_email = false
                                    }
                                    obj.customer_email = event.target.value
                                    this.setState({ invoice: obj })
                                    isFormChanged = true
                                  }}
                                  disabled={this.state.shouldDisableContactInputs}
                                />
                              </div>
                              {this.state.invoice &&
                              this.state.selectedContact &&
                              this.state.selectedContact.email &&
                              this.state.invoice.customer_email !== this.state.selectedContact.email ? (
                                <div
                                  className=" position-absolute cursor-pointer right-0 mr-15"
                                  onClick={this.setOriginalValues.bind(this, setFieldValue, 'email')}>
                                  <Tooltip position="left" message={t('payments.revert-to-email-on-file')}>
                                    <Icon name="undoThin" className={`${style['undo']}`} />
                                  </Tooltip>
                                </div>
                              ) : null}
                            </div>
                            <div className="row-user-phone  d-flex align-items-center position-relative">
                              <div className="user-phone pl-15">{t('payments.phone')}</div>
                              <div className="user-phone-value pr-20">
                                <Field
                                  type="text"
                                  name="mobile"
                                  className="form-control form-control-lg"
                                  placeholder={
                                    this.state.invoice.customer_email ? '(Optional)' : t('payments.enter-mobile')
                                  }
                                  value={this.state.invoice.customer_phone}
                                  maxLength={14}
                                  onChange={(event) => {
                                    setFieldValue('mobile', numberMask(event.target.value))
                                    const obj = Object.assign(this.state.invoice)
                                    if (event.target.value && this.state.twilioEnabled) {
                                      obj.send_sms = true
                                    } else {
                                      obj.send_sms = false
                                    }
                                    obj.customer_phone = numberMask(event.target.value)
                                    this.setState({ invoice: obj })
                                    isFormChanged = true
                                  }}
                                  disabled={this.state.shouldDisableContactInputs}
                                />
                              </div>
                              {this.state.invoice &&
                              this.state.selectedContact &&
                              this.state.selectedContact.mobile &&
                              this.state.invoice.customer_phone !==
                                phoneNumberFormat(this.state.selectedContact.mobile) ? (
                                <div
                                  className="position-absolute cursor-pointer right-0 mr-15"
                                  onClick={this.setOriginalValues.bind(this, setFieldValue, 'mobile')}>
                                  <Tooltip position="left" message={t('payments.revert-to-phone-on-file')}>
                                    <Icon name="undoThin" className={`${style['undo']}`} />
                                  </Tooltip>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                        <label className="label-text">
                          {t('payments.invoice-details')}
                          <ErrorMessage name="sendVia" component="span" className={'form-error'} />
                        </label>
                        <div className="invoice-detail border rounded-sm">
                          <div className="row-send-via border-bottom d-flex align-items-center">
                            <div className="send-via px-15">
                              {t('payments.send-via')} {this.state.invoice.send_email}
                            </div>
                            <div className="send-via-value position-relative">
                              <div
                                className="send-via-value-item px-15 cursor-pointer d-flex align-items-center justify-content-between "
                                onClick={() =>
                                  this.setState({
                                    editSendType: !this.state.editSendType,
                                    editDueDate: false,
                                    editSendDate: false
                                  })
                                }>
                                {this.state.invoice && this.state.invoice.send_email && this.state.invoice.send_sms ? (
                                  <span>{t('payments.email-and-sms')}</span>
                                ) : null}
                                {this.state.invoice && this.state.invoice.send_email && !this.state.invoice.send_sms ? (
                                  <span>{t('payments.email')}</span>
                                ) : null}
                                {this.state.invoice && !this.state.invoice.send_email && this.state.invoice.send_sms ? (
                                  <span>SMS</span>
                                ) : null}
                                {this.state.invoice &&
                                !this.state.invoice.send_email &&
                                !this.state.invoice.send_sms ? (
                                  <span>{t('payments.select')}</span>
                                ) : null}
                                <div className={`${style['arrow-icon']}`}>
                                  <Icon name="arrowDownThin" />
                                </div>
                              </div>
                              {this.state.editSendType && (
                                <div className="dropdown-send-via-menu position-absolute right-0  bg-white">
                                  <div className="dropdown-send-via-menu-item border-bottom px-15 py-10">
                                    <div className="form-group form-check d-flex m-0">
                                      <label>
                                        <Field
                                          type="checkbox"
                                          className="h-cb"
                                          disabled={!this.state.invoice.customer_phone || !this.state.twilioEnabled}
                                          checked={values.sendSMS}
                                          onChange={(event) => {
                                            setFieldValue('sendSMS', !values.sendSMS)
                                            const obj = Object.assign(this.state.invoice)
                                            obj.send_sms = !values.sendSMS
                                            this.setState({ invoice: obj, editSendType: false })
                                            isFormChanged = true
                                          }}
                                        />
                                        <span
                                          id="frm_addService_chkBookingHours"
                                          className="checkbox-icon mr-10"></span>
                                        SMS
                                      </label>
                                    </div>
                                  </div>
                                  <div className="dropdown-send-via-menu-item px-15 py-10">
                                    <div className="form-group form-check d-flex m-0">
                                      <label>
                                        <Field
                                          type="checkbox"
                                          className="h-cb"
                                          disabled={!this.state.invoice.customer_email}
                                          checked={values.sendEMail}
                                          onChange={(event) => {
                                            setFieldValue('sendEMail', !values.sendEMail)
                                            const obj = Object.assign(this.state.invoice)
                                            obj.send_email = !values.sendEMail
                                            this.setState({ invoice: obj, editSendType: false })
                                            isFormChanged = true
                                          }}
                                        />
                                        <span
                                          id="frm_addService_chkBookingHours"
                                          className="checkbox-icon mr-10"></span>
                                        {t('payments.email')}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row-send-immeediatly border-bottom d-flex align-items-center">
                            <div className="send-immeediatly px-15">{t('payments.send')}</div>
                            <div className="send-immeediatly-value">
                              <div
                                className="px-15 send-immeediatly-value-date d-flex align-items-center justify-content-between"
                                onClick={() =>
                                  this.setState({
                                    editSendDate: !this.state.editSendDate,
                                    editDueDate: false,
                                    editSendType: false
                                  })
                                }>
                                {this.state.invoice.send_type === 'immediatly' ? (
                                  <span>{t('payments.immediately')}</span>
                                ) : (
                                  <span>{this.state.invoice.scheduled_date}</span>
                                )}
                                <div className="arrow-icon">
                                  <Icon name="arrowDownThin" />
                                </div>
                              </div>
                              {this.state.editSendDate ? (
                                <div className="send-immeediatly-calendar react-datepicker-popper">
                                  <div
                                    className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                    onClick={this.updateSendDate.bind(this, 'Immediatly')}>
                                    {t('payments.immediately')}
                                  </div>
                                  <div
                                    className="send-immeediatly-calendar-item px-15 py-10"
                                    onClick={this.updateSendDate.bind(this, 'Scheduled')}>
                                    {t('payments.scheduled')}
                                  </div>
                                </div>
                              ) : null}
                              {this.state.editScheduledDate ? (
                                <div className="send-immeediatly-calendar react-datepicker-popper hide-outside-dates ">
                                  <DatePicker
                                    inline
                                    id="sendInvoice_DatePicker"
                                    selected={values.sendDate}
                                    dateFormat="MM/dd/yyyy"
                                    className="form-control border-0 p-0 datepicker"
                                    name="sendDate"
                                    placeholderText={this.state.invoice.send_type}
                                    minDate={new Date(moment().add('days', 1).format('L'))}
                                    onChange={(event) => {
                                      setFieldValue('sendDate', event)
                                      setFieldValue('dueDate', '')
                                      const obj = Object.assign(this.state.invoice)
                                      obj.scheduled_date = moment(event).format('MM/DD/yyyy')
                                      obj.send_type = 'schedule'
                                      obj.invoice_due = 'upon receipt'
                                      this.setState({ invoice: obj, editScheduledDate: false })
                                      isFormChanged = true
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row-due-date border-bottom d-flex align-items-center">
                            <div className="due-date px-15" onClick={() => this.setState({ editDueDate: true })}>
                              {t('payments.due-date')}
                            </div>
                            <div className="due-date-value">
                              <div
                                className="px-15 due-date-value-date d-flex align-items-center justify-content-between"
                                onClick={() =>
                                  this.setState({
                                    editDueDate: !this.state.editDueDate,
                                    editSendDate: false,
                                    editSendType: false
                                  })
                                }>
                                {this.INVOICE_DUE_DATE_VALUES.includes(this.state.invoice.invoice_due) ||
                                this.INVOICE_DUE_DATE_VALUES.includes(this.state.dueSelectedType)
                                  ? _.capitalize(
                                      this.state.dueSelectedType
                                        ? invoiceDueDateLabelsMap[this.state.dueSelectedType]
                                        : t('payments.upon-receipt')
                                    ) +
                                    ' ' +
                                    (this.state.dueSelectedType.includes('day') ||
                                    this.state.dueSelectedType.includes('week')
                                      ? t('payments.after-receipt')
                                      : '')
                                  : moment(this.state.invoice.invoice_due).format('MM/DD/YYYY')}
                                <div className="arrow-icon">
                                  <Icon name="arrowDownThin" />
                                </div>
                              </div>
                              {this.state.editDueDate ? (
                                <div className="due-date-calendar react-datepicker-popper hide-outside-dates d-sm-flex pt-10 pl-10 pr-15 pb-0">
                                  <DatePicker
                                    inline
                                    id="sendInvoice_DatePicker"
                                    selected={values.dueDate}
                                    dateFormat="MM/dd/yyyy"
                                    className="form-control border-0 p-0 datepicker"
                                    name="dueDate"
                                    placeholderText={t('payments.select-due-date')}
                                    minDate={
                                      this.state.invoice.scheduled_date
                                        ? new Date(this.state.invoice.scheduled_date)
                                        : new Date()
                                    }
                                    onChange={(event) => {
                                      setFieldValue('dueDate', event)
                                      const obj = Object.assign(this.state.invoice)
                                      obj.invoice_due = moment(event).format('MM/DD/yyyy')
                                      this.setState({ invoice: obj, editDueDate: false, dueSelectedType: '' })
                                      isFormChanged = true
                                    }}
                                  />
                                  <div className="calendar-button-block pl-15 pt-5">
                                    {this.INVOICE_DUE_DATE_VALUES.map((type) => (
                                      <button
                                        key={type}
                                        onClick={this.updateDueDate.bind(this, type, setFieldValue)}
                                        className="btn btn-sm btn-outline-primary border text-nowrap mb-15 w-100">
                                        {invoiceDueDateLabelsMap[type]}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {this.state.showReminder && (
                            <div className="row-smart-reminder d-flex align-items-center py-10">
                              <div className="smart-reminder px-15">
                                {t('payments.smart-reminders')}{' '}
                                <Tooltip
                                  position="top"
                                  message={t(
                                    'payments.use-smart-reminders-to-automatically-remind-your-customers-when-their-invoice-is-due-we-use-extensive-data-to-identify-the-most-optimal-time-to-notify-your-customers-of-outstanding-payments'
                                  )}
                                />
                              </div>
                              <div className="smart-reminder-value form-group form-check px-15 m-0">
                                <label>
                                  <Field
                                    type="checkbox"
                                    className="h-cb"
                                    checked={values.reminder}
                                    onChange={(event) => {
                                      setFieldValue('reminder', !values.reminder)
                                      const obj = Object.assign(this.state.invoice)
                                      obj.reminder = !values.reminder
                                      this.setState({ invoice: obj })
                                      isFormChanged = true
                                    }}
                                  />
                                  <span id="frm_addService_chkBookingHours" className="checkbox-icon"></span>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                        <label className="label-text">
                          {t('payments.invoice-items')}
                          <ErrorMessage name="items" component="span" className={'form-error'} />
                        </label>
                        <div className={`${style['scroll-for-mobile']}`}>
                          <table className="table table-invoice rounded-sm">
                            <thead>
                              <tr>
                                <th>{t('payments.item')}</th>
                                <th className="text-right qty">QTY</th>
                                <th className="text-right rate">{t('payments.rate')}</th>
                                <th className="text-right amount">{t('payments.amount')}</th>
                              </tr>
                            </thead>
                            <tbody ref={this.dragulaDecorator}>
                              {values.invoice_items.length !== 0
                                ? values.invoice_items.map((item, index) => (
                                    <tr key={index} id={index}>
                                      <td className="td-item-label td-item-label-name position-relative">
                                        {!this.state.shouldDisableItems && (
                                          <div className="invoice-row-handle position-absolute d-flex align-items-center justify-content-center top-0 heigh-100">
                                            <Icon name="dots" />
                                          </div>
                                        )}
                                        <div className="d-flex justify-content-between">
                                          <div className="flex-grow-1 pr-15 ">
                                            <Box
                                              data-test="select-invoice-service-item"
                                              className="item-name"
                                              sx={{
                                                ...(this.state.shouldDisableItems
                                                  ? {
                                                      svg: {
                                                        opacity: 0
                                                      }
                                                    }
                                                  : {})
                                              }}>
                                              <AsyncCreatableSelect
                                                isDisabled={this.state.shouldDisableItems}
                                                id="sendInvoiceService"
                                                name="service"
                                                isClearable
                                                placeholder={t('payments.enter-item')}
                                                className="dropdown-select no-arrow-down"
                                                classNamePrefix="dropdownSelect"
                                                defaultOptions
                                                cacheOptions
                                                value={
                                                  item && item.service_name
                                                    ? {
                                                        label: item.service_name,
                                                        value: item.service_id ? item.service_id : ''
                                                      }
                                                    : []
                                                }
                                                loadOptions={this.autoSuggestServices}
                                                formatOptionLabel={customOptionsService}
                                                noOptionsMessage={() => null}
                                                onChange={(event, type) =>
                                                  this.handleSelectOptionService(
                                                    setFieldValue,
                                                    event,
                                                    'service',
                                                    type,
                                                    index
                                                  )
                                                }
                                                formatCreateLabel={(userInput) => (
                                                  <div className="new-custom-item">
                                                    <span className="">{t('payments.new-custom-item')}</span>{' '}
                                                    {`"${userInput}"`}
                                                  </div>
                                                )}
                                              />
                                            </Box>
                                            {(this.state.editDescription && this.state.currentIndex === index) ||
                                            item.service_description ? (
                                              <div className="item-description">
                                                <div
                                                  className="item-description-text d-none"
                                                  onClick={(event) => {
                                                    event.target.classList.add('d-none')
                                                    event.target.classList.remove('d-block')
                                                    event.target
                                                      .closest('.item-description')
                                                      .querySelector('input')
                                                      .classList.add('d-block')
                                                    event.target
                                                      .closest('.item-description')
                                                      .querySelector('input')
                                                      .classList.remove('d-none')
                                                    event.target
                                                      .closest('.item-description')
                                                      .querySelector('input')
                                                      .focus()
                                                  }}>
                                                  {item.service_description
                                                    ? item.service_description
                                                    : t('payments.enter-description')}
                                                </div>
                                                <Field
                                                  type="text"
                                                  name="description"
                                                  id={'serviceDescription_' + index}
                                                  className="form-control d-block"
                                                  placeholder={t('payments.enter-description')}
                                                  value={item.service_description}
                                                  autoComplete="off"
                                                  onChange={(event) => {
                                                    setFieldValue('description', event.target.value)
                                                    const obj = Object.assign(this.state.invoice)
                                                    obj.invoice_items[index].service_description = event.target.value
                                                    this.setState({ invoice: obj })
                                                    isFormChanged = true
                                                  }}
                                                  onBlur={(event) => {
                                                    event.target.classList.add('d-none')
                                                    event.target.classList.remove('d-block')
                                                    event.target
                                                      .closest('.item-description')
                                                      .querySelector('.item-description-text')
                                                      .classList.add('d-block')
                                                    event.target
                                                      .closest('.item-description')
                                                      .querySelector('.item-description-text')
                                                      .classList.remove('d-none')
                                                  }}
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                          {!item.service_description &&
                                          item.service_name &&
                                          !this.state.shouldDisableItems ? (
                                            <div className="add-note">
                                              <Tooltip position="top" message={t('payments.add-service-note')}>
                                                <Icon
                                                  onClick={this.editDescriptionBtnClick.bind(this, item, index)}
                                                  name="note"
                                                />
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </td>
                                      <td className="td-item-label td-item-label-qty text-right light-gray">
                                        <Field
                                          type="text"
                                          name="quantity"
                                          placeholder="--"
                                          className="form-control form-control-lg"
                                          value={item.service_quantity}
                                          onChange={(event) => {
                                            const { value } = event.target
                                            const regex = /^[0-9]*$/
                                            if (regex.test(value.toString()) || value.length === 0) {
                                              setFieldValue('quantity', value)
                                              const obj = Object.assign(this.state.invoice)
                                              obj.invoice_items[index].service_quantity = value
                                              obj.invoice_items[index].service_price_total =
                                                value * obj.invoice_items[index].service_price
                                              this.setState({ invoice: obj })
                                              isFormChanged = true
                                            }
                                          }}
                                          disabled={this.state.shouldDisableItems}
                                        />
                                      </td>
                                      <td className="td-item-label td-item-label-rate text-right light-gray">
                                        {/* {item.service_status === "open" || !item.isPayment ? */}
                                        <Field
                                          type="text"
                                          name="rate"
                                          maxLength={config.amountFieldLength}
                                          placeholder="--"
                                          className="form-control form-control-lg"
                                          value={item.service_price}
                                          onChange={(event) => {
                                            const { value } = event.target
                                            const regex = /^(0*[1-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                            if (regex.test(value.toString()) || value.length === 0) {
                                              setFieldValue('rate', value)
                                              const obj = Object.assign(this.state.invoice)
                                              obj.invoice_items[index].service_price = value
                                              value.toString() !== '.'
                                                ? (obj.invoice_items[index].service_price_total =
                                                    value * item.service_quantity)
                                                : (obj.invoice_items[index].service_price_total = 0)
                                              this.setState({ invoice: obj })
                                              isFormChanged = true
                                            }
                                          }}
                                          disabled={this.state.shouldDisableItems}
                                        />
                                        {/* : <span>${nFormatter(item.service_price)}</span>} */}
                                      </td>
                                      <td className="text-right td-item-label-total">
                                        <div className="position-relative">
                                          {USDOLLAR.format(item.service_price_total)}
                                          <div className="position-absolute remove-item">
                                            {values.invoice_items.length > 1 && !this.state.shouldDisableItems && (
                                              <i
                                                onClick={(event) => {
                                                  this.onRemoveItem(setFieldValue, values, index)
                                                }}
                                                className={'mx-10 top-minus-2'}>
                                                <Icon name="removeMinus" />
                                              </i>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                            <tbody>
                              {!_.get(this.props, 'fromBooking') && !this.state.shouldDisableItems && (
                                <tr>
                                  <td colSpan="4" className="td-add-item border-top ">
                                    <div
                                      data-test="add-invoice-item"
                                      className="text-primary cursor-pointer"
                                      onClick={(event) => {
                                        this.onAddItem(setFieldValue, values)
                                      }}>
                                      {t('payments.add-item')}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td
                                  colSpan="3"
                                  className={`light-text-color td-total-calculation ${
                                    this.state.shouldDisableItems ? 'border-top' : ''
                                  }`}>
                                  {t('payments.subtotal')}
                                </td>
                                <td
                                  className={`text-right primary-font-bold td-total-calculation ${
                                    this.state.shouldDisableItems ? 'border-top' : ''
                                  }`}>
                                  {USDOLLAR.format(this.calculateTotal())}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3" className="primary-font-bold td-total-calculation">
                                  {t('payments.total')}
                                </td>
                                <td className="text-right primary-font-bold td-total-calculation">
                                  {USDOLLAR.format(this.calculateTotal())}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {this.props.showInstruction && this.state.hasContact && values.invoice_items.length === 0 && (
                          <TextInstruction
                            strongMessage={t('payments.next-step')}
                            message={t('payments.add-the-service-s-you-performed-for-your-customer')}
                          />
                        )}
                      </div>
                    </div>

                    {(this.props.hasMid ||
                      !paymentStatusListForDeclinedAndSubmited.includes(this.props.paymentStatus)) && (
                      <div className="col-12">
                        <div
                          data-test="accept-payments-checkbox-title"
                          className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                          <label className="label-text" style={{ color: '#082344' }}>
                            PAYMENTS
                          </label>
                          {!this.state.paymentAllowed && (
                            <p>{t('payments.activate-payments-to-get-paid-directly-from-invoices')}</p>
                          )}
                          <div
                            data-test="accept-payments-checkbox"
                            className={!this.state.paymentAllowed ? 'payments-disabled' : null}>
                            <div className="payments border rounded-sm d-flex py-10">
                              <div
                                className="payments-text px-15 primary-font-bold"
                                style={{ color: '#082344', fontSize: '.875rem' }}>
                                {t('payments.credit-card')}
                              </div>
                              <div className="payments-check-box form-group form-check px-15 m-0 ml-auto">
                                <label>
                                  <Field
                                    type="checkbox"
                                    className="h-cb"
                                    checked={values.collect_payments}
                                    onChange={async (event) => {
                                      setFieldValue('collect_payments', !values.collect_payments)
                                      const obj = Object.assign(this.state.invoice)
                                      obj.collect_payments = !values.collect_payments
                                      this.setState({ invoice: obj })
                                      isFormChanged = true
                                    }}
                                  />
                                  <span id="frm_addService_chkBookingHours" className="checkbox-icon"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          {this.state.payment_type === 'stripe' ? (
                            <div
                              data-test="accept-bank-transfer"
                              className={!this.state.paymentAllowed ? 'payments-disabled' : null}>
                              <div className="payments border rounded-sm d-flex py-10">
                                <div
                                  className="payments-text px-15 primary-font-bold"
                                  style={{ color: '#082344', fontSize: '.875rem' }}>
                                  {t('payments.bank-transfer')}
                                </div>
                                <div className="payments-check-box form-group form-check px-15 m-0 ml-auto">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      className="h-cb"
                                      checked={values.bank_transfer}
                                      onChange={async (event) => {
                                        setFieldValue('bank_transfer', !values.bank_transfer)
                                        const obj = Object.assign(this.state.invoice)
                                        obj.bank_transfer = !values.bank_transfer
                                        this.setState({ invoice: obj })
                                        isFormChanged = true
                                      }}
                                    />
                                    <span id="frm_addService_chkBookingHours" className="checkbox-icon"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Box>
                  <footer className="d-block d-sm-flex">
                    {this.props.permissions.isBookingPermitted && (
                      <button
                        className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 flex-fill'}
                        data-test="send-invoice-form-btn"
                        id="frmBtnSendInvoice"
                        disabled={this.props.paymentLinkLoader}
                        type="submit">
                        {' '}
                        {this.props.invoiceItem ? t('payments.update-invoice-0') : t('payments.continue')}
                        {this.props.paymentLinkLoader && (
                          <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                            <Icon name="spinner" />
                          </i>
                        )}
                      </button>
                    )}
                    <button
                      type="reset"
                      id="frmBtnSendInvoice_btnCancel"
                      className={'btn btn-xl m-0 cancel-btn flex-fill'}>
                      {t('payments.cancel-0')}
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </Popup>
      </React.Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(SendInvoicePopup)
