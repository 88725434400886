import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import Popup from '@components/popups/basic-modal'
import CalendarInput from '@components/calendarInput'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment-timezone'
import { changeTimezone } from '@helpers/utility'
import style from '../index.module.scss'
import notification from '@components/notification'
import './add-booking-popup.scss'
import i18next from 'i18next'

const t = i18next.t

function ConvertTimeformat(format, str) {
  const time = str
  let hours = Number(time.match(/^(\d+)/)[1])
  const minutes = Number(time.match(/:(\d+)/)[1])
  const AMPM = time.match(/\s(.*)$/)[1]
  if (AMPM === 'PM' && hours < 12) hours = hours + 12
  if (AMPM === 'AM' && hours === 12) hours = hours - 12
  let sHours = hours.toString()
  let sMinutes = minutes.toString()
  if (hours < 10) sHours = '0' + sHours
  if (minutes < 10) sMinutes = '0' + sMinutes
  return sHours + ':' + sMinutes
}
function parseToDate(dateTime) {
  const d = new Date()
  d.setHours(dateTime.substring(0, 2))
  d.setMinutes(dateTime.substring(3, 5))
  return d
}
function getTimeIntervals(time1, time2, interval, startTimeInc) {
  const arr = []
  // eslint-disable-next-line no-unmodified-loop-condition
  while (time1 <= time2) {
    arr.push(time1.toTimeString().substring(0, 5))
    time1.setMinutes(time1.getMinutes() + startTimeInc)

    // if(time1 <= time2)
    //     arr.push(time1.toTimeString().substring(0, 5));

    // time1.setMinutes(time1.getMinutes() + startTimeInc);
  }
  return arr
}

// const currentDate = new Date();
class BookingReschedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  async showPopup() {
    this.setState({ open: true })
    if (this.props.rescheduleService) {
      this.getMaxDate(this.props.rescheduleService.dateRange)
      await this.generateAllslotDate(this.props.rescheduleService)
      this.getOffDays(this.props.rescheduleService)
    }
  }

  async getMaxDate(dateRange) {
    const date = changeTimezone(new Date(), this.props.userTimeZone && this.props.userTimeZone.key)
    date.setDate(date.getDate() + dateRange)
    this.setState({ maxDate: date })
  }
  async generateAllslotDate(service) {
    const { dateRange } = service
    const dates = []
    if (dateRange) {
      for (let i = 0; i <= dateRange; i++) {
        const date = changeTimezone(new Date(), this.props.userTimeZone && this.props.userTimeZone.key)
        date.setDate(date.getDate() + i)
        dates.push({ date: moment(date).locale('en').format('YYYY-MM-DD HH:mm:ss') })
      }
    }
    const bookinglots = {}

    Object.keys(service.business_hours).forEach(function (day, index) {
      if (service.business_hours.hasOwnProperty(day)) {
        bookinglots[day] = {}
        bookinglots[day].open = service.business_hours[day].open
        bookinglots[day].split = service.business_hours[day].split
        bookinglots[day].slots = []

        if (service.business_hours[day].open) {
          service.business_hours[day].slots.forEach(function (item) {
            let ts, te
            if (!service.business_hours[day].open_allday) {
              ts = ConvertTimeformat('24', item.time_start)
              te = ConvertTimeformat('24', item.time_end)
            } else {
              ts = '00:00'
              te = '24:00'
            }
            const intervals = getTimeIntervals(parseToDate(ts), parseToDate(te), service.duration, service.startTimeInc)
            intervals.forEach((interval, index) => {
              const nextInterval = intervals[index + 1]
              if (nextInterval) {
                bookinglots[day].slots.push({
                  time_start: interval,
                  time_end: nextInterval,
                  is_booked: false,
                  is_available: true,
                  _id: Math.floor(Math.random() * 1000000000).toFixed()
                })
              }
            })
          })
        }
      }
    })

    const slotDates = []
    dates.forEach((item) => {
      const key = moment(item.date).locale('en').format('dddd').toLowerCase()
      const data = { date: item.date, slots: bookinglots[key].slots }
      slotDates.push(data)
    })
    await this.setState({ slotList: slotDates })
  }

  handleSubmit(values, { resetForm }) {
    values.appointment_date = this.state.selectedDate
    values.appointment_start_time = values.selSelectedAppTime.value.split('-')[0]
    // values.appointment_end_time = values.selSelectedAppTime.value.split("-")[1];
    const endTime = parseToDate(values.appointment_start_time)
    endTime.setMinutes(endTime.getMinutes() + this.props.rescheduleService.duration)
    values.appointment_end_time = endTime.toTimeString().substring(0, 5)
    delete values.selAppointmentDate
    delete values.selSelectedAppTime
    this.props.onSubmit(values, resetForm)
  }
  handleCancel(values, { resetForm }) {
    this.setState({ open: false, date: '', newSlots: '', service: '', selectedSlot: '' })
    resetForm({ selAppointmentDate: '', selectedAppTime: '' })
  }
  closeModal(resetForm) {
    this.setState({ open: false, service: '', date: '', selectedSlot: '' })
    if (resetForm) {
      resetForm({ selAppointmentDate: '', selectedAppTime: '' })
    }
  }
  async getDateFormat(d) {
    const date = new Date(d)

    const mnth = ('0' + (date.getMonth() + 1)).slice(-2)

    const day = ('0' + date.getDate()).slice(-2)
    this.state.selectedDate = [mnth, day, date.getFullYear()].join('/')
  }
  async isFuture(date, time, period) {
    const zone = this.props.userTimeZone
    // let zone = this.props.userTimeZone && this.props.userTimeZone.offset_string

    // set time zone to business time zone
    moment.tz.setDefault(zone.key)
    if (time) {
      const t = moment(
        moment(date).format('YYYY-MM-DD') + ' ' + time.split('-')[0] + ' ' + period,
        'YYYY-MM-DD HH:mm a'
      )
      if (t.isValid() && moment(t).isAfter(moment().utcOffset(zone.offset_string))) {
        moment.tz.setDefault()
        return true
      } else {
        moment.tz.setDefault()
        return false
      }
    }
  }
  async getAMPM(input) {
    const timeString = input
    const H = +timeString.substr(0, 2)
    const h = H % 12 || 12
    const ampm = H < 12 ? 'AM' : 'PM'
    const timeStart = h + timeString.substr(2, 3) + ampm
    return timeStart
  }
  async dateChanged(setFieldValue, d, key) {
    this.setState({ selectedSlot: null })
    if (d) {
      await setFieldValue(key, d)
      await setFieldValue('selectedAppTime', '')
      await this.setState({ date: d, newSlots: '' })
      await this.getDateFormat(d)
      this.setState({ slotLoading: true })
      const payload = {
        serviceId: this.props.rescheduleService._id,
        query: { date: moment(d).locale('en').format('L'), checkAllService: true }
      }
      if (
        this.props.bookingDetailPage &&
        this.props.bookingData &&
        this.props.bookingData.parent_user &&
        this.props.bookingData.parent_user._id
      ) {
        payload.query = { ...payload.query, customers: this.props.bookingData.parent_user._id }
      }
      await this.props.getUnAvailableDateSlots(payload)
      this.setState({ slotLoading: false })
      // if(this.state.selectedSlot){
      //   this.setState({selectedSlot:{label:"",value:""}})
      // }
      if (this.state.selectedDate) {
        let slots = []
        const formattedDate =
          this.state.selectedDate.split('/')[2] +
          '-' +
          this.state.selectedDate.split('/')[0] +
          '-' +
          this.state.selectedDate.split('/')[1]
        this.state.slotList &&
          this.state.slotList.length &&
          this.state.slotList.forEach(function (eachDate) {
            if (eachDate.date.indexOf(formattedDate) > -1) slots = eachDate.slots
          })

        // let zone = this.props.userTimeZone && this.props.userTimeZone.key

        const zone = this.props.userTimeZone && this.props.userTimeZone.key

        if (this.props.unAvailabledateSlots && this.props.unAvailabledateSlots.length) {
          this.props.unAvailabledateSlots.forEach((element) => {
            // console.log("element---------->",element,zone)
            // element.start_time_utc = moment(element.start_time_utc).tz(zone).format("YYYY-MM-DD HH:mm:ss");
            // element.end_time_utc = moment(element.end_time_utc).tz(zone).format("YYYY-MM-DD HH:mm:ss");

            element.start_time_utc = moment(element.start_time_utc).tz(zone).format('YYYY-MM-DD HH:mm:ss')
            element.end_time_utc = moment(element.end_time_utc).tz(zone).format('YYYY-MM-DD HH:mm:ss')

            let updatedStart, updatedEnd

            if (this.props.rescheduleService && this.props.rescheduleService.block_off_time) {
              updatedStart = moment(element.start_time_utc)
                .subtract(
                  this.props.rescheduleService.duration + parseInt(this.props.rescheduleService.block_off_time),
                  'm'
                )
                .format('YYYY-MM-DD HH:mm:ss')
              updatedEnd = moment(element.end_time_utc)
                .add(this.props.rescheduleService.block_off_time, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
            } else {
              updatedStart = moment(element.start_time_utc)
                .subtract(this.props.rescheduleService.duration, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
              updatedEnd = moment(element.end_time_utc).format('YYYY-MM-DD HH:mm:ss')
            }
            // if the updated start time is not on same day, set the start time to current date
            const currentDate = moment(d).format('DD/MM/YYYY')
            const currentStart = moment(updatedStart).format('DD/MM/YYYY')
            const currentEnd = moment(updatedEnd).format('DD/MM/YYYY')

            if (currentDate !== currentStart && currentDate !== currentEnd) {
              return false
            } else if (currentDate !== currentStart && currentDate === currentEnd) {
              updatedStart = moment(d).format('YYYY-MM-DD') + ' 00:00:00'
              slots.shift() // removing 00:00 from slot array
            } else if (moment(updatedEnd).format('LT') === '12:00 AM') {
              // if end time is 12 am update end time to next day
              updatedEnd = moment(d).format('YYYY-MM-DD') + ' 24:00:00'
            }

            while (updatedStart < updatedEnd) {
              slots = this.checkIfSlotValid(slots, updatedStart, updatedEnd, formattedDate)
              updatedStart = moment(updatedStart)
                .add(this.props.rescheduleService.startTimeInc, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
            }
          })
        }
        const slotArr = []
        if (slots.length) {
          for (let i = 0; i < slots.length; i++) {
            if (slots[i].is_booked === false && slots[i].is_available) {
              const startTime = await this.getAMPM(slots[i].time_start)
              const res = await this.isFuture(formattedDate, startTime, startTime)
              if (res) {
                slotArr.push({ label: `${startTime}`, value: slots[i].time_start })
              }
            }
          }
          await this.setState({ newSlots: slotArr })
          if (slotArr.length === 0 || this.state.newSlots.length === 0) {
            // if (!hideError) {
            notification('error', t('bookings.no-slots-available-on-the-selected-date-0'))
            // }
            // await this.setState({ date: "", newSlots: "" });
          }
        } else {
          // if (!hideError) {
          notification('error', t('bookings.no-slots-available-on-the-selected-date-0'))
          // }
        }
      }
    } else {
      await setFieldValue(key, '')
      await setFieldValue('selectedAppTime', '')
      await this.setState({ date: '', newSlots: '' })
    }
  }
  checkIfSlotValid(slots, startTime, endTime, formattedDate) {
    const s = slots
    const selectedDate = moment(formattedDate).format('YYYY-MM-DD HH:mm:ss')
    // disable slots if its already booked or not available
    s.forEach((slot) => {
      if (this.isSlotToBeDisabled(selectedDate, startTime, endTime, slot.time_start)) {
        slot.is_available = false
        slot.is_booked = true
      }
    })
    return s
  }
  isSlotToBeDisabled(date, startTime, endTime, slotTime) {
    // checking if the slot is from the same day
    if (date && startTime) {
      const res = moment(moment(date)).isSame(moment(startTime), 'day')
      if (res) {
        const st = moment(moment(date).format('YYYY-MM-DD') + ' ' + slotTime.split('-')[0]).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        if (startTime < st && st < endTime) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
  setSelectedTimeSlot(setFieldValue, timeSlot, field) {
    setFieldValue(field, timeSlot)
    this.setState({ selectedSlot: timeSlot })
  }
  getOffDays(service) {
    const weeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const offDays = []
    if (service && service.business_hours) {
      Object.keys(service.business_hours).forEach(function (day, index) {
        if (service.business_hours[day].open === false) {
          offDays.push(weeks.indexOf(day))
        }
      })
    }
    this.setState({ offDays: offDays })
  }
  isOffDay(date) {
    if (this.state.offDays) {
      if (this.state.offDays.indexOf(date.getDay()) > -1) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
  render() {
    // const { rescheduleService } = this.props
    // const disabledDates =
    //   rescheduleService && rescheduleService.disabled_date && rescheduleService.disabled_date.length
    //     ? rescheduleService.disabled_date.map((d) => new Date(d.replace(/-/g, '/')))
    //     : []
    const { service } = this.state
    const disabledDates =
      service && service.dataObj && service.dataObj.disabled_date && service.dataObj.disabled_date.length
        ? service.dataObj.disabled_date.map((d) => new Date(d.replace(/-/g, '/')))
        : []
    return (
      <Popup
        id="modalRescheduleBooking"
        open={this.state.open}
        title={t('bookings.reschedule-appointment')}
        okText={t('bookings.save')}
        cancelText={t('bookings.cancel')}
        onCancel={() => this.setState({ open: false })}
        type="default"
        overrideForm={true}
        // modalBackdropClicked={() => this.setState({ open: false })}
        modalBackdropClicked={this.closeModal.bind(this)}>
        <Formik
          enableReinitialize
          initialValues={{
            selAppointmentDate: this.state.date ? this.state.date : '',
            selSelectedAppTime: this.state.selectedSlot ? this.state.selectedSlot : ''
          }}
          validationSchema={Yup.object().shape({
            selAppointmentDate: Yup.string().required(t('bookings.required')),
            selSelectedAppTime: Yup.string().required(t('bookings.required'))
          })}
          onReset={this.handleCancel.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form
                name="frmBookingDetailsReschedule"
                id="frmBookingDetailsReschedule"
                className={`custom-add-booking-form ${style['complete-transaction-form']} `}>
                <button
                  name="btnCloseBookingRescheduleModal"
                  type="reset"
                  className="close custom-modal-close-button remove-modal-close">
                  <span aria-hidden="true">×</span>
                </button>
                <div id="reschedule">
                  <div className="form-group custom-form-group-dropdown mt-10">
                    <label className="label-text">
                      {t('bookings.appointment-date-0')}
                      <i className="star ml-5">*</i>
                      <ErrorMessage
                        id="frmRescheduleBooking_labelDateError"
                        name="selAppointmentDate"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div
                      id="datePickerContainer"
                      className="due-date-calendar due-date-value react-datepicker-popper hide-outside-dates ">
                      <DatePicker
                        id="datePickerContainer"
                        dateFormat="MM/dd/yyyy"
                        className="form-control border-0 p-0 datepicker"
                        selected={this.state.date}
                        name="selAppointmentDate"
                        value={this.state.date}
                        customInput={
                          <CalendarInput
                            message={t('bookings.select-appointment-date')}
                            clearDate={(e) => this.dateChanged(setFieldValue, null, 'selAppointmentDate')}
                            value={this.state.date}
                            onClick={(e) => this.this.dateChanged(setFieldValue, e, 'selAppointmentDate')}
                          />
                        }
                        // placeholderText="Select appointment date"
                        minDate={changeTimezone(new Date(), this.props.userTimeZone && this.props.userTimeZone.key)}
                        onChange={(e) => this.dateChanged(setFieldValue, e, 'selAppointmentDate')}
                        maxDate={this.state.maxDate}
                        // includeDates={this.state.slotList && this.state.slotList.length && this.state.slotList.map(item=> new Date(item.date))}
                        autoComplete="off"
                        filterDate={this.isOffDay.bind(this)}
                        excludeDates={disabledDates}
                      />
                    </div>
                  </div>
                  <div className="form-group custom-form-group-dropdown mb-30  position-relative">
                    <label className="label-text">
                      {t('bookings.appointment-time')}
                      <i className="star">&nbsp;*</i>
                      <ErrorMessage
                        id="frmRescheduleBooking_labelTimeError"
                        name="selSelectedAppTime"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <Select
                      id="frmReschedule_selRescheduleTime"
                      className="dropdown-select"
                      classNamePrefix="dropdownSelect"
                      closeMenuOnSelect={true}
                      options={this.state.newSlots}
                      isMulti={false}
                      isSearchable={false}
                      placeholder={t('bookings.select-time')}
                      name="selSelectedAppTime"
                      isDisabled={!(this.state.newSlots && this.state.newSlots.length)}
                      value={this.state.selectedSlot}
                      maxMenuHeight="120px"
                      noOptionsMessage={() => null}
                      onChange={(value) => {
                        this.setSelectedTimeSlot(setFieldValue, value, 'selectedAppTime')
                      }}
                    />
                    {this.state.slotLoading ? (
                      <div
                        className={`position-absolute z-index-1 d-flex align-item-center justify-content-center mt-15 ${style['appt-time-loader']}`}>
                        <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="loader" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <footer className="d-block d-sm-flex">
                  <button
                    type="submit"
                    name="btnSaveBookingRescheduleModal"
                    id="frmRescheduleBooking_btnSave"
                    disabled={this.props.processbooking}
                    className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block">
                    SAVE
                    {this.props.processbooking ? (
                      <i className="btn-icon top-minus-2">
                        <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                      </i>
                    ) : null}
                  </button>
                  <button
                    name="btnCancelBookingRescheduleModal"
                    id="frmRescheduleBooking_btnCancel"
                    type="reset"
                    className="btn btn-xl m-0 cancel-btn custom-btn-width">
                    {'CANCEL'}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}
export default BookingReschedule
